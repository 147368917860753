import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import backgroundImage from "./fond_login2.webp";
import { useUser } from "./../../contexts/UserContext";
import { loginUser } from "./../../services/userServices";
import useScrollToTop from "../../hooks/useScrollToTop";

function LoginPage() {
  useScrollToTop();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { updateUser } = useUser();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await loginUser(email, password);

      if (!data.userInfo || !data.token) {
        setErrorMessage("Email ou mot de passe incorrect. Veuillez réessayer.");
        return;
      }

      localStorage.setItem("token", data.token);
      localStorage.setItem("userInfo", JSON.stringify(data.userInfo));
      updateUser(data.userInfo);

      navigate("/"); // Redirection vers la page d'accueil ou dashboard

      window.location.reload(); // Force le rechargement de la page
    } catch (error) {
      setErrorMessage(
        "Impossible de se connecter pour le moment. Veuillez réessayer plus tard."
      );
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="max-w-md w-full space-y-8 bg-white bg-opacity-90 p-10 rounded-lg shadow-lg dark:bg-gray-800 dark:bg-opacity-90">
        <form onSubmit={handleSubmit} className="space-y-6">
          <h1 className="text-center text-3xl font-bold text-gray-900 dark:text-white">
            Connexion
          </h1>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Email
            </label>
            <input
              type="email"
              placeholder="Votre email"
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                         focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 dark:bg-gray-700 dark:border-gray-600"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Mot de passe
            </label>
            <input
              type="password"
              placeholder="Votre mot de passe"
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                         focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 dark:bg-gray-700 dark:border-gray-600"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-primary text-white px-4 py-2 rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center"
            >
              Se connecter
            </button>
          </div>
          {errorMessage && (
            <div
              className="p-4 mt-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
              role="alert"
            >
              {errorMessage}
            </div>
          )}
          <div className="text-center">
            <Link
              to="/forgot-password"
              className="font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-200"
            >
              Mot de passe oublié ?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
