import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Button from '../../../../components/Button/Button';
import { updateAgendaClient } from '../../../../services/agendaclubServices'; // Assurez-vous que c'est le bon chemin pour votre service

const DetailAgendaModal = ({ isOpen, onClose, agenda, onUpdate }) => {
  const [editingType, setEditingType] = useState(false);
  const [typeRdv, setTypeRdv] = useState(agenda.type_rdv);

  if (!isOpen || !agenda) return null;

  const getTypeDisplay = (type_rdv) => {
    switch (type_rdv.toLowerCase()) {
      case "sur place":
        return { name: "Sur place", className: "bg-green-500 text-white" };
      case "téléphonique":
        return { name: "Téléphonique", className: "bg-blue-500 text-white" };
      case "visio":
        return { name: "Visio", className: "bg-purple-500 text-white" };
      default:
        return { name: "Type Inconnu", className: "bg-gray-500 text-white" };
    }
  };

  const handleTypeChange = async (newTypeRdv) => {
    try {
      await updateAgendaClient(agenda.id, { type_rdv: newTypeRdv });
      setTypeRdv(newTypeRdv);
      setEditingType(false);
      onUpdate({ ...agenda, type_rdv: newTypeRdv });
    } catch (error) {
      console.error("Erreur lors de la mise à jour du type de rendez-vous :", error);
    }
  };

  const handleClose = () => {
    onClose();
    onUpdate({ ...agenda, type_rdv: typeRdv });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 dark:bg-gray-900 p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-full overflow-y-auto">
        {/* En-tête avec le logo et le nom de l'association */}
        <div className="flex items-center mb-6">
          <img
            src={agenda.Associations.logo_asso}
            alt={`Logo de ${agenda.Associations.nom_asso}`}
            className="w-10 h-10 rounded-full mr-4"
          />
          <h3 className="text-3xl font-light tracking-wide text-white">
            {agenda.Associations.nom_asso}
          </h3>
        </div>

        {/* Détails du rendez-vous en deux colonnes */}
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3 space-y-4">
            <h4 className=" text-gray-300">Date :</h4>
            <h4 className=" text-gray-300">Heure :</h4>
            <h4 className=" text-gray-300">Client :</h4>
            <h4 className=" text-gray-300">Type :</h4>
            <h4 className=" text-gray-300">Email :</h4>
            <h4 className=" text-gray-300 mb-2">Notes :</h4>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="h-full w-px bg-white"></div> {/* Ligne verticale */}
          </div>
          <div className="col-span-6 space-y-4">
            <p className="text-gray-100">
              {new Date(agenda.date_rdv).toLocaleDateString('fr-FR')}
            </p>
            <p className="text-gray-100">{agenda.heure_debut} - {agenda.heure_fin}</p>
            <p className="text-gray-100">{agenda.client_nom}</p>
            {!editingType ? (
              <button
                className={`px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out border flex items-center justify-center ${getTypeDisplay(typeRdv).className}`}
                onClick={() => setEditingType(true)}
              >
                {getTypeDisplay(typeRdv).name}
              </button>
            ) : (
              <select
                className="bg-gray-700 text-white border border-gray-600 rounded-3xl px-4 py-2"
                value={typeRdv}
                onChange={(e) => handleTypeChange(e.target.value)}
              >
                <option value="sur place">Sur place</option>
                <option value="téléphonique">Téléphonique</option>
                <option value="visio">Visio</option>
              </select>
            )}
            <p className="text-gray-100">{agenda.client_mail}</p>
            <div className="text-gray-100 max-w-prose">
              <div dangerouslySetInnerHTML={{ __html: agenda.notes }} />
            </div>
          </div>
        </div>

        {/* Boutons en bas */}
        <div className="flex justify-center items-center gap-4 mt-6">
          <Button
            text="Fermer"
            icon={FaTimes}
            onClick={handleClose}
            className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center"
          />
        </div>
      </div>
    </div>
  );
};

export default DetailAgendaModal;
