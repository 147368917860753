import React, { useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../../../components/Button/Button';
import { addProduct } from '../../../../services/productsServices';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importation des styles de React-Quill

const AddProductModal = ({ isOpen, onClose, refreshList }) => {
  const [formData, setFormData] = useState({
    nom_product: '',
    contenu_product: '',
    tarif_product: '',
    periodicite_product: '',
    categorie_product: '',
    payment_stripe_link: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleQuillChange = (value) => {
    setFormData({ ...formData, contenu_product: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addProduct(formData);
      toast.success('Produit ajouté avec succès');
      refreshList();
      onClose();
    } catch (error) {
      toast.error("Erreur lors de l'ajout du produit");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Ajouter un Produit
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-bold mb-2">Nom du Produit:</label>
            <input
              className="input input-bordered w-full"
              type="text"
              name="nom_product"
              value={formData.nom_product}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Contenu:</label>
            <ReactQuill
              value={formData.contenu_product}
              onChange={handleQuillChange}
              className="bg-white dark:bg-slate-600"
              theme="snow"
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Tarif:</label>
            <input
              className="input input-bordered w-full"
              type="number"
              name="tarif_product"
              value={formData.tarif_product}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Périodicité:</label>
            <input
              className="input input-bordered w-full"
              type="text"
              name="periodicite_product"
              value={formData.periodicite_product}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Catégorie:</label>
            <input
              className="input input-bordered w-full"
              type="text"
              name="categorie_product"
              value={formData.categorie_product}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Lien Stripe:</label>
            <input
              className="input input-bordered w-full"
              type="text"
              name="payment_stripe_link"
              value={formData.payment_stripe_link}
              onChange={handleChange}
            />  
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Ajouter" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Fermer" icon={FaTimes} onClick={onClose} className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProductModal;
