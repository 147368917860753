import React, { useState, useEffect } from "react";
import axios from "axios";

// Liste des clubs avec leurs API
const clubs = [
  { name: 'MonAppliClub', apiUrl: 'https://api.monappliclub.com' },
  { name: 'MonAppliClub Démo', apiUrl: 'https://api.monappliclub-demo.com' },
  { name: 'MonAppliClub Light', apiUrl: 'https://api.monappliclub-demo-light.com' },
  { name: 'USB Judo', apiUrl: 'https://api.usbouscat-judo.com' },
  { name: 'ASSM Judo', apiUrl: 'https://api.assm-judo.com' },
  { name: 'USB Karaté', apiUrl: 'https://api.usbouscat-karate.com' },
  { name: 'Comité Gironde', apiUrl: 'https://api.judogironde.fr' },
  { name: 'Judo Club Salon-de-Provence', apiUrl: 'https://api.judoclubsalon.fr' },
  { name: 'CAL Judo', apiUrl: 'https://api.caljudo.com' },
  { name: 'ESSHA Judo Segré', apiUrl: 'https://api.essha-judo-segre.com' },
  { name: 'Dojo Gujanais', apiUrl: 'https://api.dojogujanais.fr' },
  // Ajoutez les autres clubs ici
];

const UsersCountTable = () => {
  const [userCounts, setUserCounts] = useState([]);

  useEffect(() => {
    const fetchUserCounts = async () => {
      const counts = [];

      for (const club of clubs) {
        try {
          // Récupération du nombre total d'utilisateurs
          const totalResponse = await axios.get(`${club.apiUrl}/api/users/countUsers`);
          const totalCount = totalResponse.data.count || 0;

          let todayCount = null;
          let nombreAdherents = null;

          // Récupération du nombre d'inscriptions aujourd'hui si la route existe
          try {
            const todayResponse = await axios.get(`${club.apiUrl}/api/users/countUsersToday`);
            todayCount = todayResponse.data.count || 0;
          } catch (error) {
            console.warn(`Route /countUsersToday non disponible pour ${club.name}`);
          }

          // Récupération du nombre d'adhérents
          try {
            const adherentsResponse = await axios.get(`${club.apiUrl}/api/dashboard/comptageStatutLicencie`);
            nombreAdherents = adherentsResponse.data.nombreAdherents || 0;
          } catch (error) {
            console.warn(`Route /comptageStatutLicencie non disponible pour ${club.name}`);
            nombreAdherents = 'Non disponible';
          }

          counts.push({
            name: club.name,
            totalCount,
            todayCount,
            nombreAdherents,
          });
        } catch (error) {
          console.error(`Erreur lors de la récupération des données pour ${club.name}`, error);
          counts.push({
            name: club.name,
            totalCount: 'Erreur',
            todayCount: null,
            nombreAdherents: 'Non disponible',
          });
        }
      }

      setUserCounts(counts);
    };

    fetchUserCounts();
  }, []);

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-4 dark:bg-gray-900 dark:text-gray-200">
      <h3 className="text-lg font-bold text-white mb-4">Nombre d'Utilisateurs par Club</h3>
      <table className="min-w-full bg-gray-800 text-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-700">Nom du Club</th>
            <th className="py-2 px-4 border-b border-gray-700">Nombre d'Utilisateurs Total</th>
            <th className="py-2 px-4 border-b border-gray-700">Inscrits Aujourd'hui</th>
            <th className="py-2 px-4 border-b border-gray-700">Nombre de Licenciés</th>
          </tr>
        </thead>
        <tbody>
          {userCounts.map((club, index) => (
            <tr key={index} className="hover:bg-gray-700">
              <td className="py-2 px-4 border-b border-gray-700">{club.name}</td>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                {club.totalCount !== 'Erreur' ? club.totalCount : 'Erreur'}
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                {club.todayCount === null
                  ? '' // Rien à afficher si la route n'est pas encore disponible
                  : club.todayCount === 0
                  ? '0' // Affiche 0 si aucun utilisateur aujourd'hui
                  : <span className="bg-red-500 text-white px-2 py-1 rounded">+{club.todayCount}</span>
                }
              </td>
              <td className="py-2 px-4 border-b border-gray-700 text-center">
                {club.nombreAdherents !== 'Erreur' ? club.nombreAdherents : 'Erreur'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersCountTable;
