import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/messages`;
const token = localStorage.getItem("token");

///GET
// Fonction pour récupérer le nombre de messages non lus pour un utilisateur (TopBar)
export const getUnreadMessagesCountByUser = async () => {
  // Tentative de récupération sécurisée de l'utilisateur
  const userItem = localStorage.getItem("userInfo");
  if (!userItem) {
    console.error("Aucun utilisateur enregistré dans localStorage");
    return 0;
  }

  let user;
  try {
    user = JSON.parse(userItem);
  } catch (error) {
    console.error(
      "Erreur lors de la désérialisation des données de l'utilisateur",
      error
    );
    return 0;
  }

  if (!user.userId) {
    console.error("Aucun userId trouvé pour l'utilisateur actuel");
    return 0;
  }

  const userId = user.userId;

  try {
    const response = await axios.get(
      `${apiUrl}/countUnreadMessageUser/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.count;
  } catch (error) {
    console.error(
      "Erreur lors du chargement du nombre de messages non lus:",
      error
    );
    return 0;
  }
};

// Fonction pour récupérer tous les messages destinés à un utilisateur
export const getMessagesByUser = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/allmessages/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des messages:", error);
    return [];
  }
};

// Fonction pour récupérer tous les messages envoyés par un utilisateur
export const getSentMessagesByUser = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/sentmessages/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des messages envoyés:", error);
    return [];
  }
};

// Fonction pour récuperer l'ensemble des messages
export const getAllMessages = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allMessages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement de tous les messages:", error);
    return [];
  }
};

/// POST
// Fonction pour envoyer un message
export const sendMessage = async (messageData) => {
  try {
    const response = await axios.post(`${apiUrl}/createMessage`, messageData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'envoi du message:", error);
    throw error;
  }
};

// Fonction pour envoyer un message groupé à l'ensemble des utilisateurs dont les adhérents font partie d'un groupe
export const sendGroupMessage = async (messageData) => {
  try {
    await axios.post(`${apiUrl}/createGroupMessage`, messageData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de l'envoi du message groupé:", error);
    throw error;
  }
};

/// PATCH
// Fonction pour marquer un message comme lu
export const markMessageAsRead = async (messageId) => {
  try {
    await axios.patch(
      `${apiUrl}/toggleRead/${messageId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Erreur lors de la mise à jour du message:", error);
    throw error;
  }
}

// Fonction pour marquer tous les messages comme lus
export const markAllMessagesAsRead = async (userId) => {
  try {
    await axios.patch(
      `${apiUrl}/toggleAllRead/${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  catch (error) {
    console.error("Erreur lors de la mise à jour de tous les messages:", error);
    throw error;
  }
}



/// DELETE
// Fonction pour supprimer un message par son ID
export const deleteMessageById = async (messageId) => {
  try {
    await axios.delete(`${apiUrl}/deleteMessage/${messageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la suppression du message:", error);
    throw error;
  }
};

