import React, { useState, useEffect } from "react";
import { useUser } from "./../../../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../../../utils/roleHelper";
import MessagesReceived from "./MessagesReceived";
import MessagesSent from "./MessagesSent";
import CreateMessageForm from "./CreateMessageForm";
import Button from "./../../../../components/Button/Button";
import { LuMessageSquarePlus } from "react-icons/lu";
import useScrollToTop from "./../../../../hooks/useScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import ComponentTitle from "./../../../Home/ComponentTitle";

const Messaging = () => {
  useScrollToTop();

  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const [showForm, setShowForm] = useState(false);
  const [refreshMessages, setRefreshMessages] = useState(false);

  const handleNewMessage = () => {
    setShowForm(true);
  };

  const handleSuccess = () => {
    setShowForm(false);
    setRefreshMessages(true); // Activer le rafraîchissement
  };

  const hideForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    console.log("userRole", userRole);
    console.log("userId", userId);
  }, [userRole, userId]);

  return (
    <div className="container mx-auto p-4">
      <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-8 bg-slate-200 rounded-lg p-2">
        <ComponentTitle title="Mes" highlight=" Messages" />
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Bienvenue dans votre espace de messagerie. Vous pouvez envoyer et recevoir des messages ici !
        </p>
      </div>

      <div className="mx-auto max-w-screen-md mb-8 lg:mb-12">
        <div className="w-full mx-auto mb-4">
          <Button
            text="Nouveau Message"
            onClick={handleNewMessage}
            icon={LuMessageSquarePlus}
            className="w-full gap-2 mb-4"
          />

          <div className="w-full max-w-4xl px-2 mb-4">
            {showForm && (
              <CreateMessageForm
                userId={userId}
                userRole={userRole}
                onSuccess={handleSuccess}
                show={showForm}
                onHide={hideForm}
              />
            )}
          </div>
          <div className="w-full max-w-4xl px-2 mb-4">
            <MessagesReceived
              userId={userId}
              refresh={refreshMessages}
              setRefresh={setRefreshMessages}
            />
          </div>
          <div className="block md:hidden">
            {/* Ajoutez ici le code pour afficher les messages reçus sous forme de cartes pour mobile */}
          </div>

          <div className="w-full max-w-4xl px-2 mb-4">
            <MessagesSent
              userId={userId}
              refresh={refreshMessages}
              setRefresh={setRefreshMessages}
            />
          </div>
          <div className="block md:hidden">
            {/* Ajoutez ici le code pour afficher les messages envoyés sous forme de cartes pour mobile */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Messaging;
