import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/notifications`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer le nombre de messages non lus pour un utilisateur (TopBar)
export const getUnreadNotificationsCountByUser = async () => {
  // Tentative de récupération sécurisée de l'utilisateur
  const userItem = localStorage.getItem("userInfo");
  if (!userItem) {
    console.error("Aucun utilisateur enregistré dans localStorage");
    return 0;
  }

  let user;
  try {
    user = JSON.parse(userItem);
  } catch (error) {
    console.error(
      "Erreur lors de la désérialisation des données de l'utilisateur",
      error
    );
    return 0;
  }

  if (!user.userId) {
    console.error("Aucun userId trouvé pour l'utilisateur actuel");
    return 0;
  }

  const userId = user.userId;

  try {
    const response = await axios.get(
      `${apiUrl}/countUnreadNotificationUser/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.count;
  } catch (error) {
    console.error(
      "Erreur lors du chargement du nombre de notifications non lues:",
      error
    );
    return 0;
  }
};

// Fonction pour récupérer tous les messages d'un utilisateur
export const getNotificationsByUser = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des notifications:", error);
    return [];
  }
};

/// POST
// Fonction pour envoyer une notification
export const sendNotification = async (recipientId, message, token) => {
  try {
    const payload = {
      message,
      recipient_id: recipientId,
    };

    console.log("Sending notification with payload:", payload);

    const response = await axios.post(`${apiUrl}/sendNotification`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("Notification envoyée avec succès:", response.data);
  } catch (error) {
    console.error("Erreur lors de l'envoi de la notification:", error);
  }
};

// Fonction pour envoyer une notification uniquement à l'administrateur
export const sendNotificationToAdmin = async (message, token) => {
  const payload = {
    message: message,
  };
  try {
    const response = await axios.post(
      `${apiUrl}/sendNotificationAdmin`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Notification envoyée avec succès:", response.data);
  } catch (error) {
    console.error("Erreur lors de l'envoi de la notification:", error);
    if (error.response) {
      console.error("Erreur détaillée:", error.response.data);
    }
  }
};

// Fonction pour envoyer une notification à tous les administrateurs et enseignants
export const sendNotificationToAdminAndTeachers = async (message, token) => {
  const payload = {
    message: message,
  };
  try {
    const response = await axios.post(
      `${apiUrl}/sendNotificationAllAdminsAndTeachers`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Notification envoyée avec succès:", response.data);
  } catch (error) {
    console.error("Erreur lors de l'envoi de la notification:", error);
    if (error.response) {
      console.error("Erreur détaillée:", error.response.data);
    }
  }
};

/// PATCH
// Fonction pour marquer une notification comme lue
export const markNotificationAsRead = async (notificationId) => {
  try {
    await axios.patch(
      `${apiUrl}/toggleRead/${notificationId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la notification:", error);
  }
};

// Fonction pour marquer toutes les notifications comme lues
export const markAllNotificationsAsRead = async (userId) => {
  try {
    await axios.patch(
      `${apiUrl}/toggleAllRead/${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de toutes les notifications:",
      error
    );
  }
};

/// DELETE
// Fonction pour supprimer une notification par son id
export const deleteNotificationById = async (notificationId) => {
  try {
    await axios.delete(`${apiUrl}/${notificationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la suppression de la notification:", error);
  }
};
