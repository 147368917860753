import React, { useState } from "react";
import { saveAssociation, getAllAssociations } from "../../../services/associationsServices";
import { assignAssociationToUser } from "../../../services/userServices";
import { sendNotification, sendNotificationToAdmin } from "../../../services/notificationServices";
import { toast, ToastContainer } from "react-toastify";
import { useUser } from "../../../contexts/UserContext"; // Importation du contexte utilisateur
import { useNavigate } from "react-router-dom";

const RegisterAssociationForm = () => {
  const { user } = useUser(); // Récupération des informations de l'utilisateur depuis le contexte
  const userId = user?.userId; // Récupération de l'ID utilisateur
  const token = user?.token; // Si tu utilises un token d'authentification
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nom_asso: "",
    adresse_asso: "",
    tel_asso: "",
    mail_asso: "",
    siret_asso: "",
    discipline_asso: "",
    siteweb_asso: "",
    detail_asso: "",
    logo_asso: "",
    statut_asso: "Prospect", // Statut par défaut
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Étape 1 : Sauvegarder l'association
      await saveAssociation(formData);
      console.log("Données du formulaire envoyées:", formData);

      // Étape 2 : Récupérer la dernière association créée
      const associations = await getAllAssociations();
      if (!associations || associations.length === 0) {
        throw new Error("Aucune association trouvée après la création.");
      }

      // Trier les associations par ID pour obtenir la dernière association créée
      const latestAssociation = associations.sort((a, b) => b.id - a.id)[0];

      if (!latestAssociation || !latestAssociation.id) {
        throw new Error("Impossible de trouver l'ID de la dernière association créée.");
      }

      // Étape 3 : Assigner l'association à l'utilisateur connecté
      await assignAssociationToUser(userId, latestAssociation.id, token);
      console.log("Association assignée avec succès:", latestAssociation.id);

      // Envoyer une notification à l'administrateur
      const adminMessage = `Une nouvelle association a été inscrite : ${formData.nom_asso}`;
      await sendNotificationToAdmin(adminMessage, token);

      // Envoyer une notification à l'utilisateur
      const userMessage = `Votre association ${formData.nom_asso} a été enregistrée avec succès.`;
      await sendNotification(userId, userMessage, token);

      // Afficher un message de succès
      toast.success("Association ajoutée avec succès");

      // Réinitialiser le formulaire
      setFormData({
        nom_asso: "",
        adresse_asso: "",
        tel_asso: "",
        mail_asso: "",
        siret_asso: "",
        discipline_asso: "",
        siteweb_asso: "",
        detail_asso: "",
        logo_asso: "",
        statut_asso: "Prospect", 
      });

      // Rediriger vers la page de détails de l'association
      navigate(`/appli/mon-association`);
    } catch (error) {
      console.error("Erreur lors de l'ajout ou de l'assignation de l'association:", error);
      toast.error("Erreur lors de l'ajout de l'association");
    }
  };

  return (
    <div className="bg-gray-200 p-6 rounded-lg shadow-md max-w-lg mx-auto mt-10">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Inscrire mon Association / Club de Sport
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Nom de l'Association / Club de Sport</label>
          <input
            type="text"
            name="nom_asso"
            value={formData.nom_asso}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Adresse</label>
          <input
            type="text"
            name="adresse_asso"
            value={formData.adresse_asso}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Téléphone</label>
          <input
            type="text"
            name="tel_asso"
            value={formData.tel_asso}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="mail_asso"
            value={formData.mail_asso}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">SIRET (Facultatif)</label>
          <input
            type="text"
            name="siret_asso"
            value={formData.siret_asso}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Discipline Sportive</label>
          <input
            type="text"
            name="discipline_asso"
            value={formData.discipline_asso}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Site Web (Facultatif)</label>
          <input
            type="text"
            name="siteweb_asso"
            value={formData.siteweb_asso}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Détails</label>
          <textarea
            name="detail_asso"
            value={formData.detail_asso}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            rows="4"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Lien vers votre logo</label>
          <input
            type="text"
            name="logo_asso"
            value={formData.logo_asso}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-gray-800 text-white p-2 rounded mt-4"
        >
          Inscrire l'Association
        </button>
      </form>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default RegisterAssociationForm;
