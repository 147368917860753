import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/visites`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour compter le nombre de visites

export const countVisites = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countVisites`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du nombre de visites:",
      error
    );
  }
};

// Fonction pour compter le nombre de visites par date
export const countVisitesByDate = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countVisitesByDate`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du nombre de visites par date:",
      error
    );
  }
};

// Fonction pour compter le nombre de visite de la page Accueil du jour
export const countVisitesAccueilToday = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countVisitesHomeToday`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // { count: <valeur> }
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du nombre de visite de la page Accueil du jour:",
      error
    );
    return { count: 0 }; // Retourner une valeur par défaut en cas d'erreur
  }
};

// Fonction pour compter le nombre de visites par page
export const countVisitesByPage = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countVisitesByPage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du nombre de visites par page:",
      error
    );
  }
};

// Fonction pour compter le nombre de visites par page aujourd'hui
export const countVisitesByPageToday = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countVisitesByPageToday`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du nombre de visites par page aujourd'hui:",
      error
    );
  }
};

// Fonction pour enregistrer une visite
export const saveVisite = async (visite) => {
  try {
    const response = await axios.post(`${apiUrl}/addVisite`, visite, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'enregistrement de la visite:", error);
  }
};
