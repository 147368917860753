import React, { useState, useEffect } from "react";
import { RiCheckDoubleFill } from "react-icons/ri";
import Button from "./../../../../components/Button/Button";
import ButtonDelete from "./../../../../components/Button/ButtonDelete";
import ButtonEyeNoRead from "../../../../components/Button/ButtonEyeNoRead";
import ButtonEyeRead from "../../../../components/Button/ButtonEyeRead";
import ButtonReply from "../../../../components/Button/Buttonreply";
import {
  getMessagesByUser,
  deleteMessageById,
  markMessageAsRead,
  markAllMessagesAsRead,
} from "./../../../../services/messageServices";
import { useMessage } from "./../../../../contexts/MessageContext";
import { FaEnvelope } from "react-icons/fa";
import CreateResponseMessageForm from "./CreateResponseMessageForm";
import { toast } from "react-toastify";

const MessagesDisplay = ({ userId, refresh, setRefresh }) => {
  const [messages, setMessages] = useState([]);
  const { unreadMessageCount, setUnreadMessageCount } = useMessage();
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);

  useEffect(() => {
    if (refresh) {
      loadMessages();
      setRefresh(false);
    }
  }, [refresh, userId]);

  const loadMessages = async () => {
    try {
      const data = await getMessagesByUser(userId);
      const filteredMessages = data.filter((message) => !message.is_copy);
      setMessages(filteredMessages);
    } catch (error) {
      console.error("Erreur lors du chargement des messages:", error);
    }
  };

  const handleReply = (message) => {
    setCurrentMessage({ recipientId: message.sender_id, subject: `RE: ${message.subject}` });
    setShowReplyModal(true);
  };

  const closeReplyModal = () => {
    setShowReplyModal(false);
    setCurrentMessage(null);
  };

  const promptDeleteMessage = (messageId) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce message ?");
    if (confirmDelete) {
      handleDeleteMessage(messageId);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessageById(messageId);
      setMessages(messages.filter((message) => message.id !== messageId));
      toast.success("Message supprimé avec succès.");
    } catch (error) {
      console.error("Error deleting message:", error);
      toast.error("Erreur lors de la suppression du message.");
    }
  };

  const toggleReadStatus = async (messageId) => {
    try {
      const message = messages.find((m) => m.id === messageId);
      await markMessageAsRead(messageId, !message.is_read);
      setMessages(messages.map((m) => (m.id === messageId ? { ...m, is_read: !m.is_read } : m)));
      setUnreadMessageCount((current) => (message.is_read ? current + 1 : current - 1));
    } catch (error) {
      console.error("Erreur lors du basculement de l'état de lecture:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllMessagesAsRead(userId);
      setMessages(messages.map((m) => ({ ...m, is_read: true })));
      setUnreadMessageCount(0);
    } catch (error) {
      console.error("Erreur lors du marquage de tous les messages comme lus:", error);
    }
  };

  useEffect(() => {
    loadMessages();
  }, [userId]);

  return (
    <div className="mt-5 container mx-auto p-4 bg-gray-600 text-gray-200 border border-black">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <FaEnvelope className="mr-2" />
          Messages Reçus
          {unreadMessageCount > 0 && (
            <span className="ml-2 bg-primary text-white text-xs rounded-full px-2 py-0.5">
              {unreadMessageCount}
            </span>
          )}
        </h3>
        <Button
          onClick={handleMarkAllAsRead}
          icon={RiCheckDoubleFill}
          className="bg-gray-700 text-gray-200"
          hoverLabel="Tout Marquer Comme Lu"
        />
      </div>
      {messages.length > 0 ? (
        <>
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-gray-700 divide-y divide-gray-600">
              <thead>
                <tr className="bg-gray-800">
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Détails
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-600">
                {messages.map((message) => (
                  <tr
                    key={message.id}
                    className={`${
                      message.is_read ? "bg-gray-600" : "bg-gray-500"
                    } hover:bg-gray-500`}
                  >
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-200">
                      {new Date(message.created_at).toLocaleDateString("fr-FR")}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-200">
                      <div className="font-semibold">
                        {`${message.Sender.nom} ${message.Sender.prenom}`}
                      </div>
                      <div className="text-blue-400 font-bold">
                        {message.subject}
                      </div>
                      <div
                        className="text-gray-300"
                        dangerouslySetInnerHTML={{ __html: message.content }}
                      ></div>
                    </td>
                    <td className="px-4 py-2 text-right text-sm font-medium gap-2 flex">
                      <ButtonReply
                        onClick={() => handleReply(message)}
                        hoverLabel="Répondre"
                        className="bg-gray-700 text-gray-200"
                      />
                      {message.is_read ? (
                        <ButtonEyeRead
                          onClick={() => toggleReadStatus(message.id)}
                          hoverLabel="Marquer comme non lu"
                          className="bg-gray-700 text-gray-200"
                        />
                      ) : (
                        <ButtonEyeNoRead
                          onClick={() => toggleReadStatus(message.id)}
                          hoverLabel="Marquer comme lu"
                          className="bg-gray-700 text-gray-200"
                        />
                      )}
                      <ButtonDelete
                        onClick={() => promptDeleteMessage(message.id)}
                        hoverLabel="Supprimer"
                        className="bg-gray-700 text-gray-200"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="block sm:hidden">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`shadow-md rounded-lg p-4 mb-4 ${
                  message.is_read ? "bg-gray-600" : "bg-gray-500"
                }`}
              >
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-semibold text-gray-200">
                    {new Date(message.created_at).toLocaleDateString("fr-FR")}
                  </span>
                  <div className="flex space-x-2">
                    <ButtonReply
                      onClick={() => handleReply(message)}
                      hoverLabel="Répondre"
                      className="bg-gray-700 text-gray-200"
                    />
                    {message.is_read ? (
                      <ButtonEyeRead
                        onClick={() => toggleReadStatus(message.id)}
                        hoverLabel="Marquer comme non lu"
                        className="bg-gray-700 text-gray-200"
                      />
                    ) : (
                      <ButtonEyeNoRead
                        onClick={() => toggleReadStatus(message.id)}
                        hoverLabel="Marquer comme lu"
                        className="bg-gray-700 text-gray-200"
                      />
                    )}
                    <ButtonDelete
                      onClick={() => promptDeleteMessage(message.id)}
                      hoverLabel="Supprimer"
                      className="bg-gray-700 text-gray-200"
                    />
                  </div>
                </div>
                <div className="text-sm text-gray-300">
                  <div className="font-semibold">
                    {`${message.Sender.nom} ${message.Sender.prenom}`}
                  </div>
                  <div className="text-blue-400 font-bold">
                    {message.subject}
                  </div>
                  <div
                    className="text-gray-300"
                    dangerouslySetInnerHTML={{ __html: message.content }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center py-4 bg-gray-600 text-gray-200">
          Aucuns messages.
        </div>
      )}
      {showReplyModal && (
        <CreateResponseMessageForm
          show={showReplyModal}
          onHide={closeReplyModal}
          userId={userId}
          initialRecipientId={currentMessage.recipientId}
          initialSubject={currentMessage.subject}
        />
      )}
    </div>
  );
};

export default MessagesDisplay;
