import React from "react";
import ComponentTitle from "./ComponentTitle";

const Products = () => {
  return (
    <section
      id="products"
      className="py-16 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200"
    >
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Nos" highlight=" Produits" />
        </div>
        <div className="flex flex-col md:flex-row justify-center items-start space-y-8 md:space-y-0 md:space-x-8">

                    {/* MonAppliClub */}
          <div className="relative group bg-white dark:bg-neutral-800 rounded-lg shadow-lg overflow-hidden w-full md:w-1/2 transform transition-all duration-300 hover:scale-105 hover:z-10">
            <div className="p-6 text-center">
              <span className="text-6xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Club
              </span>

              {/* Tarif mis en valeur */}
              <p className="text-2xl font-semibold mt-2 text-blue-600 dark:text-blue-400">
                À partir de 30€/mois*
              </p>

              <hr className="border-gray-400 dark:border-gray-700"/>
              <p className="text-lg mt-4 font-semibold">
                La solution complète pour les clubs sportifs.
              </p>
              <p className="mt-4 italic">
                <b>MonAppliClub</b> est une solution tout-en-un, idéale pour les
                clubs qui souhaitent gérer efficacement leurs adhérents, les
                compétitions, et bien plus encore, avec des fonctionnalités
                avancées pour la gestion quotidienne du club.
              </p>
              <ul className="text-left mt-4">
                <li>
                  ✅ Gestion des adhérents par les utilisateurs (adhésions,
                  dossier d'inscription, règlements en ligne),
                </li>
                <li>
                  ✅ Gestion du Calendrier des événements (inscriptions,
                  résultats, palmarès),
                </li>
                <li>✅ Messagerie interne, chat, notifications ...</li>
                <li>✅ Articles, Albums Photos, Boutique en ligne ...</li>
                <li>✅ Lexique, arbitrage et techniques ...</li>
                <li>
                  ✅ Gestion des passages de grades, appels, todolist, inventaires ...
                </li>
                <li>
                  ✅ Statistiques, tableaux de bords, gestion des règlements et
                  dossiers d'inscriptions ...
                </li>
                <li>
                  ✅ Gestion des Dépenses & Recettes, heures d'enseignement & coaching.
                </li>
                <li>✅ Application 100% paramétrable.</li>
              </ul>
              <div className="mt-8">
                <a
                  href="/product-monappliclub"
                  className="px-8 py-3 bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 text-white rounded-full transition"
                >
                  En savoir plus
                </a>
                <p className="text-sm text-left mt-6">* Hors hébergements et nom de domaine.</p>
              </div>
            </div>
          </div>
          
          {/* MonAppliClub Light */}
          <div className="relative group bg-white dark:bg-neutral-800 rounded-lg shadow-lg overflow-hidden w-full md:w-1/2 transform transition-all duration-300 hover:scale-105 hover:z-10">
            <div className="p-6 text-center">
              <span className="text-6xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Club
              </span>
              <span className="text-4xl font-thin font-passionOne text-orange-600 italic">
                {" "}
                "Light"
              </span>

              {/* Tarif mis en valeur */}
              <p className="text-2xl font-semibold  mt-2 text-blue-600 dark:text-blue-400">
                À partir de 15€/mois*
              </p>

              <hr className="border-gray-400 dark:border-gray-700"/>
              <p className="text-lg mt-4 font-semibold">
                Une solution simplifiée pour les petits clubs sportifs.
              </p>
              <p className="mt-4 italic">
                <b>MonAppliClub "Light"</b> est une version allégée spécialement
                conçue pour les petits clubs. Elle comprend :
              </p>
              <ul className="text-left mt-4">
                <li>
                  ✅ Page Accueil : les informations sur le club, les
                  disciplines enseignées, les derniers articles publiés, les
                  derniers albums photos, les partenaires du club et un
                  formulaire de contact,
                </li>
                <li>
                  ✅ Page "Le Club" : les membres des équipes dirigeantes et
                  enseignantes, l'historique du club et les renseignements de
                  contact,
                </li>
                <li>✅ Page Groupes, Horaires & Tarifs,</li>
                <li>✅ Page Inscription des Adhérents,</li>
                <li>
                  ✅ Espace Administrateur pour gérer l'ensemble des données
                  sur le site,
                </li>
              </ul>
              <div className="mt-8">
                <a
                  href="/product-monappliclub-light"
                  className="px-8 py-3 bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 text-white rounded-full transition"
                >
                  En savoir plus
                </a>
                <p className="text-sm text-left mt-6">* Hors hébergements et nom de domaine.</p>
              </div>
            </div>
          </div>

          {/* MonAppliClub Omnisports */}
          <div className="relative group bg-white dark:bg-neutral-800 rounded-lg shadow-lg overflow-hidden w-full md:w-1/2 transform transition-all duration-300 hover:scale-105 hover:z-10">
            <div className="p-6 text-center">
              <span className="text-6xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Club
              </span>
              <span className="text-4xl font-thin font-passionOne text-orange-600 italic">
                {" "}
                "Omnisports"
              </span>

              {/* Tarif mis en valeur */}
              <p className="text-2xl font-semibold  mt-2 text-blue-600 dark:text-blue-400">
                À partir de 20€/mois*
              </p>

              <hr className="border-gray-400 dark:border-gray-700"/>
              <p className="text-lg mt-4 font-semibold">
                Une solution simplifiée pour les associations sportives omnisports.
              </p>
              <p className="mt-4 italic">
                <b>MonAppliClub "Omnisports"</b>  permet de présenter l'ensemble des sections sportives de votre association. Elle comprend :
              </p>
              <ul className="text-left mt-4">
                <li>
                  ✅ Page Accueil : les informations sur l'association omnisports, les
                  différentes sections sportives, les derniers articles publiés, les
                  derniers albums photos, les palmarès, les partenaires de l'association et un
                  formulaire de contact,
                </li>
                <li>
                  ✅ Page "Sections" : permet de visualiser un mini-site de chaque section sportive avec les coordonnées, les horaires, les tarifs, les actualités, les résultats, les palmarès, les photos et un formulaire de contact,
                </li>
                <li>✅ Page "Infrastrutures" permet de repertorier l'ensemble des équipements sportifs de la commune</li>
                <li>✅ Page "Contact" affiche les coordonnées des responsables sports de la commune ainsi qu'un formulaire de contact,</li>
                <li>
                  ✅ Espace Administrateur pour gérer l'ensemble des données
                  sur le site, Chaque Responsable de section peut gérer son propre mini-site.
                </li>
              </ul>
              <div className="mt-8">
                <a
                  href="/product-monappliclub-omnisports"
                  className="px-8 py-3 bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 text-white rounded-full transition"
                >
                  En savoir plus
                </a>
                <p className="text-sm text-left mt-6">* Hors hébergements et nom de domaine.</p>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
  );
};

export default Products;
