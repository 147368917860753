import React, { useState, useEffect } from "react";
import Footer from "./../Home/Footer";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useUser } from "../../contexts/UserContext";
import AuthIcons from "../../hooks/AuthIcons";
import PartenaireKataCard from "./PartenaireKataCard";
import CreatePartenaireModal from "./CreatePartenaireModal";
import { departementsEtRegions } from "./donnees.js";
import { getAllPartenaires } from "../../services/partenaireskataServices";
import { FaFilter, FaPlus } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../components/Button/Button";
import CarteFrance from "./CarteFrance";

const PartenairesKataPage = () => {
  useScrollToTop();
  const { user } = useUser();
  
  const [currentImage, setCurrentImage] = useState("/images/fond11.webp");

  const [partenaires, setPartenaires] = useState([]);
  const [filteredPartenaires, setFilteredPartenaires] = useState([]);
  const [region, setRegion] = useState("");
  const [departement, setDepartement] = useState("");
  const [passageGrade, setPassageGrade] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);

    useEffect(() => {
    const images = [
      "/images/fond11.webp",
      "/images/fond12.webp",
      "/images/fond13.webp",
    ];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length;
      setCurrentImage(images[currentIndex]);
    }, 7000); // Change image every 7 seconds

    return () => clearInterval(interval);
  }, []);

  // Fonction pour charger tous les partenaires
  const fetchPartenaires = async () => {
    const allPartenaires = await getAllPartenaires();
    setPartenaires(allPartenaires);
    setFilteredPartenaires(allPartenaires);
  };

  // Charger les partenaires au démarrage
  useEffect(() => {
    fetchPartenaires();
  }, []);

  // Filtrage basé sur région, département et passage_grade
  useEffect(() => {
    const applyFilters = () => {
      let results = [...partenaires];
      if (region) {
        results = results.filter((partenaire) => partenaire.region === region);
      }
      if (departement) {
        results = results.filter(
          (partenaire) => partenaire.departement === parseInt(departement)
        );
      }
      if (passageGrade) {
        results = results.filter(
          (partenaire) => partenaire.passage_grade === passageGrade
        );
      }
      setFilteredPartenaires(results);
    };
    applyFilters();
  }, [region, departement, passageGrade, partenaires]);

  // Récupère la liste unique des régions pour le menu déroulant
  const regions = [
    ...new Set(departementsEtRegions.map((item) => item.region)),
  ];

  // Gestion de la mise à jour de la liste après une opération (ajout, édition, suppression)
  const refreshList = async () => {
    await fetchPartenaires();
    setCreateModalOpen(false); // Ferme la modal de création si ouverte
  };

  const grades = [
    "1er Dan",
    "2ème Dan",
    "3ème Dan",
    "4ème Dan",
    "5ème Dan",
    "6ème Dan",
    "7ème Dan",
    "8ème Dan",
    "9ème Dan",
    "10ème Dan",
  ];

  return (
    <div className="flex flex-col min-h-screen">
      {!user && <AuthIcons />}
      <div className="flex-grow">
        <div className="relative h-screen">
          <div
            className="absolute inset-0 transition-opacity duration-1000 ease-in-out bg-cover bg-center"
            style={{
              backgroundImage: `url('${currentImage}')`,
            }}
          ></div>
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
            <div className=" p-8 rounded-lg">
              <span className="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Kata
              </span>
              <p className="text-lg mb-4">
                Application pour trouver un partenaire de kata (Judo).
              </p>
            </div>
          </div>
        </div>

        {/* Filtres */}
        <section className="py-6 bg-white dark:bg-neutral-900 text-gray-800 dark:text-gray-200 shadow-md">
          <div className="container mx-auto px-4">
          <p className="mx-auto">✅ Cette application facilite la recherche de partenaires de Kata au niveau national.</p>
          <p className="mx-auto">✅ Vous pouvez déposez votre annonce et la modifier ou la supprimer.</p>
          <p className="mx-auto">✅ Seuls les dépositaires de l'annonce peuvent modifier ou supprimer leur annonce avec leur mot de passe.</p>
          <p className="mx-auto mb-4">✅ Vous pouvez filtrer les partenaires par région, département et grade.</p>
          </div>
          <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex items-center space-x-2">
              <FaFilter className="text-xl text-gray-600 dark:text-gray-400 text-primary dark:text-primary" />
              <span className="text-lg font-semibold">Filtres</span>
            </div>

            <select
              onChange={(e) => {
                setRegion(e.target.value);
                setDepartement(""); // Reset le département lorsqu'une région est sélectionnée
              }}
              value={region}
              className="border p-2 rounded-lg text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 w-full md:w-64"
            >
              <option value="">Sélectionnez une région</option>
              {regions.map((reg, index) => (
                <option key={index} value={reg}>
                  {reg}
                </option>
              ))}
            </select>

            <select
              onChange={(e) => setDepartement(e.target.value)}
              value={departement}
              className="border p-2 rounded-lg text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 w-full md:w-64"
              disabled={!region}
            >
              <option value="">Sélectionnez un département</option>
              {departementsEtRegions
                .filter((item) => item.region === region)
                .map((dep, index) => (
                  <option key={index} value={dep.code}>
                    {dep.departement}
                  </option>
                ))}
            </select>

            {/* Filtre Passage Grade */}
            <select
              onChange={(e) => setPassageGrade(e.target.value)}
              value={passageGrade}
              className="border p-2 rounded-lg text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 w-full md:w-64"
            >
              <option value="">Sélectionnez un grade</option>
              {grades.map((grade, index) => (
                <option key={index} value={grade}>
                  {grade}
                </option>
              ))}
            </select>
            </div>

            <Button
              text="Créer une annonce"
              icon={FaPlus}
              onClick={() => setCreateModalOpen(true)}
              className="gap-2 mx-auto mt-4"
            />

        </section>

        {/* Carte interactive */}
        <section className="container mx-auto py-6" >
          <CarteFrance partenaires={filteredPartenaires} />
        </section>

        {/* Liste des partenaires */}
        <section className="container mx-auto py-6 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredPartenaires.map((partenaire) => (
            <PartenaireKataCard
              key={partenaire.id}
              partenaire={partenaire}
              refreshPartenairesList={refreshList}
            />
          ))}
        </section>
        <p className="text-gray-600 mx-auto text-center text-sm italic font-light mb-4 dark:text-gray-100">RGPD : Les données collectées (coordonnées) seront conservées tant que l'utilisateur ne les aura pas supprimé</p>
      </div>

      {createModalOpen && (
        <CreatePartenaireModal
          isOpen={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          refreshList={refreshList}
        />
      )}

      <ToastContainer />
      <Footer />
    </div>
  );
};

export default PartenairesKataPage;
