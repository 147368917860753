import React, { useState, useEffect } from "react";
import {
  getSentMessagesByUser,
  deleteMessageById,
} from "../../../../services/messageServices";
import { useMessage } from "./../../../../contexts/MessageContext";
import { BsEnvelopeArrowUpFill } from "react-icons/bs";
import ButtonDelete from "./../../../../components/Button/ButtonDelete";
import { toast } from "react-toastify";

const MessagesSent = ({ userId, refresh, setRefresh }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (refresh) {
      loadMessages();
      setRefresh(false);
    }
  }, [refresh]);

  const loadMessages = async () => {
    if (!userId) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      return;
    }

    try {
      const data = await getSentMessagesByUser(userId);
      const filteredMessages = data.filter((message) => message.is_copy);
      setMessages(filteredMessages);
    } catch (error) {
      console.error("Erreur lors du chargement des messages:", error);
    }
  };

  const promptDeleteMessage = (messageId) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer le message ?");
    if (confirmDelete) {
      handleDeleteMessage(messageId);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessageById(messageId);
      setMessages(messages.filter((message) => message.id !== messageId));
      toast.success("Message supprimé avec succès.");
    } catch (error) {
      console.error("Error deleting message:", error);
      toast.error("Erreur lors de la suppression du message.");
    }
  };

  useEffect(() => {
    loadMessages();
  }, [userId]);

  return (
    <div className="mt-5 container mx-auto p-4 bg-gray-600 text-gray-200 border border-black">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <BsEnvelopeArrowUpFill className="mr-2 text-2xl" />
          Messages Envoyés
        </h3>
      </div>
      {messages.length > 0 ? (
        <>
          {/* Tableau pour mode desktop */}
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-gray-500 divide-y divide-gray-600">
              <thead>
                <tr className="bg-gray-800">
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Détails
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-600">
                {messages.map((message) => (
                  <tr key={message.id} className="hover:bg-gray-700">
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-200">
                      {new Date(message.created_at).toLocaleDateString("fr-FR")}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-200">
                      <div className="font-semibold">
                        {`${message.Recipient.nom} ${message.Recipient.prenom}`}
                      </div>
                      <div className="text-blue-400 font-bold">
                        {message.subject}
                      </div>
                      <div
                        className="text-gray-300"
                        dangerouslySetInnerHTML={{ __html: message.content }}
                      ></div>
                    </td>
                    <td className="px-4 py-2 text-right text-sm font-medium gap-2 flex">
                      <ButtonDelete
                        onClick={() => promptDeleteMessage(message.id)}
                        hoverLabel="Supprimer"
                        className="bg-gray-700 text-gray-200"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cartes pour mode mobile */}
          <div className="block sm:hidden">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`shadow-md rounded-lg p-4 mb-4 ${
                  message.is_read ? "bg-gray-600" : "bg-gray-500"
                }`}
              >
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-semibold text-gray-200">
                    {new Date(message.created_at).toLocaleDateString("fr-FR")}
                  </span>
                  <div className="flex space-x-2">
                    <ButtonDelete
                      onClick={() => promptDeleteMessage(message.id)}
                      hoverLabel="Supprimer"
                      className="bg-gray-700 text-gray-200"
                    />
                  </div>
                </div>
                <div className="text-sm text-gray-300">
                  <div className="font-semibold">
                    {`${message.Recipient.nom} ${message.Recipient.prenom}`}
                  </div>
                  <div className="text-blue-400 font-bold">
                    {message.subject}
                  </div>
                  <div
                    className="text-gray-300"
                    dangerouslySetInnerHTML={{ __html: message.content }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center py-4 bg-gray-600 text-gray-200">
          Aucuns messages.
        </div>
      )}
    </div>
  );
};

export default MessagesSent;
