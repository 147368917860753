import React from "react";
import { Routes, Route } from "react-router-dom";
import ListingUsers from "./AppliAdmin/ListingUsers/ListingUsers";
import ListingAssociations from "./AppliAdmin/ListingAssociations/ListingAssociations";
import ListingTasks from "./AppliAdmin/ListingTasks/ListingTasks";
import DashboardAdmin from "./AppliAdmin/DashboardAdmin/DashboardAdmin";
import Messaging from "./AppliAdmin/Messaging/Messaging";
import Notifications from "./AppliAdmin/Notifications/Notifications";
import ContactForm from "./AppliAdmin/ContactForm/ContactForm";
import ListingProducts from "./AppliAdmin/ListingProducts/ListingProducts";
import ListingDepensesPro from "./AppliAdmin/ListingDepensesPro/ListingDepensesPro";
import ListingEcheances from "./AppliAdmin/ListingEcheances/ListingEcheances";
import RegisterAssociationForm from "./AppliUser/RegisterAssociationForm";
import MonAssociationPage from "./AppliUser/MonAssociationPage/MonAssociationPage";
import Profil from "./AppliAdmin/Profil/Profil";
import PricingPageForProspects from "./AppliUser/PricingPageForProspects/PricingPageForProspects";
import SubscriptionsPage from "./AppliAdmin/SubscriptionsPage/SubscriptionsPage";
import ListingReglements from "./AppliAdmin/Reglements/ListingReglements";
import Tutoriel from "./AppliUser/Tutoriel/Tutoriel";
import ListingAgenda from "./AppliAdmin/ListingAgenda/ListingAgenda";
import DesinscriptionsPage from "./AppliAdmin/DesinscriptionsPage/DesinscriptionsPage";
import ParrainagePage from "./AppliAdmin/Parrainage/ParrainagePage";
import useScrollToTop from "../../hooks/useScrollToTop";

const MainContent = ({ role_id, isMenuOpen }) => {
  useScrollToTop();
  return (
    <div className={`${isMenuOpen ? "ml-72" : "ml-20"} flex-1 p-4 bg-gray-400 overflow-y-auto h-screen transition-all duration-300`}>
      <Routes>
        {role_id === 1 && (
          <>
            <Route path="/listing-users" element={<ListingUsers />} />
            <Route path="/listing-associations" element={<ListingAssociations />} />
            <Route path="/listing-rendez-vous" element={<ListingAgenda />} />
            <Route path="/liste-taches" element={<ListingTasks />} />
            <Route path="/dashboard-admin" element={<DashboardAdmin />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/messaging" element={<Messaging />} />
            <Route path="/contact-form" element={<ContactForm />} />
            <Route path="/products" element={<ListingProducts />} />
            <Route path="/depenses" element={<ListingDepensesPro />} />
            <Route path="/echeances" element={<ListingEcheances />} />
            <Route path="/subscriptions" element={<SubscriptionsPage />} />
            <Route path="/reglements" element={<ListingReglements />} />¨
            <Route path="/parrainage" element={<ParrainagePage />} />
            <Route path="/profil" element={<Profil />} />
            <Route path="/desinscriptions-admin" element={<DesinscriptionsPage />} />
          </>
        )}
        {(role_id === 2  || role_id ===3) && (
          <>
            <Route path="/notifications" element={<Notifications />} /> 
            <Route path="/messaging" element={<Messaging />} />
            <Route path="/inscription-association" element={<RegisterAssociationForm />} />
            <Route path="/mon-association" element={<MonAssociationPage />} />
            <Route path="/tarifs" element={<PricingPageForProspects />} />
            <Route path="/subscriptions" element={<SubscriptionsPage />} />
            <Route path="/profil" element={<Profil />} />
            <Route path="/tutoriel" element={<Tutoriel />} />
          </>
        )}
        <Route
          path="*"
          element={<div>Bienvenue dans votre espace personnel</div>}
        />
      </Routes>
    </div>
  );
};

export default MainContent;
