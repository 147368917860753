import React from 'react';

const Button = ({ text, onClick, icon: Icon, className = '', hoverLabel = '', disabled = false }) => {
  return (
    <button
      onClick={disabled ? null : onClick} // Désactiver onClick si disabled est true
      aria-label={hoverLabel} // Bon pour l'accessibilité
      title={hoverLabel} // Ajout de l'infobulle au survol
      className={`px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out flex items-center justify-center border 
        ${disabled ? 'bg-gray-400 text-gray-700 border-gray-400 cursor-not-allowed' : 'bg-primary text-white hover:bg-white hover:text-primary border-primary'} 
        ${className}`}
      disabled={disabled} // Ajout de l'attribut disabled
    >
      {Icon && <Icon className="text-xl" />}
      {text}
    </button>
  );
};

export default Button;
