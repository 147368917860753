import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css"; // Importez les styles de React Toastify

// Vérifiez si le navigateur supporte les Service Workers
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js") // Enregistrer le fichier firebase-messaging-sw.js
    .then((registration) => {
      console.log("Service Worker enregistré avec succès :", registration);
    })
    .catch((err) => {
      console.error("Erreur lors de l'enregistrement du Service Worker :", err);
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
