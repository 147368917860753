import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { getAllDepensesPro } from "../../../../services/depensesproServices";
import { format, parseISO } from "date-fns";

const DepensesProByMonthChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchDepenses = async () => {
      try {
        const depenses = await getAllDepensesPro();

        // Regroupement des dépenses par mois
        const depensesByMonth = depenses.reduce((acc, depense) => {
          const month = format(parseISO(depense.date_depense), 'yyyy-MM');
          if (!acc[month]) {
            acc[month] = {
              month,
              total: 0,
            };
          }
          acc[month].total += depense.montant_depense;
          return acc;
        }, {});

        // Transformation en tableau pour Recharts
        const chartData = Object.values(depensesByMonth).sort((a, b) => new Date(a.month) - new Date(b.month));
        setData(chartData);
      } catch (error) {
        console.error("Erreur lors de la récupération des données de dépenses", error);
      }
    };

    fetchDepenses();
  }, []);

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-4 dark:bg-gray-900 dark:text-gray-200">
      <h3 className="text-lg font-bold text-white mb-4">Dépenses Pro par Mois</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip formatter={(value) => `${value.toFixed(2)} €`} />
          <Legend />
          <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} name="Dépenses en €" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DepensesProByMonthChart;
