import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/agendaclient`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les rendez-vous
export const getAllAgendaClients = async () => {
  try {
    const response = await axios.get(`${apiUrl}/AllAgendaClients`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      console.log("Réponse API :", response.data);
      return response.data; // ou response.data.data si votre API encapsule les données
    } else {
      console.error("Les données de l'API sont vides ou non définies.");
      return [];
    }
  } catch (error) {
    console.error("Erreur lors du chargement des rendez-vous:", error);
    return []; // Renvoie un tableau vide en cas d'erreur
  }
};

// Fonction pour compter le nombre de rendez-vous
export const countAgendaClients = async () => {
  try {
    const response = await axios.get(`${apiUrl}/CountAgendaClients`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du nombre de rendez-vous:",
      error
    );
  }
};

// Fonction pour récupérer un rendez-vous par son id
export const getAgendaClientById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/AgendaClientById/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du rendez-vous:", error);
  }
};

// Fonction pour récupérer les rendez-vous d'une association
export const getAgendaClientsByAssociationId = async (id) => {
  try {
    const response = await axios.get(
      `${apiUrl}/AgendaClientsByAssociationId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des rendez-vous de l'association:",
      error
    );
  }
};

/// POST
// Fonction pour ajouter un rendez-vous
export const addAgendaClient = async (agendaClient) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addAgendaClient`,
      agendaClient,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout du rendez-vous:", error);
  }
};

/// PATCH
// Fonction pour modifier un rendez-vous
export const updateAgendaClient = async (id, agendaClient) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updateAgendaClient/${id}`,
      agendaClient,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du rendez-vous:", error);
  }
};

/// DELETE
// Fonction pour supprimer un rendez-vous
export const deleteAgendaClient = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteAgendaClient/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du rendez-vous:", error);
  }
};
