import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollToTop";
import {
  checkResetPasswordToken,
  resetPassword,
} from "./../../services/passwordServices";
import backgroundImage from "./HeaderSlider_02.jpg";

function ResetPasswordPage() {
  useScrollToTop();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    console.log("Début de la vérification du token:", token); // Assurez-vous que le token est correct
    const verifyToken = async () => {
      const result = await checkResetPasswordToken(token);
      console.log("Résultat de la vérification:", result); // Ce log aidera à comprendre ce que l'API retourne

      if (!result.valid) {
        setError(
          result.message || "Token de réinitialisation invalide ou expiré."
        );
        // navigate("/login"); // Temporairement commenté pour le débogage
      }
    };
    verifyToken();
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }
    const result = await resetPassword(token, password);
    if (result.success) {
      alert(result.message);
      navigate("/login");
    } else {
      setError(result.message);
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-lg shadow-lg">
        <form onSubmit={handleSubmit} className="space-y-6">
          <h1 className="text-center text-3xl font-bold text-gray-900">
            Réinitialisation du mot de passe
          </h1>
          {error && (
            <div
              className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg"
              role="alert"
            >
              {error}
            </div>
          )}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nouveau mot de passe:
            </label>
            <input
              type="password"
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Confirmez le mot de passe:
            </label>
            <input
              type="password"
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-blue-600"
          >
            Réinitialiser le mot de passe
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
