import React from 'react';
import {FaInfo} from 'react-icons/fa';

const ButtonDetails = ({onClick, className = '', hoverLabel = 'Détails'}) => {
  return (
    <button
      onClick={onClick}
      aria-label={hoverLabel}
      title={hoverLabel}
      className={`w-8 h-8 bg-primary text-white rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-primary-dark flex items-center justify-center ${className}`}
    >
      <FaInfo className='text-[12px]'/>
    </button>
  );
};

export default ButtonDetails;