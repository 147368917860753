import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CalendarView = ({ events, onSelectEvent }) => {
  return (
    <div className="bg-gray-200 dark:bg-gray-900 p-4 rounded-lg">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        defaultView="month"
        views={['month', 'week', 'day']}
        onSelectEvent={onSelectEvent} // Utilise la fonction passée en prop
        eventPropGetter={(event) => {
          const backgroundColor =
            event.type === 'sur place'
              ? '#38a169'
              : event.type === 'téléphonique'
              ? '#3182ce'
              : '#805ad5';
          return { style: { backgroundColor } };
        }}
      />
    </div>
  );
};

export default CalendarView;
