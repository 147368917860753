import React, { useState, useEffect } from "react";
import { getAllAssociations } from "../../../../services/associationsServices";

const AssociationsShortcuts = () => {
  const [associations, setAssociations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchAssociations = async () => {
      try {
        const response = await getAllAssociations();
        setAssociations(response);
      } catch (error) {
        console.error("Erreur lors de la récupération des associations", error);
      }
    };

    fetchAssociations();
  }, []);

  // Filtrer les associations selon le terme de recherche
  const filteredAssociations = associations.filter((association) =>
    association.nom_asso.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mt-4">
      <div className="text-center mb-4">
        <input
          type="text"
          placeholder="Rechercher une association"
          className="p-2 border border-gray-300 rounded-lg w-full"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {filteredAssociations.map((association) => (
          <div
            key={association.id}
            className="flex flex-col items-center bg-gray-800 rounded-lg p-4 shadow-lg dark:bg-gray-900 dark:text-gray-200"
          >
            <a href={association.siteweb_asso} target="_blank" rel="noopener noreferrer">
              <img
                src={association.logo_asso}
                alt={association.nom_asso}
                className="w-16 h-16 object-contain mb-2"
              />
            </a>
            <p className="text-white">{association.nom_asso}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssociationsShortcuts;
