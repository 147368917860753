import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/associations`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les associations
export const getAllAssociations = async () => {
  try {
    const response = await axios.get(`${apiUrl}/AllAssociations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      console.log("Réponse API :", response.data);
      return response.data; // ou response.data.data si votre API encapsule les données
    } else {
      console.error("Les données de l'API sont vides ou non définies.");
      return [];
    }
  } catch (error) {
    console.error("Erreur lors du chargement des associations:", error);
    return []; // Renvoie un tableau vide en cas d'erreur
  }
};

// Fonction pour compter le nombre d'associations
export const countAssociations = async () => {
  try {
    const response = await axios.get(`${apiUrl}/CountAssociations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la récupération du nombre d'associations:", error);
  }
}

// Fonction pour compter le nombre d'associations avec le statut_asso = "Client"
export const countClientAssociations = async () => {
  try {
    const response = await axios.get(`${apiUrl}/CountClientAssociations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la récupération du nombre d'associations clients:", error);
  }
}

// Fonction pour afficher les 5 denières associations créees
export const getLatestAssociations = async () => {
  try {
    const response = await axios.get(`${apiUrl}/LastFiveAssociations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des dernières associations:", error);
  }
};

// Fonction pour récupérer une association par son ID
export const getAssociationById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/AssociationById/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement de l'association:", error);
  }
};

// Fonction pour récupérer les associations favorites
export const getFavoriteAssociations = async () => {
  try {
    const response = await axios.get(`${apiUrl}/FavoriteAssociations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des associations favorites:", error);
  }
};

// Fonction pour afficher toutes les associations d'un utilisateur
export const getAssociationsByUserId = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/AssociationsByUserId/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des associations de l'utilisateur:", error);
  }
};

/// POST
// Fonction pour sauvegarder une association
export const saveAssociation = async (formData) => {
  try {
    await axios.post(`${apiUrl}/addAssociation`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Association sauvegardée avec succès");
  } catch (error) {
    console.log("formData:", formData);
    console.error("Erreur lors de la sauvegarde de l'association:", error);
  }
};

/// PATCH
// Fonction pour mettre à jour une association
export const updateAssociation = async (id, formData) => {
  try {
    await axios.patch(`${apiUrl}/updateAssociation/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Association mise à jour avec succès");
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'association:", error);
  }
};

/// DELETE
// Fonction pour supprimer une association
export const deleteAssociation = async (id) => {
  try {
    await axios.delete(`${apiUrl}/deleteAssociation/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Association supprimée avec succès");
  } catch (error) {
    console.error("Erreur lors de la suppression de l'association:", error);
  }
};
