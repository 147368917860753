// Fonction qui attribue une catégorie d'âge en fonction de l'année de naissance et de la saison active
const getCategory = (dateOfBirth, saisonActive, gender) => {
  if (!dateOfBirth || !saisonActive) return "Inconnu";

  const birthYear = new Date(dateOfBirth).getFullYear();
  const startYear = parseInt(saisonActive.split("-")[0], 10); // Année de début de la saison
  const age = startYear - birthYear; // Calcul de l'âge

  // Définition des catégories par année de naissance pour la saison active
  if (age >= 3 && age <= 5) return "Eveil";
  if (age >= 6 && age <= 7) return "Mini-Poussin";
  if (age >= 8 && age <= 9) return "Poussin";
  if (age >= 10 && age <= 11) return "Benjamin";
  if (age >= 12 && age <= 13) return "Minime";
  if (age >= 14 && age <= 16) return "Cadet";
  if (age >= 17 && age <= 19) return "Junior";
  if (age >= 20 && age <= 29) return "Sénior";

  // Catégories Vétérans en fonction de l'âge
  if (age >= 30 && age <= 34) return gender === "Masculin" ? "M1" : "F1";
  if (age >= 35 && age <= 39) return gender === "Masculin" ? "M2" : "F2";
  if (age >= 40 && age <= 44) return gender === "Masculin" ? "M3" : "F3";
  if (age >= 45 && age <= 49) return gender === "Masculin" ? "M4" : "F4";
  if (age >= 50 && age <= 54) return gender === "Masculin" ? "M5" : "F5";
  if (age >= 55 && age <= 59) return gender === "Masculin" ? "M6" : "F6";
  if (age >= 60 && age <= 64) return gender === "Masculin" ? "M7" : "F7";
  if (age >= 65 && age <= 69) return gender === "Masculin" ? "M8" : "F8";
  if (age >= 70 && age <= 74) return gender === "Masculin" ? "M9" : "F9";
  if (age >= 75 && age <= 79) return gender === "Masculin" ? "M10" : "F10";
  if (age >= 80) return gender === "Masculin" ? "M11" : "F11";

  return "Inconnu";
};

export default getCategory;
