import React, { useState } from 'react';
import {
  FaUsers, FaCalendarAlt, FaEnvelope, FaMoneyBillWave, FaUserCheck,
  FaChartBar, FaAddressCard, FaCartPlus, FaRegCreditCard, FaUserCircle,
  FaFileSignature, FaRegNewspaper, FaImage, FaComment, FaHourglassHalf, FaCalculator, FaShoppingBag    
} from "react-icons/fa";
import { IoMdContacts, IoMdSettings } from "react-icons/io";
import { GiBlackBelt, GiKimono, GiPodiumWinner, GiPodium, GiGong } from "react-icons/gi";
import { MdDashboard, MdTimer, MdOutlineInventory   } from "react-icons/md";
import { PiTranslateFill } from "react-icons/pi";
import { LuListTodo } from "react-icons/lu";
import ComponentTitle from "./../../../Home/ComponentTitle";

const linkIcon = {
  "Tableau de Bord": <MdDashboard />,
  "Dossier d'Inscription": <FaAddressCard />,
  "Fiche Adhérent": <FaUserCircle />,
  "Calendrier": <FaCalendarAlt />,
  "Messages": <FaEnvelope />,
  "Chat": <FaComment />,
  "Boutique": <FaCartPlus />,
  "Commandes & Règlements": <FaRegCreditCard />,
  "Techniques Judo": <GiKimono />,
  "Actualités": <FaRegNewspaper  />,
  "Lexique": <PiTranslateFill  />,
  "Arbitrage Judo": <GiGong  />,
  "Galerie Photos": <FaImage  />,
  "Listing Adhérents": <IoMdContacts />,
  "Passage Ceinture": <GiBlackBelt />,
  "Grade CN": <GiBlackBelt />,
  "Suivi Compétitions": <GiPodiumWinner />,
  "Palmarès": <GiPodium />,
  "ToDoList": <LuListTodo  />,
  "Événements": <FaCalendarAlt />,
  "Appel Présence": <FaUserCheck />,
  "Dashboard Adhérents": <FaChartBar />,
  "Dashboard Comptabilité": <FaChartBar />,
  "Détails Adhérent": <FaUserCircle />,
  "Listing Utilisateurs": <FaUsers />,
  "Listing Adhérents Admin": <FaUsers />,
  "Règlements Inscriptions": <FaMoneyBillWave />,
  "Dossiers d'Inscription": <FaFileSignature />,
  "Achats Boutique": <FaCartPlus />,
  "Articles Admin": <FaShoppingBag  />,
  "Dépenses Recettes": <FaCalculator />,
  "Coachings": <MdTimer />,
  "Heures Enseignement": <FaHourglassHalf  />,
  "Inventaire": <MdOutlineInventory  />,
  "Chat Page Admin": <FaComment />,
  "Message Admin": <FaEnvelope />,
  "Paramètres": <IoMdSettings />,
};

const linkDescriptions = {
  "Tableau de Bord": "Vue d'ensemble de vos activités et informations clés.",
  "Dossier d'Inscription": "Gérez vos informations d'inscription et documents.",
  "Fiche Adhérent": "Accédez à votre profil personnel et informations.",
  "Calendrier": "Consultez les événements et possibilité de s'y inscrire.",
  "Messages": "Envoyez et recevez des messages.",
  "Chat": "Communiquez en direct avec votre groupe.",
  "Boutique": "Accédez à la boutique en ligne du club.",
  "Commandes & Règlements": "Suivez vos achats et effectuez des paiements.",
  "Techniques Judo": "Explorez les techniques de judo.",
  "Actualités": "Restez informé des dernières nouvelles.",
  "Lexique": "Découvrez les termes du judo en français et japonais.",
  "Arbitrage Judo": "Apprenez les règles d'arbitrage.",
  "Galerie Photos": "Consultez les photos des événements passés.",
  "Listing Adhérents": "Accédez à la liste des adhérents du club.",
  "Passage Ceinture": "Suivez les progressions de ceinture.",
  "Grade CN": "Gérez les grades CN des adhérents.",
  "Suivi Compétitions": "Suivez la performance des adhérents en compétition.",
  "Palmarès": "Affichez les palmarès des compétitions.",
  "ToDoList": "Gérez votre liste de tâches.",
  "Événements": "Planifiez et suivez les événements du club.",
  "Appel Présence": "Faites l'appel des adhérents présents.",
  "Dashboard Adhérents": "Vue d'ensemble des adhérents.",
  "Dashboard Comptabilité": "Surveillez les finances du club.",
  "Détails Adhérent": "Accédez aux informations détaillées d'un adhérent.",
  "Listing Utilisateurs": "Gérez tous les utilisateurs de l'application.",
  "Listing Adhérents Admin": "Accédez à la liste complète des adhérents.",
  "Règlements Inscriptions": "Gérez les paiements des inscriptions.",
  "Dossiers d'Inscription": "Suivez les dossiers d'inscription.",
  "Achats Boutique": "Gérez les achats effectués via la boutique.",
  "Articles Admin": "Gérez les articles de la boutique en ligne.",
  "Dépenses Recettes": "Suivez les dépenses et recettes du club.",
  "Coachings": "Gérez les sessions de coaching.",
  "Heures Enseignement": "Enregistrez les heures de présence des enseignants.",
  "Inventaire": "Gérez l'inventaire des équipements.",
  "Chat Page Admin": "Surveillez les discussions en tant qu'administrateur.",
  "Message Admin": "Gérez les messages administratifs.",
  "Paramètres": "Personnalisez les paramètres du club et du site.",
};

const linkDetails = {
  // Ajoutez les détails ici...
  "Tableau de Bord": `Le tableau de bord vous permet de visualiser vos dernières notifications, vos messages reçus, les événements sur lequels un de vos adhérents est inscrit.
  <br>🔸En tant qu'<b>adminitrateur</b> les messages du formulaire de contact de l'accueil sont affichés.`,

  "Dossier d'Inscription": `La dossier d'inscription regroupe toutes les informations essentielles nécessaires pour l'inscription des adhérents. Vous pouvez gérer et suivre les statuts des inscriptions. <br>🔸Un petit tableau affiche les adhérents que vous gérez,<br>🔸Vous pouvez accéder au formulaire d'inscription pour créér un nouvel adhérent,<br>🔸Vous pouvez télécharger les Questionnaires Santé,<br>🔸Pour chaque adhérent, vous avez accès aux informations du dossier d'inscription,<br>🔸Vous pouvez télécharger la licence-assurance actuelle,<br>🔸Vous pouvez déposer vos documents dans un Google Drive ou autre moyen de stockage de fichiers (certificats médicaux, formulaire de licence signé, photo, ...),<br>🔸Vous pouvez signaler à l'administrateur que vous avez déposé des documents, <br>🔸Vous pouvez générer une attestation d'inscription après la validation du dossier d'inscription,<br>🔸Vous pouvez réinscrire votre adhérent pour la prochaine saison.`,
  
  "Fiche Adhérent": `Cette page permet d'afficher pour chacun de vos adhérents :<br>🔸Les coordonnées,<br>🔸les groupes et catégories de poids et d'âge en fonction de la date de naissance,<br>🔸Un historique des passages de ceinture et UV pour la ceinture noire,<br>🔸Le palmarès et résultats sur les derniers évènements,<br>🔸L'Utilisateur peut changer ses données sur les coordonnées et le poids de l'adhérent,<br>🔸L'enseignant et administrateur aura accès à des boutons d'appels téléphonques, sms et mail.`, 

  "Calendrier": `Le calendrier affiche les événements à venir et passés.<br>🔸 Vous pouvez afficher les informations pour chaque événement (contenu, date, lieu, type, niveau, catégories d'âges, horaires, coachs et adhérents inscrits),<br>🔸Vous pouvez inscrire ou désinscrire un adhérent sur un événement, l'enseignant ou administrateur reçoit une notification, il pourra ainsi valider l'inscription en basculant 🔲 en ✅,<br>🔸Vous pouvez liker un évènement,<br>🔸Vous pouvez filtrer les évènements : recherche par terme, catégories d'âge, types, niveaux, A venir ou passés.`,

  "Messages": `La messagerie vous permet d'échanger des messages avec les enseignants et bureau du club. <br>🔸En tant qu'enseignant ou administrateur, vous pouvez envoyer un message à n'importe quel utilisateur,<br>🔸Les enseignants et administrateurs ont la possibilité de créer des messages de groupe.`,

  "Chat": `Le chat permet de communiquerau sein des groupes de judokas. <br>🔸L'utilisateur peut discuter à l'intérieur du groupe de ses adhérents,<br>🔸L'enseignant et l'administrateur peuvent communiquer sur tous les groupes de discussion,<br>🔸Ils ont également la possibilité de modérer les discussions et effacer les messages.`,

  "Boutique": `La boutique affiche les articles en vente au sein du club.<br>🔸Pour commander un article, appuyez sur "Ajouter au Panier",<br>🔸Ensuite allez dans la section "Afficher mon Panier",<br>🔸Le ou les articles sont présents dans le panier, il vous suffit de choisir la quantité, la taille ou commentaires s'il y a besoin,<br>🔸En passant la commande, vous pouvez régler le ou les articles dans la section "Commandes & Règlements"`,

  "Commandes & Règlements": `La section Commandes & Règlements permet de suivre les commandes passées et les règlements effectués.<br>🔸Vous pouvez suivre l'état de votre commande, si elle est en attente, en cours ou terminée,<br>🔸Vous pouvez régler votre commande en ligne, par chèque ou en espèces,`,

  "Actualités": `La section Actualités permet de consulter les dernières nouvelles du club et résultats.<br>🔸Vous pouvez consulter les articles et les commenter,<br>🔸Vous pouvez filtrer les articles par catégories,<br>🔸En tant qu'enseignant ou administrateur, vous pouvez créer, modifier ou supprimer un article`,

  "Galerie Photos": `La galerie photos permet de consulter les photos des événements passés.<br>🔸Vous pouvez consulter des photos contenus dans des albums<br>🔸Les enseignants et administrateurs peuvent ajouter, modifier et supprimer des albums et des photos`,

  "Lexique": `Le lexique permet de consulter les termes du judo en français et japonais.<br>`,

  "Arbitrage Judo": `La section Arbitrage Judo permet d'apprendre les règles d'arbitrage.<br>`,

  "Techniques Judo": `La section Techniques Judo permet d'explorer les techniques de judo.<br>🔸un filtre permet de trier les techniques par ceinture, par famille,<br>🔸Pour chaque technique une image et une vidéo sont associées,<br>🔸Vous pouvez Liker un technique afin de la retrouver plus facilement `,

  "Listing Adhérents": `La section Listing Adhérents permet d'accéder à la liste des adhérents du club.<br>🔸Vous pouvez rechercher un adhérent par son nom, prénom, son groupe,<br>🔸Vous pouvez afficher les anniversaires du jour et leur envoyer un message,<br>🔸L'enseignant à la possibilité de voir la fiche adhérent, éditer sa fiche ou gérer les groupes,<br>🔸Il a accès facilement au téléphone, sms et mail de l'adhérent`,

  "Appel Présence": `La section Appel Présence permet de faire l'appel des adhérents présents.<br>🔸Vous pouvez faire l'appel pour un cours ou un événement en fonction du groupe choisi,<br>🔸Vous avez accés aux statistiques de présence,`,

  "Passage Ceinture": `La section Passage Ceinture permet de suivre les progressions de ceinture.<br>🔸Vous pouvez consulter les passages de ceinture de chaque adhérent,<br>🔸Vous pouvez valider un passage de ceinture,`,

  "Grade CN": `La section Grade CN permet de gérer les passages des UV pour l'obtention de la ceinture noire.<br>🔸Vous pouvez consulter, créer; modifier et supprimer les grades CN de chaque adhérent,`,

  "Événements": `La section Événements permet de planifier et suivre les événements du club.<br>🔸Vous pouvez consulter les événements à venir et passés, les créer, modifier et supprimer,<br>🔸Vous pouvez afficher ou non un événement dans le caldendrier,<br>🔸Vous pouvez télécharger un fichier excel pour remplir automatiquement le tableau des événements `,

  "Suivi Compétitions": `La section Suivi Compétitions permet de suivre la performance des adhérents en compétition.<br>🔸Vous pouvez consulter les résultats des compétitions, les palmarès, les inscriptions, les catégories d'âge, les niveaux, les coachs, les adhérents inscrits,`,

  "Palmarès": `La section Palmarès permet d'afficher les palmarès des compétitions.<br>🔸Vous pouvez consulter les résultats des compétitions par saison, les modifier ou supprimer`,

  "ToDoList": `La section ToDoList permet de gérer votre liste de tâches.<br>🔸Vous pouvez ajouter, modifier, supprimer une tâche, la marquer comme terminée,`,

  "Dashboard Adhérents": `Le Dashboard Adhérents permet d'avoir une vue d'ensemble des adhérents.<br>🔸Vous pouvez consulter les statistiques des adhérents, les groupes, les derniers inscrits,<br>🔸 statistiques de visite sur le site,<br>🔸la répartition sur les cours sous forme de graphiques, <br>🔸Répartition par genre et âge,<br>🔸Statistiques des adhérents par commune, genre et tranche d'âge pour les demandes de subventions,<br>🔸Répartition sur les communes, <br>🔸Statistiques des licenciés par groupe`,

  "Dashboard Comptabilité":`Permet d'afficher par saison les statistiques des Règlements de cotisation, achats boutique, dépenses et recettes,<br>🔸il permet de visualiser sous forme de graphiques la répartition des statuts et types de règlement `,

  "Détails Adhérent": `Affiche toutes les données d"un adhérent, ses coordonnées, ses groupes, ses catégories d'âge, son historique de passage de ceinture, son palmarès, ses résultats ...,`,

  "Listing Utilisateurs": `La section Listing Utilisateurs permet de gérer tous les utilisateurs de l'application.<br>🔸Vous pouvez rechercher un utilisateur par son nom, prénom,<br>🔸Vous pouvez affecter ou retirer un adhérent à un utilisateur,<br>🔸Vous pouvez modifier les informations d'un utilisateur, le supprimer, changer son rôle`,

  "Listing Adhérents Admin": `La section Listing Adhérents Admin permet d'accéder à la liste complète des adhérents.<br>🔸Vous pouvez filtrer les adhérents, modifier ou supprimer.`,

  "Règlements Inscriptions": `C'est ici que vous allez rentrer les informations concernant le règlement des inscriptions, les réductions, pass'Sport ...<br>🔸Vous pouvez consulter les règlements, les modifier, les supprimer,`,

  "Dossiers d'Inscription": `La section Dossiers d'Inscription permet de suivre les dossiers d'inscription.<br>🔸Vous pouvez consulter les fichiers se trouvant dans le Google Drive, et valider le Certificat Médical, Formulaire de licence signée et règlement validé`,

  "Achats Boutique": `La section Articles Admin permet de les achats boutique et règlements.<br>🔸C'est ici que vous allez pouvoir ajouter des achats boutiques manuellement ou envoyer le règlement Licence-Cotisation à l'utilisateur afin qu'il puisse le régler en ligne sur la section "Commandes & Réglements",`,

  "Articles Admin": `La section Articles Admin permet de gérer les articles de la boutique en ligne.<br>🔸Vous pouvez ajouter, modifier, supprimer un article,<br>🔸C'est ici également que vous gérez les tarifs des licences, cotisations, réduction ...`,

  "Dépenses Recettes": `La section Dépenses Recettes permet de suivre les dépenses et recettes courantes du club.<br>🔸Vous pouvez consulter les dépenses et recettes, les modifier, créer et supprimer,<br>🔸Un mein vers la facture est disponible`,

  "Coachings": `La section Coachings permet de gérer heures eneregistrées pour les enseignants qui coachent en compétition.<br>🔸Vous pouvez consulter les coachings, les modifier, créer et supprimer,<br>🔸Vous pouvez consulter les heures enregistrées par enseignant, modifier ou supprimer les heures.<br>🔸L'enseignant peut enregistrer de son côté ses heures dans la section "Suivi Compétition" en cliquant sur sa photo lorsqu'il est inscrit sur une compétition. `,

  "Heures Enseignement": `La section Heures Enseignement permet de suivre les heures de présence des enseignants.<br>🔸Vous pouvez consulter les heures, les modifier, créer et supprimer,<br>🔸Récapitulatif par mois ou par année `,

  "Inventaire": `La section Inventaire permet de gérer l'inventaire des équipements que ce soit des rouleaux de ceintures, kimonos, pharmacie ....<br>`,

  "Chat Page Admin": `La section Chat Page Admin permet de surveiller les discussions en tant qu'administrateur.<br>🔸Vous pouvez consulter les messages, les modifier, créer et supprimer,<br>🔸Vous pouvez modérer les messages, les effacer,`,

  "Message Admin": `La section Message Admin permet de gérer les messages administratifs.<br>🔸Vous pouvez consulter les messages, les modifier, créer et supprimer,<br>`,

  "Paramètres": `La section Paramètres permet de personnaliser les paramètres du club et du site. C'est ici que vous allez : <br>🔸Définir les groupes,<br>🔸Définir les membres des équipes sportives et dirigeantes,<br>🔸Choisir ses disciplines sportives, <br>🔸Rentrer les données concernant l'association, <br>🔸Paramétrer la page Accueil,<br>🔸Définir le nombre de règlement maximum et espacement pour un réglement en ligne,<br>🔸Paramétrer le Règlement Intérieur,<br>🔸Ajouter des Partenaires et sponsors du Club, <br>🔸Définir les pages à afficher ou masquer`,
};

const Features = () => {
  const [activeRole, setActiveRole] = useState("Adhérent");
  const [selectedFeature, setSelectedFeature] = useState(null);

  const sections = {
    Adhérent: [
      "Tableau de Bord", "Dossier d'Inscription", "Fiche Adhérent", "Calendrier", "Messages", "Chat",
      "Boutique", "Commandes & Règlements", "Actualités", "Galerie Photos", "Lexique", "Arbitrage Judo", "Techniques Judo"
    ],
    Enseignant: [
      "Listing Adhérents", "Appel Présence", "Passage Ceinture", "Grade CN", "Événements", "Suivi Compétitions",
      "Palmarès", "ToDoList"
    ],
    Dirigeant: [
      "Dashboard Adhérents", "Dashboard Comptabilité", "Détails Adhérent", "Listing Utilisateurs",
      "Listing Adhérents Admin", "Règlements Inscriptions", "Dossiers d'Inscription", "Achats Boutique",
      "Articles Admin", "Dépenses Recettes", "Coachings", "Heures Enseignement", "Inventaire",
      "Chat Page Admin", "Message Admin", "Paramètres"
    ],
  };

  const getCardBackgroundColor = (sectionLabel) => {
    if (sectionLabel === "Enseignant") return "bg-white hover:bg-blue-100";
    if (sectionLabel === "Dirigeant") return "bg-white hover:bg-green-100";
    return "bg-white hover:bg-gray-100";
  };

  const getBubbleStyle = (role) => {
    const activeStyle = "bg-gradient-to-br from-blue-600 to-blue-400 text-white";
    const inactiveStyle = "bg-gradient-to-br from-gray-400 to-gray-200 text-gray-700";
    return activeRole === role ? activeStyle : inactiveStyle;
  };

 return (
  <section id="features" className="py-8 text-gray-800">
    <div className="container mx-auto px-4">
      <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12 bg-slate-200 rounded-lg p-4">
        <ComponentTitle title="Les" highlight=" Fonctionnalités" />
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Découvrez les fonctionnalités de MonAppliClub selon votre rôle.
        </p>
      </div>
      <div className="flex justify-center space-x-4 mb-12">
        {Object.keys(sections).map((role, idx) => (
          <button
            key={idx}
            onClick={() => { setActiveRole(role); setSelectedFeature(null); }}
            className={`px-6 py-2 rounded-full shadow-lg text-sm font-semibold transition-all duration-300 sm:text-lg ${
              activeRole === role ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-700"
            }`}
          >
            Pour les {role}s
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-6 cursor-pointer">
          {sections[activeRole].map((link, linkIdx) => (
            <div key={linkIdx} className="text-center relative group col-span-2 lg:col-span-1" onClick={() => setSelectedFeature(link)}>
              <div
                className="block relative overflow-hidden rounded-lg p-4 shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 bg-white hover:bg-gray-100"
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="text-3xl text-primary my-3">{linkIcon[link]}</div>
                  <span className="block text-sm font-semibold text-gray-800 dark:text-white">
                    {link}
                  </span>
                </div>
                <div className="absolute inset-0 bg-[#F27131] bg-opacity-100 text-white flex items-center justify-center text-center p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <p className="text-xs sm:text-sm text-left">{linkDescriptions[link]}</p>
                </div>
              </div>
              {/* Section de détails en mode mobile */}
              {selectedFeature === link && (
                <div className="mt-4 col-span-2 lg:hidden p-4 bg-white rounded-lg shadow-lg">
                  <h3 className="text-2xl font-bold mb-4">{link}</h3>
                  <div
                    className="text-gray-700 dark:text-gray-300 text-left"
                    dangerouslySetInnerHTML={{ __html: linkDetails[link] }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Section de détails en mode bureau */}
        <div className="mt-6 lg:mt-0 p-4 bg-white rounded-lg shadow-lg hidden lg:block">
          {selectedFeature ? (
            <>
              <h3 className="text-2xl font-bold mb-4">{selectedFeature}</h3>
              <div
                className="text-gray-700 dark:text-gray-300"
                dangerouslySetInnerHTML={{ __html: linkDetails[selectedFeature] }}
              />
            </>
          ) : (
            <p className="text-gray-500">Sélectionnez une fonctionnalité pour voir plus de détails.</p>
          )}
        </div>
      </div>
    </div>
  </section>
);

};


export default Features;
