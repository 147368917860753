import React from "react";
import ComponentTitle from "./ComponentTitle";

const About = () => {
  return (
    <section
      id="about"
      className="py-16 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200"
    >
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Présen" highlight="tation" />
        </div>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 mb-8 md:mb-0">
            <img
              src="/images/LV.webp"
              alt="Victorin Laurent"
              className="rounded-full mx-auto h-48 w-48 object-cover"
            />
          </div>
          <div className="md:w-2/3 md:pl-12 text-center md:text-left">
            <h3 className="text-2xl font-semibold mb-4">Laurent VICTORIN</h3>
            <hr className="border-gray-400 dark:border-gray-700" />
            <p className="text-lg mt-4 font-semibold italic">Développeur Web & Web Mobile,</p>
            <p className="text-lg font-semibold italic">Président & Enseignant de club de Judo,</p>
            <p className="text-lg font-semibold italic">
              Membre du Comité Départemental de Judo de la Gironde en charge de
              la communication.
            </p>

            <p className="mt-4">
              Passionné par le développement web et le judo, j'ai combiné ces deux passions pour créer une solution de gestion qui répond aux besoins spécifiques de chaque club. En tant que responsable de club depuis de nombreuses années, j'ai pu tester de nombreuses applications qui n'étaient pas optimisées pour nos disciplines sportives, j'ai donc conçu <b>MonAppliClub</b> pour y répondre efficacement.
            </p>
            <div className="mt-8">
              <a
                href="https://www.laurent-victorin.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="px-8 py-3 bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 text-white rounded-full transition"
              >
                Mon Portfolio
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
