import React from "react";

const ConditionsGeneralesDeVente = () => {
  return (
    <div>
      <div>
        <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[80px] w-full"></div>
        <div className="flex flex-col w-full max-w-[1280px] mx-auto p-6 px-10 bg-gray-200 dark:bg-backgroundMainDark dark:text-white ">
          <h1 className="text-xl tracking-widest text-gray-800 text-center dark:text-white">
            Textes Réglementaires
          </h1>
          <h2 className="text-4xl font-bold uppercase text-center my-6 mb-10">
            Conditions Générales de Vente
          </h2>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Objet</h2>
            <p className="text-lg">
              Les présentes Conditions Générales de Vente (CGV) régissent
              l’ensemble des prestations fournies par
              <strong> MonAppliClub </strong> à ses clients. Elles concernent
              les abonnements aux services, les packs d’installation, les
              prestations d’assistance ainsi que la saisie des données. En
              souscrivant à nos services, vous acceptez pleinement et sans
              réserve les présentes conditions.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Abonnements</h2>
            <p className="text-lg">
              <strong>MonAppliClub</strong> propose plusieurs abonnements
              mensuels et annuels, notamment pour les versions "Light" et
              "Standard" de l’application, avec des tarifs adaptés en fonction
              du nombre d'adhérents au club.
            </p>
            <ul className="list-disc list-inside text-lg">
              <li>Les abonnements "Light" débutent à 15€/mois ou 171€/an.</li>
              <li>
                Les abonnements "Standard" varient en fonction du nombre
                d’adhérents : 30€/mois à partir de moins de 100 adhérents,
                35€/mois pour 100-200 adhérents, et 40€/mois pour plus de 200
                adhérents.
              </li>
              <li>Un mois d'essai gratuit est proposé pour tout abonnement.</li>
            </ul>
            <p className="text-lg mt-4">
              Tous les abonnements sont sans engagement et peuvent être résiliés
              à tout moment, avec un préavis de 30 jours pour une prise en
              compte avant la fin du mois en cours.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              3. Packs d'installation
            </h2>
            <p className="text-lg">
              <strong>MonAppliClub</strong> propose des packs d’installation
              comprenant différents niveaux de service.
            </p>
            <ul className="list-disc list-inside text-lg">
              <li>
                <strong>Pack Installation #01</strong>: 60€/an, incluant la
                réservation du nom de domaine, l’hébergement côté client,
                l’hébergement côté serveur et la base de données. Renouvelable
                tous les ans.
              </li>
              <li>
                <strong>Pack Installation #02</strong>: 40€/an, incluant
                l’hébergement côté serveur et de la base de données, sans la
                réservation de domaine et l’hébergement côté client.
                Renouvelable tous les ans.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              4. Prestations d'assistance
            </h2>
            <p className="text-lg">
              Nous proposons deux formules d’assistance pour l’aide à
              l’installation et au paramétrage de l’application :
            </p>
            <ul className="list-disc list-inside text-lg">
              <li>Assistance ½ journée : 120€</li>
              <li>Assistance journée : 200€</li>
            </ul>
            <p className="text-lg mt-4">
              Ces prestations incluent des déplacements dans le département, des
              visioconférences ou des appels téléphoniques pour un
              accompagnement optimal.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              5. Saisie de données
            </h2>
            <p className="text-lg">
              <strong>MonAppliClub</strong> propose également un service de
              saisie des données à partir de fichiers Excel fournis par le
              client. Ce service est facturé à 100€, incluant la saisie des
              informations relatives aux adhérents du club.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              6. Conditions de paiement
            </h2>
            <p className="text-lg">
              Les paiements pour les abonnements, packs d’installation,
              assistance et services de saisie de données sont réalisés via
              prélèvement automatique ou virement bancaire. Les abonnements sont
              renouvelés automatiquement à chaque période (mensuelle ou
              annuelle), sauf résiliation.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              7. Droit de rétractation
            </h2>
            <p className="text-lg">
              Conformément à la législation en vigueur, les clients disposent
              d’un délai de rétractation de 14 jours à compter de la
              souscription de leur abonnement ou de leur achat de service, à
              condition que l'exécution des prestations n'ait pas encore
              commencé.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">8. Responsabilité</h2>
            <p className="text-lg">
              <strong>MonAppliClub</strong> ne pourra être tenu responsable des
              interruptions de service ou des problèmes techniques liés aux
              hébergements ou aux serveurs de base de données, dans la limite
              des accords contractuels avec les prestataires tiers.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              9. Protection des données personnelles
            </h2>
            <p className="text-lg">
              Les données personnelles des clients sont traitées conformément à
              notre Politique de Confidentialité. Elles ne sont utilisées qu’à
              des fins de gestion des abonnements et des prestations, et ne sont
              jamais transmises à des tiers sans consentement préalable.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              10. Modification des CGV
            </h2>
            <p className="text-lg">
              <strong>MonAppliClub</strong> se réserve le droit de modifier les
              présentes CGV à tout moment. Les modifications seront notifiées
              aux clients abonnés avant leur entrée en vigueur. Les abonnés
              pourront résilier leur abonnement s’ils n’acceptent pas les
              nouvelles conditions.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">11. Contact</h2>
            <p className="text-lg">
              Pour toute question relative à ces CGV ou à nos services, vous
              pouvez nous contacter à l'adresse suivante :{" "}
              <strong>victorinlaurent33@gmail.com</strong>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ConditionsGeneralesDeVente;
