import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/parrainage`;
const token = localStorage.getItem("token");

/// GET
// Route pour récupérer tous les parrainages
export const getAllParrainages = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allparrainages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des parrainages:", error);
    return [];
  }
};

// Route pour récupérer un parrainage par son id
export const getParrainageById = async (parrainageId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/parrainageById/${parrainageId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du parrainage:", error);
    return null;
  }
};

/// POST
// Route pour créer un parrainage
export const createParrainage = async (parrainage) => {
  try {
    const response = await axios.post(`${apiUrl}/addParrainage`, parrainage, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du parrainage:", error);
    return null;
  }
};

/// PATCH
// Route pour mettre à jour un parrainage
export const updateParrainage = async (parrainageId, parrainage) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updateParrainage/${parrainageId}`,
      parrainage,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du parrainage:", error);
    return null;
  }
};

/// DELETE
// Route pour supprimer un parrainage par son id
export const deleteParrainage = async (parrainageId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteParrainage/${parrainageId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du parrainage:", error);
    return null;
  }
};
