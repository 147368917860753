import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Button from '../../../../components/Button/Button';
import { updateTask } from '../../../../services/tasksServices';

const DetailTaskModal = ({ isOpen, onClose, task, onUpdate }) => {
  const [editingStatus, setEditingStatus] = useState(false);
  const [status, setStatus] = useState(task.statut);

  if (!isOpen || !task) return null;

  const getStatusDisplay = (statut) => {
    switch (statut.toLowerCase()) {
      case "a faire":
        return { name: "À Faire", className: "bg-orange-500 text-white" };
      case "en cours":
        return { name: "En Cours", className: "bg-blue-500 text-white" };
      case "terminee":
        return { name: "Terminée", className: "bg-green-500 text-white" };
      case "urgent":
        return { name: "Urgent", className: "bg-red-600 text-white" };
      default:
        return { name: "Statut Inconnu", className: "bg-gray-500 text-white" };
    }
  };

  const handleStatusChange = async (newStatus) => {
    try {
      await updateTask(task.id, { statut: newStatus });
      setStatus(newStatus);
      setEditingStatus(false);
      onUpdate({ ...task, statut: newStatus });
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error);
    }
  };

  const handleClose = () => {
    onClose();
    onUpdate({ ...task, statut: status });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 dark:bg-gray-900 p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-full overflow-y-auto">
        {/* En-tête avec le logo et le nom de l'association */}
        <div className="flex items-center mb-6">
          <img
            src={task.association.logo_asso}
            alt={`Logo de ${task.association.nom_asso}`}
            className="w-10 h-10 rounded-full mr-4"
          />
          <h3 className="text-3xl font-light tracking-wide text-white">
            {task.association.nom_asso}
          </h3>
        </div>

        {/* Détails de la tâche en deux colonnes */}
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3 space-y-4">
            <h4 className=" text-gray-300">Date :</h4>
            <h4 className=" text-gray-300">Catégorie :</h4>
            <h4 className=" text-gray-300">Titre :</h4>
            <h4 className=" text-gray-300 mb-2">Contenu :</h4>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="h-full w-px bg-white"></div> {/* Ligne verticale */}
          </div>
          <div className="col-span-6 space-y-4">
            <p className="text-gray-100">
              {new Date(task.date_creation).toLocaleDateString('fr-FR')}
            </p>
            <p className="text-gray-100">{task.categorie}</p>
            <p className="text-gray-100">{task.titre}</p>
            <div className="text-gray-100 max-w-prose">
              <div dangerouslySetInnerHTML={{ __html: task.contenu }} />
            </div>
          </div>
        </div>

        {/* Boutons en bas */}
        <div className="flex justify-center items-center gap-4 mt-6">
          {!editingStatus ? (
            <button
              className={`px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out border flex items-center justify-center ${getStatusDisplay(status).className}`}
              onClick={() => setEditingStatus(true)}
            >
              {getStatusDisplay(status).name}
            </button>
          ) : (
            <select
              className="bg-gray-700 text-white border border-gray-600 rounded-3xl px-4 py-2"
              value={status}
              onChange={(e) => handleStatusChange(e.target.value)}
            >
              <option value="a faire">À Faire</option>
              <option value="en cours">En Cours</option>
              <option value="terminee">Terminée</option>
              <option value="urgent">Urgent</option>
            </select>
          )}

          <Button
            text="Fermer"
            icon={FaTimes}
            onClick={handleClose}
            className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center"
          />
        </div>
      </div>
    </div>
  );
};

export default DetailTaskModal;
