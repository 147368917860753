import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/depensespro`;
const token = localStorage.getItem("token");


/// GET
// Fonction pour récupérer toutes les dépenses pro
export const getAllDepensesPro = async () => {
  try {
    const response = await axios.get(`${apiUrl}/alldepensespro`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des dépenses pro:", error);
    return [];
  }
};

// Fonction pour récupérer une dépense pro par son id
export const getDepenseProById = async (depenseProId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/depenseproById/${depenseProId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la dépense pro:", error);
    return null;
  }
};

// Fonction pour afficher la répartition des dépenses pro par catégorie
export const getDepensesProByCategorie = async () => {
  try {
    const response = await axios.get(`${apiUrl}/depensesproByCategorie`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération de la répartition des dépenses pro par catégorie:",
      error
    );
    return [];
  }
};

/// POST
// Fonction pour ajouter une dépense pro
export const addDepensePro = async (depensePro) => {
  try {
    const response = await axios.post(`${apiUrl}/addDepensePro`, depensePro, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de la dépense pro:", error);
    return null;
  }
};

/// PATCH
// Fonction pour mettre à jour une dépense pro
export const updateDepensePro = async (id, depenseProData) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updateDepensePro/${id}`, // L'ID est bien utilisé ici
      depenseProData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la dépense pro:", error);
    return null;
  }
};

/// DELETE
// Fonction pour supprimer une dépense pro
export const deleteDepensePro = async (depenseProId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteDepensePro/${depenseProId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de la dépense pro:", error);
    return null;
  }
};

