import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../../../components/Button/Button';
import { getDepenseProById, updateDepensePro } from '../../../../services/depensesproServices';
import { getAllAssociations } from '../../../../services/associationsServices';
import { toast } from 'react-toastify';

const EditDepenseProModal = ({ isOpen, onClose, depenseId, refreshList }) => {
  const [formData, setFormData] = useState({
    nom_depense: '',
    destinataire_depense: '',
    date_depense: '',
    montant_depense: '',
    categorie_depense: '',
    facture_depense: '',
    asso_id: '',
  });

  const [associations, setAssociations] = useState([]);

  useEffect(() => {
    const fetchDepenseData = async () => {
      try {
        const depenseData = await getDepenseProById(depenseId);
        setFormData(depenseData);
      } catch (error) {
        toast.error("Erreur lors du chargement des données de la dépense");
      }
    };

    const fetchAssociations = async () => {
      try {
        const associationsData = await getAllAssociations();
        setAssociations(associationsData || []);
      } catch (error) {
        toast.error("Erreur lors de la récupération des associations");
      }
    };

    if (isOpen && depenseId) {
      fetchDepenseData();
      fetchAssociations();
    }
  }, [isOpen, depenseId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDepensePro(depenseId, formData);
      toast.success('Dépense mise à jour avec succès');
      refreshList();
      onClose();
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de la dépense");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier la Dépense
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-bold mb-2">Nom Dépense:</label>
            <input
              className="input input-bordered w-full"
              type="text"
              name="nom_depense"
              value={formData.nom_depense}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Destinataire:</label>
            <input
              className="input input-bordered w-full"
              type="text"
              name="destinataire_depense"
              value={formData.destinataire_depense}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Date Dépense:</label>
            <input
              className="input input-bordered w-full"
              type="date"
              name="date_depense"
              value={formData.date_depense}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Montant:</label>
            <input
              className="input input-bordered w-full"
              type="number"
              name="montant_depense"
              value={formData.montant_depense}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Catégorie:</label>
            <input
              className="input input-bordered w-full"
              type="text"
              name="categorie_depense"
              value={formData.categorie_depense}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Facture (URL):</label>
            <input
              className="input input-bordered w-full"
              type="text"
              name="facture_depense"
              value={formData.facture_depense}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Association:</label>
            <select
              className="select select-bordered w-full dark:text-black"
              name="asso_id"
              value={formData.asso_id}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionnez une association</option>
              {associations.map((association) => (
                <option key={association.id} value={association.id}>
                  {association.nom_asso}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Mettre à jour" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Fermer" icon={FaTimes} onClick={onClose} className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDepenseProModal;
