import React, { useState, useEffect } from "react";
import { FaPlus, FaExternalLinkAlt } from "react-icons/fa";  // Importer l'icône souhaitée
import Button from "../../../../components/Button/Button";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import { getAllProducts, deleteProductById } from "../../../../services/productsServices";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import AddProductModal from "./AddProductModal";
import EditProductModal from "./EditProductModal";

const ListingProducts = () => {
  useScrollToTop();
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editProductId, setEditProductId] = useState(null);

  const fetchData = async () => {
    try {
      const productsData = await getAllProducts();
      setProducts(productsData || []);
      setDisplayedProducts(productsData || []);
    } catch (error) {
      console.error("Erreur lors du chargement des produits:", error);
      toast.error("Erreur lors du chargement des produits");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterProducts(searchTermLocal);
  };

  const filterProducts = (term) => {
    const filteredProducts = products.filter((product) =>
      product.nom_product.toLowerCase().includes(term.toLowerCase())
    );
    setDisplayedProducts(filteredProducts);
  };

  const deleteProduct = async (productId) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer ce produit ? ID: ${productId}`)) {
      try {
        const message = await deleteProductById(productId);
        toast.success(message);
        setProducts(products.filter((product) => product.id !== productId));
        setDisplayedProducts(products.filter((product) => product.id !== productId));
      } catch (error) {
        console.error("Erreur lors de la suppression du produit:", error);
        toast.error("Erreur lors de la suppression du produit");
      }
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openEditModal = (productId) => {
    setEditProductId(productId);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-light tracking-wide text-gray-800 dark:text-white mb-4">
        Liste des Produits
      </h1>

      <div className="w-full mx-auto mb-4">
        <Button
          text="Ajouter Produit"
          onClick={openAddModal}
          icon={FaPlus}
          className="w-full gap-2 mb-4"
        />

        <input
          className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full mb-2"
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <div className="text-left font-base mb-2">
          Nombre de produits affichés : {displayedProducts.length}
        </div>

        <div className="overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
          <table className="w-full text-left text-sm text-gray-400">
            <thead className="border-b border-gray-700">
              <tr>
                <th className="pb-3 px-4 text-gray-400">Nom du Produit</th>
                <th className="pb-3 px-4 text-gray-400">Contenu</th>
                <th className="pb-3 px-4 text-gray-400">Tarif</th>
                <th className="pb-3 px-4 text-gray-400">Périodicité</th>
                <th className="pb-3 px-4 text-gray-400">Catégorie</th>
                <th className="pb-3 px-4 text-gray-400">Lien Stripe</th>
                <th className="pb-3 px-4 text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedProducts.map((product) => (
                <tr key={product.id} className="border-b border-gray-700 text-white">
                  <td className="py-2 px-4 text-white">
                    {product.nom_product}
                  </td>
                  <td className="py-2 px-4 " style={{ color: 'white' }}>
                    <div
                      style={{ color: 'white'}}
                      dangerouslySetInnerHTML={{ __html: product.contenu_product }}
                    />
                  </td>
                  <td className="py-2 px-4 text-white whitespace-nowrap">
                    {product.tarif_product} €
                  </td>
                  <td className="py-2 px-4 text-white">
                    {product.periodicite_product}
                  </td>
                  <td className="py-2 px-4 text-white">
                    {product.categorie_product}
                  </td>
                  <td className="py-2 px-4 text-white">
                    {product.payment_stripe_link ? (
                      <a href={product.payment_stripe_link} target="_blank" rel="noopener noreferrer">
                        <FaExternalLinkAlt className="text-lg text-blue-500 hover:text-blue-700" style={{ fontSize: '16px' }} />
                      </a>
                    ) : (
                      <span>Pas de lien</span>
                    )}
                  </td>
                  <td className="py-2 px-4 flex gap-2">
                    <ButtonEdit
                      onClick={() => openEditModal(product.id)}
                      hoverLabel="Modifier"
                    />
                    <ButtonDelete
                      onClick={() => deleteProduct(product.id)}
                      hoverLabel="Supprimer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <AddProductModal
          isOpen={showAddModal}
          onClose={closeAddModal}
          refreshList={fetchData}
        />
        {editProductId && (
          <EditProductModal
            isOpen={showEditModal}
            onClose={closeEditModal}
            productId={editProductId}
            refreshList={fetchData}
          />
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default ListingProducts;
