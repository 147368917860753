import React, { useState, useEffect } from "react";
import {
  getNotificationsByUser,
  deleteNotificationById,
  markNotificationAsRead,
  markAllNotificationsAsRead,
} from "../../../../services/notificationServices";
import { useNotification } from "./../../../../contexts/NotificationContext";
import { RiCheckDoubleFill } from "react-icons/ri";
import Button from "../../../../components/Button/Button";
import ButtonEyeNoRead from "../../../../components/Button/ButtonEyeNoRead";
import ButtonEyeRead from "../../../../components/Button/ButtonEyeRead";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import { FaBell } from "react-icons/fa";
import { toast } from "react-toastify";
import { useUser } from "../../../../contexts/UserContext";
import NotificationCard from "./NotificationCard";
import "./styles.css";

const NotificationsDisplay = ({ userId }) => {
  const { user } = useUser();
  const [notifications, setNotifications] = useState([]);
  const { unreadCount, setUnreadCount } = useNotification();

  useEffect(() => {
    if (!user || !user.userId) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      return;
    }

    const loadNotifications = async () => {
      try {
        const data = await getNotificationsByUser(user.userId);
        setNotifications(data);
      } catch (error) {
        console.error("Erreur lors du chargement des notifications:", error);
        toast.error("Erreur lors du chargement des notifications.");
      }
    };

    loadNotifications();
  }, [user]);

  const loadNotifications = async () => {
    if (!userId) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      return;
    }

    try {
      const data = await getNotificationsByUser(userId);
      setNotifications(data);
    } catch (error) {
      console.error("Erreur lors du chargement des notifications:", error);
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
      await deleteNotificationById(notificationId);
      loadNotifications(); // Recharger les notifications après la suppression
      toast.success("Notification supprimée avec succès.");
    } catch (error) {
      console.error("Erreur lors de la suppression de la notification:", error);
      toast.error("Erreur lors de la suppression de la notification.");
    }
  };

  const toggleReadStatus = async (notificationId) => {
    try {
      const notification = notifications.find((n) => n.id === notificationId);
      if (!notification) {
        console.error("Notification non trouvée");
        return;
      }

      const currentReadStatus = notification.read_notification;

      await markNotificationAsRead(notificationId);

      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notificationId
            ? { ...n, read_notification: !n.read_notification }
            : n
        )
      );

      setUnreadCount((prevCount) =>
        currentReadStatus ? prevCount + 1 : prevCount - 1
      );
    } catch (error) {
      console.error("Erreur lors du basculement de l'état de lecture:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllNotificationsAsRead(userId);
      setNotifications(
        notifications.map((n) => ({ ...n, read_notification: true }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error(
        "Erreur lors du marquage de toutes les notifications comme lues:",
        error
      );
      toast.error(
        "Erreur lors du marquage de toutes les notifications comme lues."
      );
    }
  };

  return (
    <div className="mt-5 container mx-auto p-4 bg-gray-600 text-gray-200 border border-black">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <FaBell className="mr-2" />
          Notifications
          {unreadCount > 0 && (
            <span className="ml-2 bg-primary text-white text-xs rounded-full px-2 py-0.5">
              {unreadCount}
            </span>
          )}
        </h3>
        <Button
          hoverLabel="Tout Marquer Comme Lu"
          onClick={() => handleMarkAllAsRead(userId)}
          icon={RiCheckDoubleFill}
          className="bg-gray-700 text-gray-200"
        />
      </div>
      {notifications.length > 0 ? (
        <>
          {/* Tableau pour mode desktop */}
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-gray-700 divide-y divide-gray-600">
              <thead>
                <tr className="bg-gray-800">
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Notification
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-600">
                {notifications.map((notification) => (
                  <tr
                    key={notification.id}
                    className={`${
                      notification.read_notification
                        ? "bg-gray-600"
                        : "bg-gray-500 text-gray-800"
                    } hover:bg-gray-500`}
                  >
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-200">
                      {new Date(notification.created_at).toLocaleDateString(
                        "fr-FR",
                        {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                        }
                      )}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-200 max-w-xs overflow-hidden text-ellipsis">
                      {notification.message}
                    </td>
                    <td className="px-4 py-2 text-right text-sm font-medium gap-2 flex">
                      {notification.read_notification ? (
                        <ButtonEyeRead
                          onClick={() => toggleReadStatus(notification.id)}
                          hoverLabel="Marquer comme non lu"
                          className="bg-gray-700 text-gray-200"
                        />
                      ) : (
                        <ButtonEyeNoRead
                          onClick={() => toggleReadStatus(notification.id)}
                          hoverLabel="Marquer comme lu"
                          className="bg-gray-700 text-gray-200"
                        />
                      )}
                      <ButtonDelete
                        onClick={() =>
                          handleDeleteNotification(notification.id)
                        }
                        hoverLabel="Supprimer"
                        className="bg-gray-700 text-gray-200"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cartes pour mode mobile */}
          <div className="block sm:hidden">
            {notifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                notification={notification}
                toggleReadStatus={toggleReadStatus}
                handleDeleteNotification={handleDeleteNotification}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="text-center py-4 bg-gray-600 text-gray-200">
          Pas encore de notifications.
        </div>
      )}
    </div>
  );
};

export default NotificationsDisplay;
