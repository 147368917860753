import React, { useState, useEffect } from "react";
import useScrollToTop from "../../hooks/useScrollToTop";
import { getSettings } from "../../services/settingsServices";

const PolitiqueConfidentialitePage = () => {
  // Scroll to top screen
  useScrollToTop();

  const [settings, setSettings] = useState({
    siteweb_club: "",
    nom_club: "",
    adresse_club: "",
    responsable_club: "",
    mail_club: "",
    siret_club: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
      }
    };

    fetchSettings();
  }, []);

  return (
    <div>
      <div>
        <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[80px] w-full"></div>
        <div className="flex flex-col w-full max-w-[1280px] mx-auto p-6 px-10 bg-gray-200 dark:bg-backgroundMainDark dark:text-white ">
          <h1 className="text-xl tracking-widest text-gray-800 text-center dark:text-white">
            Textes Réglementaires
          </h1>
          <h2 className="text-4xl font-bold uppercase text-center my-6 mb-10">
            Politique de Confidentialité
          </h2>

          {/* Content Section */}
          <div className="flex flex-col space-y-4">
            <h3 className="text-2xl font-bold">1. Introduction</h3>
            <p>
              Chez <b>{settings.nom_club}</b>, la confidentialité de nos
              visiteurs est d'une importance capitale. Cette politique de
              confidentialité documente les types de renseignements personnels
              qui sont collectés et enregistrés par notre site web et comment
              nous les utilisons.
            </p>

            <h3 className="text-2xl font-bold">2. Log Files</h3>
            <p>
              Comme beaucoup d'autres sites Web, <b>{settings.siteweb_club}</b>{" "}
              utilise des fichiers logs. Ces fichiers enregistrent simplement
              les visiteurs du site - généralement une procédure standard pour
              les hébergeurs et une partie de l'analyse des services
              d'hébergement. Les informations dans les fichiers logs incluent
              les adresses IP (internet protocol), le type de navigateur, le
              fournisseur d'accès Internet (ISP), la date et l'heure, les pages
              de référence/sortie, et éventuellement le nombre de clics. Ces
              informations sont utilisées pour analyser les tendances,
              administrer le site, suivre le mouvement des utilisateurs sur le
              site, et recueillir des informations démographiques. Les adresses
              IP, et d'autres telles informations ne sont pas liées à des
              informations qui sont personnellement identifiables.
            </p>

            <h3 className="text-2xl font-bold">3. Cookies et Web Beacons</h3>
            <p>
              <b>{settings.siteweb_club}</b> utilise des cookies pour stocker
              des informations sur les préférences des visiteurs, pour
              enregistrer des informations spécifiques sur les pages que le
              visiteur accède ou visite, et pour personnaliser ou adapter notre
              contenu de page Web en fonction du type de navigateur des
              visiteurs ou d'autres informations que le visiteur envoie via leur
              navigateur.
            </p>

            <h3 className="text-2xl font-bold">
              4. Protection des données personnelles
            </h3>
            <p>
              Conformément à la réglementation applicable, vous disposez de
              droits d'accès, de rectification, d'effacement, d'opposition et de
              limitation du traitement relatifs à vos données. Vous pouvez
              exercer ces droits en nous contactant à l'adresse{" "}
              <b>{settings.mail_club}</b>. Pour toute information complémentaire
              ou réclamation, vous pouvez contacter la Commission Nationale de
              l'Informatique et des Libertés (plus d'informations sur
              www.cnil.fr).
            </p>

            <h3 className="text-2xl font-bold">5. Consentement</h3>
            <p>
              En utilisant notre site web, vous consentez à notre politique de
              confidentialité et acceptez ses termes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolitiqueConfidentialitePage;
