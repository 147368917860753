import React from "react";
import ComponentTitle from "../Home/ComponentTitle";
import useScrollToTop from "../../hooks/useScrollToTop";

const AboutMonAppliClubLight = () => {
  useScrollToTop();

  const handleDemoClick = () => {
    window.open("https://www.monappliclub-demo-light.com/", "_blank");
  };

  return (
    <section id="about" className="py-16 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        {/* Titre principal */}
        <div className="mx-auto max-w-screen-md text-center mb-12">
          <ComponentTitle title="Quelques" highlight=" Fonctionnalités" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Bloc Photo */}
          <div className="bg-slate-100 dark:bg-neutral-800 p-6 rounded-lg">
            <img
              src="/images/appli_tel02.webp"
              alt="illustration de l'application MonAppliClub Light"
              className="rounded-lg mx-auto w-full object-cover"
            />
          </div>

          {/* Bloc Adhérents */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-blue-200 dark:bg-blue-500 p-2 rounded-md shadow-md">🥋 Visiteurs & Adhérents</h3>
            <ul className="list-none">
              <li className="mb-4">✔️ Affichage des informations concernant le club</li>
              <li className="mb-4">✔️ Les disciplines enseignées</li>
              <li className="mb-4">✔️ Les dernières actualités</li>
              <li className="mb-4">✔️ Les Albums Photo</li>
              <li className="mb-4">✔️ Formulaire de contact</li>
              <li className="mb-4">✔️ Les membres du Bureau, Historique et coordonnées du club</li>
              <li className="mb-4">✔️ Groupes, Horaires & Tarifs</li>
              <li className="mb-4">✔️ Inscriptions en ligne</li>
            </ul>
          </div>

          {/* Bloc Enseignants */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-red-200 dark:bg-red-500 p-2 rounded-md shadow-md">🥋 Espace Administrateur</h3>
            <ul className="list-none">
              <li className="mb-4">✔️ Tableau de bord</li>
              <li className="mb-4">✔️ Réception des messages et notifications</li>
              <li className="mb-4">✔️ Gestion des inscriptions</li>
              <li className="mb-4">✔️ Gestion des articles, albums, partenaires, disciplines</li>
              <li className="mb-4">✔️ Gestion de l'équipe dirigeante et enseignante</li>
              <li className="mb-4">✔️ Personnalisation des données de chaque page du site</li>
              <li className="mb-4">✔️ Gestion des nouveaux utilisateurs de l'application</li>
            </ul>
          </div>

          {/* Bloc Photo */}
          <div className="bg-slate-100 dark:bg-neutral-800 p-6 rounded-lg">
            <img
              src="/images/tablette_angle2.webp"
              alt="illustration de l'application MonAppliClub Light"
              className="rounded-lg mx-auto w-full object-cover"
            />
          </div>
        </div>

        {/* Bouton de démo */}
        <div className="mt-8 text-center mx-auto">
          <button
            onClick={handleDemoClick}
            className="px-8 py-3 bg-gradient-to-r from-yellow-400 to-orange-600 text-white rounded-full transition relative group w-96"
          >
            <span className="group-hover:opacity-0 transition-opacity duration-300">
              Tester l'Application
            </span>
            <span className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 font-passionOne text-3xl font-light">
              MonAppliClub "Light" Démo
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutMonAppliClubLight;
