import React, { useState } from "react";
import ComponentTitle from "../Home/ComponentTitle";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const images = [
  { src: "/images/diapo/slider_001.webp", alt: "Calendrier", caption: "Calendrier" },
  { src: "/images/diapo/slider_002.webp", alt: "Inscriptions aux compétitions & résultats", caption: "Inscriptions aux compétitions & résultats" },
  { src: "/images/diapo/slider_003.webp", alt: "Boutique", caption: "Boutique" },
  { src: "/images/diapo/slider_004.webp", alt: "Réglements", caption: "Réglements" },
  { src: "/images/diapo/slider_005.webp", alt: "Albums Photos", caption: "Albums Photos" },
  { src: "/images/diapo/slider_006.webp", alt: "Galeries Photo", caption: "Galeries Photo" },
  { src: "/images/diapo/slider_007.webp", alt: "Techniques", caption: "Techniques" },
  { src: "/images/diapo/slider_008.webp", alt: "Listing Adhérents", caption: "Listing Adhérents (Enseignants)" },
  { src: "/images/diapo/slider_011.webp", alt: "Suivi Compétitions", caption: "Suivi Compétitions" },
  { src: "/images/diapo/slider_009.webp", alt: "Appel Présence", caption: "Appel Présence" },
  { src: "/images/diapo/slider_010.webp", alt: "Gestion ceintures", caption: "Gestion ceintures" },
  { src: "/images/diapo/slider_012.webp", alt: "Pamarès", caption: "Pamarès" },
  { src: "/images/diapo/slider_013.webp", alt: "Statistiques Adhérents", caption: "Statistiques Adhérents" },
  { src: "/images/diapo/slider_015.webp", alt: "Statistiques Comptabilité", caption: "Statistiques Comptabilité" },
  { src: "/images/diapo/slider_014.webp", alt: "Statistiques pour dossier de subvention", caption: "Statistiques pour dossier de subvention" },
  { src: "/images/diapo/slider_016.webp", alt: "Listing Groupes", caption: "Listing Groupes" },
  { src: "/images/diapo/slider_017.webp", alt: "Membres de l'équipe", caption: "Membres de l'équipe" },
  { src: "/images/diapo/slider_018.webp", alt: "Disciplines enseignées", caption: "Disciplines enseignées" },
];

const Diaporama = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const { src, alt, caption } = images[currentImageIndex];

  return (
    <div>
      <div className="py-16 bg-base-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Quelques" highlight=" Pages" />
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Voici une sélection de quelques pages de l'application.
          </p>
        </div>

        {/* Carrousel */}
        <div className="relative flex items-center justify-center">
          <button
            className="absolute left-4 bg-orange-400 dark:bg-orange-600 text-white p-2 rounded-full focus:outline-none"
            onClick={handlePrev}
          >
            <FaArrowLeft className="text-xl" />
          </button>
          <div className="w-full max-w-4xl mx-auto">
            <img
              src={src}
              alt={alt}
              className="w-full h-auto rounded-lg object-cover"
            />
            <p className="text-center text-gray-600 dark:text-gray-400 mt-4">{caption}</p>
          </div>
          <button
            className="absolute right-4 bg-orange-400 dark:bg-orange-600 text-white p-2 rounded-full focus:outline-none"
            onClick={handleNext}
          >
            <FaArrowRight className="text-xl" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Diaporama;
