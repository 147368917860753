import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { saveVisite } from "./services/visitesServices";

// Pages importées
import Profile from "./pages/Profile/Profile";
import HomePageVitrine from "./pages/Home/Home";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";

import ResetPwd from "./pages/ResetPwd/ResetPwd";
import ForgotPasswordPage from "./pages/ResetPwd/ForgotPasswordPage";
import Desinscription from "./pages/Desinscription/Desinscription";

import MonAppliClub from "./pages/MonAppliClub/MonAppliClub";
import MonAppliClubLight from "./pages/MonAppliClubLight/MonAppliClubLight";
import MonAppliClubOmnisports from "./pages/MonAppliClubOmnisports/MonAppliClubOmnisports";
import Tarifs from "./pages/Tarifs/Tarifs";
import DesinscriptionsPage from "./pages/Appli/AppliAdmin/DesinscriptionsPage/DesinscriptionsPage.jsx";
import PartenairesKataPage from "./pages/PartenairesKataPage/PartenairesKataPage.jsx";
import MonAppliCatePage from "./pages/MonAppliCatePage/MonAppliCatePage.jsx";

// RGPD pages
import ReglementInterieur from "./pages/Documents/ReglementInterieur";
import ConditionsUtilisationPage from "./pages/rgpd/ConditionsUtilisationPage.jsx";
import PolitiqueConfidentialitePage from "./pages/rgpd/PolitiqueConfidentialitePage.jsx";
import PolitiqueConfidentialitePageScoreboard from "./pages/rgpd/PolitiqueConfidentialitePageScoreboard.jsx";
import MentionsLegalesPage from "./pages/rgpd/MentionsLegalesPage.jsx";
import ReglesConfidentialitesTableauDeScoreJudo from "./pages/rgpd/ReglesConfidentialitesTableauDeScoreJudo.jsx";
import ConditionsGeneralesDeVente from "./pages/rgpd/ConditionsGeneralesDeVente.jsx";

import ErrorPage from "./pages/ErrorPage/ErrorPage";

// Appli importée
import AsideMenu from "./pages/Appli/AsideMenu";
import MainContent from "./pages/Appli/MainContent";

// Context Providers
import { useUser, UserProvider } from "./contexts/UserContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import { MessageProvider } from "./contexts/MessageContext";
import { ContactFormProvider } from "./contexts/ContactFormContext";
import { TaskProvider } from "./contexts/TaskContext.js";
import { AsideMenuProvider } from "./contexts/AsideMenuContext";
import PrimaryColorProvider from "./hooks/PrimaryColorProvider.jsx";

import Navbar2 from "./pages/Home/Navbar2/Navbar2";
import "./App.css";

const stripePromise = loadStripe(
  "pk_test_51POgHVEr14Icaey6JeVMxJf9bAJS9AxDAGUTSDI80b01LVDorrxErNHC1HbTU3WRgJfPD3P40daULyEzeakVDBfk00fHPS7sVp"
);

const AppContent = () => {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const RequireAuth = ({ children, roles }) => {
    if (!user) {
      return <div>Chargement...</div>;
    }

    if (roles && !roles.includes(user.role_id)) {
      return <Navigate to="/unauthorized" replace />;
    }

    return children;
  };

  useEffect(() => {
    if (location.pathname === "/appli" && user) {
      if (user.role_id === 1) {
        navigate("/appli/dashboard-admin");
      } else if ([2, 3].includes(user.role_id)) {
        navigate("/appli/notifications");
      }
    }
  }, [location.pathname, user, navigate]);

  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10); // Date actuelle
    const lastVisitDate = localStorage.getItem(
      `lastVisitDate_${location.pathname}`
    );

    if (lastVisitDate !== today) {
      // Si aucune visite enregistrée pour aujourd'hui sur cette page
      saveVisite({ page: location.pathname });
      localStorage.setItem(`lastVisitDate_${location.pathname}`, today);
    }
  }, [location.pathname]); // Se déclenche à chaque changement d'URL

  return (
    <div className="flex flex-col min-h-screen">
      {!location.pathname.startsWith("/appli") && <Navbar2 user={user} />}
      <Routes>
        <Route path="/" element={<HomePageVitrine />} />
        <Route
          path="/profile"
          element={
            <RequireAuth roles={[2, 3, 1]}>
              <Profile />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/reset-password/:token" element={<ResetPwd />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/desinscription" element={<Desinscription />} />

        {/* Appli */}
        <Route
          path="/appli/*"
          element={
            <RequireAuth roles={[1, 2, 3]}>
              <div className="flex">
                <AsideMenu role_id={user?.role_id} userInfo={user} />
                <MainContent role_id={user?.role_id} />
              </div>
            </RequireAuth>
          }
        />

        {/* Nouvelle routes */}
        <Route path="/product-monappliclub" element={<MonAppliClub />} />
        <Route
          path="/product-monappliclub-light"
          element={<MonAppliClubLight />}
        />
        <Route
          path="/product-monappliclub-omnisports"
          element={<MonAppliClubOmnisports />}
        />
        <Route path="/tarifs" element={<Tarifs />} />
        <Route path="/partenaires-kata" element={<PartenairesKataPage />} />
        <Route path="/monapplicate" element={<MonAppliCatePage />} />

        <Route path="/404" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/reglement-interieur" element={<ReglementInterieur />} />
        <Route
          path="/conditions-utilisation"
          element={<ConditionsUtilisationPage />}
        />
        <Route
          path="/politique-confidentialite"
          element={<PolitiqueConfidentialitePage />}
        />
        <Route
          path="/politique-confidentialite-tableau-de-score-judo"
          element={<PolitiqueConfidentialitePageScoreboard />}
        />
        <Route path="/mentions-legales" element={<MentionsLegalesPage />} />
        <Route
          path="/regles-confidentialites-tableau-de-score-judo"
          element={<ReglesConfidentialitesTableauDeScoreJudo />}
        />
        <Route
          path="/conditions-generales-de-vente"
          element={<ConditionsGeneralesDeVente />}
        />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Elements stripe={stripePromise}>
          <MessageProvider>
            <NotificationProvider>
              <ContactFormProvider>
                <TaskProvider>
                  <AsideMenuProvider>
                    <PrimaryColorProvider>
                      <div className="flex flex-col min-h-screen bg-base-200 dark:bg-gray-600 dark:text-gray-200">
                        <AppContent />
                      </div>
                    </PrimaryColorProvider>
                  </AsideMenuProvider>
                </TaskProvider>
              </ContactFormProvider>
            </NotificationProvider>
          </MessageProvider>
        </Elements>
      </Router>
    </UserProvider>
  );
};

export default App;
