import React from "react";
import useScrollToTop from "../../hooks/useScrollToTop";

const ReglesConfidentialitesTableauDeScoreJudo = () => {
  // Scroll to top screen
  useScrollToTop();

  const siteweb_club = "www.monappliclub.com";
  const nom_club = "MonAppliclub";
  const mail_club = "victorinlaurent33@gmail.com";

  return (
    <div>
      <div>
        <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[80px] w-full"></div>
        <div className="flex flex-col w-full max-w-[1280px] mx-auto p-6 px-10 bg-gray-200 dark:bg-backgroundMainDark dark:text-white ">
          <h1 className="text-xl tracking-widest text-gray-800 text-center dark:text-white">
            Textes Réglementaires
          </h1>
          <h2 className="text-4xl font-bold uppercase text-center my-6 mb-10">
            Règles de Confidentialité
          </h2>

          {/* Content Section */}
          <div className="flex flex-col space-y-4">
            <h3 className="text-2xl font-bold">1. Introduction</h3>
            <p>
              Bienvenue sur l'application <b>Tableau de Score de Judo</b>. Chez{" "}
              <b>{nom_club}</b>, nous respectons votre vie privée. Cette politique de
              confidentialité explique quelles données sont collectées (le cas
              échéant), comment nous les utilisons, et comment nous protégeons
              vos informations.
            </p>

            <h3 className="text-2xl font-bold">2. Collecte de données personnelles</h3>
            <p>
              L'application <b>Tableau de Score de Judo</b> ne collecte, ne
              stocke, ni ne partage aucune donnée personnelle ou sensible
              concernant ses utilisateurs. L'application ne requiert pas
              d'inscription, d'authentification ou d'accès à des informations
              personnelles pour fonctionner.
            </p>

            <h3 className="text-2xl font-bold">3. Données techniques collectées</h3>
            <p>
              Aucune donnée technique liée à l'utilisation de l'application n'est
              collectée ou stockée. Nous ne conservons aucune information
              concernant votre appareil, votre localisation ou votre utilisation
              de l'application.
            </p>

            <h3 className="text-2xl font-bold">4. Publicité et suivi</h3>
            <p>
              Cette application ne contient aucune publicité et ne réalise aucun
              suivi de l'utilisateur. Il n'y a aucun outil de suivi (comme des
              cookies ou des balises Web) utilisé dans l'application.
            </p>

            <h3 className="text-2xl font-bold">5. Protection des données personnelles</h3>
            <p>
              Même si l'application ne collecte pas de données, nous nous
              engageons à protéger les données personnelles si nous en
              recueillions dans le futur. Vous disposez de droits d'accès, de
              rectification, d'effacement, et d'opposition relatifs à vos
              données. Vous pouvez exercer ces droits en nous contactant à
              l'adresse suivante : <b>{mail_club}</b>.
            </p>

            <h3 className="text-2xl font-bold">6. Consentement</h3>
            <p>
              En utilisant l'application <b>Tableau de Score de Judo</b>, vous
              consentez à cette politique de confidentialité et acceptez ses
              termes.
            </p>

            <h3 className="text-2xl font-bold">7. Contact</h3>
            <p>
              Si vous avez des questions concernant cette politique de
              confidentialité, vous pouvez nous contacter à l'adresse suivante :
              <b> {mail_club}</b>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReglesConfidentialitesTableauDeScoreJudo;
