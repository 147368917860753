import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import Button from "../../../../components/Button/Button";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import ButtonDetails from "../../../../components/Button/ButtonDetails";
import {
  getAllTasks,
  deleteTask as deleteTaskAPI,
} from "../../../../services/tasksServices";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import AddTaskModal from "./AddTaskModal";
import EditTaskModal from "./EditTaskModal";
import DetailTaskModal from "./DetailTaskModal";
import { useTask } from '../../../../contexts/TaskContext'; 

const ListingTaches = () => {
  useScrollToTop();
  const [taches, setTaches] = useState([]);
  const [displayedTaches, setDisplayedTaches] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatut, setSelectedStatut] = useState("");
  const [selectedAssociation, setSelectedAssociation] = useState("");
  const [filteredAssociations, setFilteredAssociations] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [editTacheId, setEditTacheId] = useState(null);
  const { loadTasksNotDoneCount } = useTask();

  const fetchData = async () => {
    try {
      const tachesData = await getAllTasks();
      setTaches(tachesData || []);
      setDisplayedTaches(tachesData || []);
      filterAssociations(tachesData);
    } catch (error) {
      console.error("Erreur lors du chargement des tâches:", error);
      toast.error("Erreur lors du chargement des tâches");
    }
  };

  const updateTaskInList = (updatedTask) => {
    const updatedTaches = taches.map((tache) =>
      tache.id === updatedTask.id ? updatedTask : tache
    );
    setTaches(updatedTaches);
    setDisplayedTaches(updatedTaches);
  };

  const filterAssociations = (tachesData) => {
    const associationsWithTasks = tachesData.reduce((acc, tache) => {
      if (!acc.some((association) => association.id === tache.association.id)) {
        acc.push(tache.association);
      }
      return acc;
    }, []);
    setFilteredAssociations(associationsWithTasks);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterTaches(searchTermLocal, selectedStatut, selectedAssociation);
  };

  const handleStatutChange = (e) => {
    const statut = e.target.value;
    setSelectedStatut(statut);
    filterTaches(searchTerm, statut, selectedAssociation);
  };

  const handleAssociationChange = (e) => {
    const associationId = e.target.value;
    setSelectedAssociation(associationId);
    filterTaches(searchTerm, selectedStatut, associationId);
  };

  const filterTaches = (term, statut, associationId) => {
    const filteredTaches = taches.filter((tache) => {
      const matchesSearchTerm =
        tache.titre.toLowerCase().includes(term.toLowerCase()) ||
        tache.categorie.toLowerCase().includes(term.toLowerCase()) ||
        tache.association.nom_asso.toLowerCase().includes(term.toLowerCase());

      const matchesStatut = statut
        ? tache.statut.toLowerCase() === statut.toLowerCase()
        : true;

      const matchesAssociation = associationId
        ? tache.asso_id === parseInt(associationId)
        : true;

      return matchesSearchTerm && matchesStatut && matchesAssociation;
    });
    setDisplayedTaches(filteredTaches);
  };

  const deleteTache = async (tacheId) => {
  if (
    window.confirm(
      `Êtes-vous sûr de vouloir supprimer cette tâche ? ID: ${tacheId}`
    )
  ) {
    try {
      await deleteTaskAPI(tacheId); // Suppression de la tâche via l'API
      toast.success("Tâche supprimée"); // Affichage du message de succès
      setTaches(taches.filter((tache) => tache.id !== tacheId)); // Mise à jour des tâches
      setDisplayedTaches(taches.filter((tache) => tache.id !== tacheId)); // Mise à jour des tâches affichées
      loadTasksNotDoneCount(); // Mise à jour du nombre de tâches restantes
    } catch (error) {
      console.error("Erreur lors de la suppression de la tâche:", error); // Gestion des erreurs
      toast.error("Erreur lors de la suppression de la tâche"); // Affichage du message d'erreur
    }
  }
};

  const getStatusDisplay = (statut) => {
    switch (statut.toLowerCase()) {
      case "a faire":
        return { name: "À Faire", className: "bg-orange-400 text-white" };
      case "en cours":
        return { name: "En Cours", className: "bg-blue-400 text-white" };
      case "terminee":
        return { name: "Terminée", className: "bg-green-400 text-white" };
      case "urgent":
        return { name: "Urgent", className: "bg-red-600 text-white" };
      default:
        return { name: "Statut Inconnu", className: "bg-gray-400 text-white" };
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openEditModal = (tacheId) => {
    setEditTacheId(tacheId);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openDetailModal = (tache) => {
    setSelectedTask(tache);
    setShowDetailModal(true);
  };

  const closeDetailModal = () => {
    setShowDetailModal(false);
    setSelectedTask(null);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-light tracking-wide text-gray-800 dark:text-white mb-4">
        Liste des Tâches
      </h1>

      <div className="w-full mx-auto mb-4">
        <Button
          text="Ajouter Tâche"
          onClick={openAddModal}
          icon={FaPlus}
          className="w-full gap-2 mb-4"
        />

        <div className="flex flex-col md:flex-row justify-between mb-2 gap-2">
          <div className="w-full md:w-1/3">
            <select
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              value={selectedStatut}
              onChange={handleStatutChange}
            >
              <option value="">Tous les statuts</option>
              <option value="A faire">À Faire</option>
              <option value="En cours">En Cours</option>
              <option value="Terminee">Terminée</option>
              <option value="Urgent">Urgent</option>
            </select>
          </div>
          <div className="w-full md:w-1/3">
            <select
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              value={selectedAssociation}
              onChange={handleAssociationChange}
            >
              <option value="">Toutes les associations</option>
              {filteredAssociations
                .sort((a, b) => a.nom_asso.localeCompare(b.nom_asso))
                .map((association) => (
                  <option key={association.id} value={association.id}>
                    {association.nom_asso}
                  </option>
                ))}
            </select>
          </div>

          <div className="w-full md:w-1/3">
            <input
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="text-left font-base mb-2">
          Nombre de tâches affichées : {displayedTaches.length}
        </div>

        <div className="hidden md:block overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
          <table className="w-full text-left text-sm text-gray-400">
            <thead className=" border-b border-gray-700">
              <tr>
                <th className="pb-3 px-4 text-gray-400">Date</th>
                <th className="pb-3 px-4 text-gray-400">Association</th>
                <th className="pb-3 px-4 text-gray-400">Catégorie</th>
                <th className="pb-3 px-4 text-gray-400">Titre</th>
                <th className="pb-3 px-4 text-gray-400">Statut</th>
                <th className="pb-3 px-4 text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedTaches.map((tache) => (
                <tr key={tache.id} className="border-b border-gray-700">
                  <td className="py-2 px-4 text-white">
                    {new Date(tache.date_creation).toLocaleDateString("fr-FR")}
                  </td>
                  <td className="py-2 px-4 text-white">
                    {tache.association.nom_asso}
                  </td>
                  <td className="py-2 px-4 text-white">{tache.categorie}</td>
                  <td className="py-2 px-4 text-white">{tache.titre}</td>
                  <td className="py-2 whitespace-nowrap">
                    {(() => {
                      const { name, className } = getStatusDisplay(
                        tache.statut
                      );
                      return (
                        <span
                          className={`px-2 py-1 rounded-full text-xs ${className}`}
                        >
                          {name}
                        </span>
                      );
                    })()}
                  </td>
                  <td className="py-2 px-4 flex gap-2">
                    <ButtonDetails
                      onClick={() => openDetailModal(tache)}
                      hoverLabel="Voir Détails"
                    />
                    <ButtonEdit
                      onClick={() => openEditModal(tache.id)}
                      hoverLabel="Éditer"
                    />
                    <ButtonDelete
                      onClick={() => deleteTache(tache.id)}
                      hoverLabel="Supprimer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="block md:hidden">
          {displayedTaches.map((tache) => (
            <div
              key={tache.id}
              className={`flex bg-gray-800 dark:bg-gray-900 rounded-lg shadow-md mb-4`}
            >
              <div className={`w-3/10 p-4 flex flex-col items-center justify-center gap-2  bg-gray-500`}>

                <ButtonDetails
                  onClick={() => openDetailModal(tache)}
                  hoverLabel="Voir Détails"
                  className="text-white bg-gray-800"
                />
                <ButtonEdit
                  onClick={() => openEditModal(tache.id)}
                  hoverLabel="Éditer"
                  className="text-white"
                />
                <ButtonDelete
                  onClick={() => deleteTache(tache.id)}
                  hoverLabel="Supprimer"
                  className="text-white"
                />
              </div>

              <div className="w-7/10 p-4">
                <div className="text-white text-lg mb-2">{tache.titre}</div>
                <div className="text-gray-400 text-sm">
                  {new Date(tache.date_creation).toLocaleDateString("fr-FR")}
                </div>
                <div className="text-gray-400 text-sm">
                  Association: {tache.association.nom_asso}
                </div>
                <div className="text-gray-400 text-sm">
                  Catégorie: {tache.categorie}
                </div>
                <div className="text-gray-400 text-sm mt-2">
                  Statut:{" "}
                  {(() => {
                    const { name, className } = getStatusDisplay(tache.statut);
                    return (
                      <span
                        className={`px-2 py-1 rounded-full text-xs ml-2 ${className}`}
                      >
                        {name}
                      </span>
                    );
                  })()}
                  </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <AddTaskModal
        isOpen={showAddModal}
        onClose={closeAddModal}
        refreshList={fetchData}
      />
      {editTacheId && (
        <EditTaskModal
          isOpen={showEditModal}
          onClose={closeEditModal}
          taskId={editTacheId}
          refreshList={fetchData}
        />
      )}

      {selectedTask && (
        <DetailTaskModal
          isOpen={showDetailModal}
          onClose={closeDetailModal}
          task={selectedTask}
          onUpdate={updateTaskInList}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default ListingTaches;
