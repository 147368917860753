import React, { useState, useEffect } from 'react';
import { getAllAssociations } from '../../../../services/associationsServices';
import { assignAssociationToUser, removeAssociationFromUser } from '../../../../services/userServices';
import Button from '../../../../components/Button/Button';
import { toast } from 'react-toastify';

const ManageAssociationsModal = ({ isOpen, onClose, userId, refreshList }) => {
  const [associations, setAssociations] = useState([]);
  const [filter, setFilter] = useState("");
  const [filteredAssociations, setFilteredAssociations] = useState([]);

  useEffect(() => {
    const fetchAssociations = async () => {
      try {
        const fetchedAssociations = await getAllAssociations();
        setAssociations(fetchedAssociations);
        setFilteredAssociations(fetchedAssociations);
      } catch (error) {
        toast.error("Erreur lors du chargement des associations");
      }
    };

    fetchAssociations();
  }, []);

  useEffect(() => {
    setFilteredAssociations(associations.filter(association =>
      association.nom_asso.toLowerCase().includes(filter.toLowerCase())
    ));
  }, [filter, associations]);

  const handleAddAssociation = async (associationId) => {
    try {
      await assignAssociationToUser(userId, associationId);
      refreshList();
      toast.success("Association assignée avec succès");
    } catch (error) {
      toast.error("Erreur lors de l'assignation de l'association");
    }
  };

  const handleRemoveAssociation = async (associationId) => {
    try {
      await removeAssociationFromUser(userId, associationId);
      refreshList();
      toast.success("Association retirée avec succès");
    } catch (error) {
      toast.error("Erreur lors de la suppression de l'association");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full max-h-full overflow-y-auto">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-gray-200 bg-gray-700 rounded-md shadow">
          Gérer les Associations
        </h3>
        <input
          type="text"
          placeholder="Rechercher par nom"
          value={filter}
          onChange={e => setFilter(e.target.value)}
          className="w-full px-3 py-2 mb-4 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <ul className="space-y-2 max-h-80 overflow-y-auto">
          {filteredAssociations.map(association => (
            <li key={association.id} className="flex justify-between items-center p-2 bg-gray-700 rounded-md shadow">
              <span className="text-gray-200 text-sm">{association.nom_asso}</span>
              <div className="flex space-x-2">
                <Button
                  text="Ajouter"
                  onClick={() => handleAddAssociation(association.id)}
                  className="bg-green-600 hover:bg-green-500 text-gray-200 text-xs px-2 py-1 rounded-md"
                />
                <Button
                  text="Retirer"
                  onClick={() => handleRemoveAssociation(association.id)}
                  className="bg-red-600 hover:bg-red-500 text-gray-200 text-xs px-2 py-1 rounded-md"
                />
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-end mt-4">
          <Button
            text="Fermer"
            onClick={onClose}
            className="bg-gray-600 text-gray-200 hover:bg-gray-500 px-4 py-2 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default ManageAssociationsModal;
