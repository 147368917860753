import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { countVisitesByPageToday } from "../../../../services/visitesServices";

const PagesVisitesBarChart = () => {
  const [data, setData] = useState([]);

  // Fonction pour récupérer les données des visites par page
  const fetchPageVisits = async () => {
    try {
      const visitsData = await countVisitesByPageToday(); // Appel à l'API
      console.log("Données récupérées :", visitsData);

      // Transformez les données pour convertir `total_visits` en nombre
      const formattedData = visitsData.map((item) => ({
        page: item.page,
        count: parseInt(item.total_visits, 10), // Conversion en entier
      }));

      setData(formattedData); // Stocker les données formatées
    } catch (error) {
      console.error("Erreur lors de la récupération des visites par page :", error);
    }
  };

  // Appeler la fonction fetchPageVisits une seule fois au chargement du composant
  useEffect(() => {
    fetchPageVisits();
  }, []);

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-4 dark:bg-gray-900 dark:text-gray-200">
      <h3 className="text-lg font-bold text-white mb-4">
        Visites des pages de MonAppliClub
      </h3>

      <div style={{ position: "relative", zIndex: 0 }}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              tick={{ fill: "white", fontSize: 14 }}
              label={{ value: "Visites", position: "insideBottom", fill: "white" }}
            />
            <YAxis
              type="category"
              dataKey="page" // Utilise le champ `page` comme étiquette
              tick={{ fill: "white", fontSize: 14 }} // Couleur des noms de pages en blanc
              width={200} // Ajuste la largeur pour laisser de l'espace aux noms de pages longs
            />
            <Tooltip
              formatter={(value) => [`${value} visites`, "Visites"]} // Formater les données du tooltip
              contentStyle={{ backgroundColor: "#333", color: "#fff" }}
            />
            <Bar dataKey="count" fill="#82ca9d" /> {/* Remplissage des barres */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PagesVisitesBarChart;
