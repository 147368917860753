import React from "react";
import ComponentTitle from "../Home/ComponentTitle";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // CSS pour le carrousel

export default function Pricing2() {
  const cards = [
    {
      title: "Pack Installation #01",
      price: "60€",
      period: "/an",
      details: [
        "Réservation du Nom de Domaine",
        "Hébergement côté client",
        "Hébergement côté server",
        "Hébergement de la base de données",
        "Renouvellable tous les ans",
      ],
    },
    {
      title: "Pack Installation #02",
      price: "40€",
      period: "/an",
      details: [
        "Réservation du Nom de Domaine et hébergement côté client à votre charge",
        "Hébergement côté server",
        "Hébergement de la base de données",
        "Renouvellable tous les ans",
      ],
    },
    {
      title: "Assistance ½ journée",
      price: "120€",
      details: [
        "Assistance pour aide à l'installation et paramétrage de l'application",
        "Déplacement dans le département, visio ou appel téléphonique",
      ],
    },
    {
      title: "Assistance journée",
      price: "200€",
      details: [
        "Assistance pour aide à l'installation et paramétrage de l'application",
        "Déplacement dans le département, visio ou appel téléphonique",
      ],
    },
    {
      title: "Saisie des données",
      price: "100€",
      details: [
        "A partir d'un fichier excel, saisie des données relatives aux adhérents",
      ],
    },
  ];

  return (
    <section className="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Nos" highlight=" Tarifs" />
          <p className="mb-5 font-light text-gray-500 dark:text-gray-400 sm:text-xl">
            Découvrez nos offres adaptées à vos besoins.
          </p>
        </div>

        {/* Carrousel pour afficher une carte à la fois */}
        <Carousel
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          emulateTouch
          centerMode={false}
          showArrows={true}
          interval={5000} // Intervalle de défilement de 5 secondes
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="absolute left-0 z-10 w-10 h-10 text-white bg-blue-600 hover:bg-blue-700 rounded-full flex items-center justify-center"
                style={{ top: "50%", transform: "translateY(-50%)" }}
              >
                &#8249; {/* Flèche gauche */}
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="absolute right-0 z-10 w-10 h-10 text-white bg-blue-600 hover:bg-blue-700 rounded-full flex items-center justify-center"
                style={{ top: "50%", transform: "translateY(-50%)" }}
              >
                &#8250; {/* Flèche droite */}
              </button>
            )
          }
        >
          {cards.map((card, cardIndex) => (
            <div key={cardIndex} className="flex justify-center">
              <div className="flex flex-col p-10 text-center bg-white dark:bg-gray-800 rounded-lg shadow-lg w-96 dark:border-gray-600 border border-blue-950">
                {/* Titre du pack */}
                <h3 className="text-2xl font-thin text-gray-900 dark:text-gray-200 uppercase mb-6 mt-2">
                  {card.title}
                </h3>

                {/* Cercle pour le prix */}
                <div className="flex justify-center items-center mb-8">
                  <div className="bg-blue-500 text-white rounded-full w-36 h-36 flex items-center justify-center">
                    <span className="text-4xl font-extrabold">
                      {card.price}
                    </span>
                    {card.period && <span className="text-lg">{card.period}</span>}
                  </div>
                </div>

                {/* Liste des avantages */}
                <ul className="mb-8 text-left text-gray-600 dark:text-gray-300 space-y-4">
                  {card.details.map((detail, idx) => (
                    <li key={idx} className="flex items-start">
                      <span className="bg-blue-500 text-white rounded-full w-6 h-6 flex-shrink-0 flex items-center justify-center mr-3 mt-1">
                        ✔
                      </span>
                      <span>{detail}</span>
                    </li>
                  ))}
                </ul>

                {/* Bouton d'appel à l'action */}
                <div className="mt-auto">
                  <a
                    href="/tarifs#contact"
                    className="block w-full py-3 text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
                  >
                    Informations
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}
