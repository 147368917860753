import React, { useState } from "react";

// Catégories de poids pour chaque groupe
const weightCategories = {
  Benjamin: ["-26","-30", "-34", "-38", "-42", "-46", "-50", "-55", "-60", "-66", "+66 kg"],
  Minime: ["-34", "-38", "-42", "-46", "-50", "-55", "-60", "-66", "-73", "+73 kg"],
  Cadet: ["-46", "-50", "-55", "-60", "-66", "-73", "-81", "-90", "+90 kg"],
  Junior: ["-55", "-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  Sénior: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  BenjaminF: ["-28", "-32", "-36", "-40", "-44", "-48", "-52", "-57", "-63", "+63 kg"],
  MinimeF: ["-36", "-40", "-44", "-48", "-52", "-57", "-63", "-70", "+70 kg"],
  CadetF: ["-40", "-44", "-48", "-52", "-57", "-63", "-70", "+70 kg"],
  JuniorF: ["-44", "-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  SéniorF: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  Eveil: ["Groupes Morphologiques"],
  MiniPoussin: ["Groupes Morphologiques"],
  Poussin: ["Groupes Morphologiques"],
  EveilF: ["Groupes Morphologiques"],
  MiniPoussinF: ["Groupes Morphologiques"],
  PoussinF: ["Groupes Morphologiques"],
  M1: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M2: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M3: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M4: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M5: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M6: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M7: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M8: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M9: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M10: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  M11: ["-60", "-66", "-73", "-81", "-90", "-100", "+100 kg"],
  F1: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F2: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F3: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F4: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F5: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F6: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F7: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F8: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F9: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F10: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
  F11: ["-48", "-52", "-57", "-63", "-70", "-78", "+78 kg"],
};

const TableauCategories = () => {
  const [selectedGenre, setSelectedGenre] = useState("Tous");
  const [selectedAnnee, setSelectedAnnee] = useState("Tous");
  // Données de base pour la saison active et les genres
  const saisonActive = "2024-2025";
  const categories = [
    { label: "Eveil", annees: "2019 - 2020", ageCategory: "Eveil", genre: "Masculin" },
    { label: "Eveil", annees: "2019 - 2020", ageCategory: "EveilF", genre: "Féminin" },
    { label: "Mini-Poussin", annees: "2017 - 2018", ageCategory: "MiniPoussin", genre: "Masculin" },
    { label: "Mini-Poussine", annees: "2017 - 2018", ageCategory: "MiniPoussinF", genre: "Féminin" },
    { label: "Poussin", annees: "2015 - 2016", ageCategory: "Poussin", genre: "Masculin" },
    { label: "Poussine", annees: "2015 - 2016", ageCategory: "PoussinF", genre: "Féminin" },
    { label: "Benjamin", annees: "2013 - 2014", ageCategory: "Benjamin", genre: "Masculin" },
    { label: "Benjamine", annees: "2013 - 2014", ageCategory: "BenjaminF", genre: "Féminin" },
    { label: "Minime", annees: "2011 - 2012", ageCategory: "Minime", genre: "Masculin" },
    { label: "Minime", annees: "2011 - 2012", ageCategory: "MinimeF", genre: "Féminin" },
    { label: "Cadet", annees: "2008 - 2010", ageCategory: "Cadet", genre: "Masculin" },
    { label: "Cadette", annees: "2008 - 2010", ageCategory: "CadetF", genre: "Féminin" },
    { label: "Junior", annees: "2005 - 2007", ageCategory: "Junior", genre: "Masculin" },
    { label: "Junior", annees: "2005 - 2007", ageCategory: "JuniorF", genre: "Féminin" },
    { label: "Sénior", annees: "2004 et avant", ageCategory: "Sénior", genre: "Masculin" },
    { label: "Sénior", annees: "2004 et avant", ageCategory: "SéniorF", genre: "Féminin" },
    { label: "Vétéran M1", annees: "1990 - 1994", ageCategory: "M1", genre: "Masculin" },
    { label: "Vétéran F1", annees: "1990 - 1994", ageCategory: "F1", genre: "Féminin" },
    { label: "Vétéran M2", annees: "1985 - 1989", ageCategory: "M2", genre: "Masculin" },
    { label: "Vétéran F2", annees: "1985 - 1989", ageCategory: "F2", genre: "Féminin" },
    { label: "Vétéran M3", annees: "1980 - 1984", ageCategory: "M3", genre: "Masculin" },
    { label: "Vétéran F3", annees: "1980 - 1984", ageCategory: "F3", genre: "Féminin" },
    { label: "Vétéran M4", annees: "1975 - 1979", ageCategory: "M4", genre: "Masculin" },
    { label: "Vétéran F4", annees: "1975 - 1979", ageCategory: "F4", genre: "Féminin" },
    { label: "Vétéran M5", annees: "1970 - 1974", ageCategory: "M5", genre: "Masculin" },
    { label: "Vétéran F5", annees: "1970 - 1974", ageCategory: "F5", genre: "Féminin" },
    { label: "Vétéran M6", annees: "1965 - 1969", ageCategory: "M6", genre: "Masculin" },
    { label: "Vétéran F6", annees: "1965 - 1969", ageCategory: "F6", genre: "Féminin" },
    { label: "Vétéran M7", annees: "1960 - 1964", ageCategory: "M7", genre: "Masculin" },
    { label: "Vétéran F7", annees: "1960 - 1964", ageCategory: "F7", genre: "Féminin" },
    { label: "Vétéran M8", annees: "1955 - 1959", ageCategory: "M8", genre: "Masculin" },
    { label: "Vétéran F8", annees: "1955 - 1959", ageCategory: "F8", genre: "Féminin" },
    { label: "Vétéran M9", annees: "1950 - 1954", ageCategory: "M9", genre: "Masculin" },
    { label: "Vétéran F9", annees: "1950 - 1954", ageCategory: "F9", genre: "Féminin" },
    { label: "Vétéran M10", annees: "1945 - 1949", ageCategory: "M10", genre: "Masculin" },
    { label: "Vétéran F10", annees: "1945 - 1949", ageCategory: "F10", genre: "Féminin" },
    { label: "Vétéran M11", annees: "1944 et avant", ageCategory: "M11", genre: "Masculin" },
    { label: "Vétéran F11", annees: "1944 et avant", ageCategory: "F11", genre: "Féminin" },
  ];

  // Génération des lignes du tableau
  const tableauCategories = categories.map((item) => {
    const weightCategoryList = weightCategories[item.ageCategory] || ["Non défini"];
    const weightCategoryString =
      weightCategoryList.includes("Groupes Morphologiques")
        ? "Groupes Morphologiques"
        : weightCategoryList.join(", ");

    return {
      label: item.label,
      genre: item.genre,
      annees: item.annees,
      weightCategories: weightCategoryString,
    };
  });

  // Génération de la liste d'années pour le filtre
const anneesOptions = Array.from({ length: 97 }, (_, index) => (2020 - index).toString());

// Filtrage des catégories en fonction des sélections
const filteredCategories = categories
  .filter((item) => {
    const matchesGenre = selectedGenre === "Tous" || item.genre === selectedGenre;

    // Vérifier si l'année sélectionnée se trouve dans la plage des années
    const [startYear, endYear] = item.annees.split(" - ").map((year) => parseInt(year, 10));
    const matchesAnnee =
      selectedAnnee === "Tous" ||
      (selectedAnnee >= startYear && selectedAnnee <= (endYear || startYear));

    return matchesGenre && matchesAnnee;
  })
  .map((item) => {
    const weightCategoryList = weightCategories[item.ageCategory] || ["Non défini"];
    const weightCategoryString =
      weightCategoryList.includes("Groupes Morphologiques")
        ? "Groupes Morphologiques"
        : weightCategoryList.join(", ");

    return {
      ...item,
      weightCategories: weightCategoryString,
    };
  });

  return (
    <div className="container mx-auto my-8">
      <h2 className="text-2xl font-bold text-center mb-2">Tableau des Catégories d'Âge et de Poids</h2>
      <h2 className="text-xl font-bold text-center mb-6">Saison 2024-2025</h2>
      {/* Filtres */}
      <div className="flex justify-center mb-4 space-x-4">
        <select
          value={selectedGenre}
          onChange={(e) => setSelectedGenre(e.target.value)}
          className="p-2 border rounded dark-bg-gray-200 dark:text-gray-800"
        >
          <option value="Tous">Tous les genres</option>
          <option value="Masculin">Masculin</option>
          <option value="Féminin">Féminin</option>
        </select>
        <select
          value={selectedAnnee}
          onChange={(e) => setSelectedAnnee(e.target.value)}
          className="p-2 border rounded  *dark-bg-gray-200 dark:text-gray-800"
        >
          <option value="Tous">Toutes les années</option>
          {anneesOptions.map((annee) => (
            <option key={annee} value={annee}>
              {annee}
            </option>
          ))}
        </select>
      </div>

      {/* Tableau */}
      <table className="min-w-full bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-700 dark:bg-gray-700">
          <tr>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-200 dark:text-gray-300 uppercase">
              Catégorie d'Âge
            </th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-200 dark:text-gray-300 uppercase">
              Genre
            </th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-200 dark:text-gray-300 uppercase">
              Années de Naissance
            </th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-200 dark:text-gray-300 uppercase">
              Catégories de Poids
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredCategories.map((row, index) => (
            <tr
              key={index}
              className={`border-b dark:border-gray-700 ${
                row.genre === "Masculin" ? "bg-blue-100" : "bg-pink-100"
              }`}
            >
              <td className="py-3 px-6 text-sm text-gray-800 dark:text-gray-800 border-b border-gray-800">{row.label}</td>
              <td className="py-3 px-6 text-sm text-gray-800 dark:text-gray-800 border-b border-gray-800">{row.genre}</td>
              <td className="py-3 px-6 text-sm text-gray-800 dark:text-gray-800 border-b border-gray-800">{row.annees}</td>
              <td className="py-3 px-6 text-sm text-gray-800 dark:text-gray-800 border-b border-gray-800">{row.weightCategories}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableauCategories;
