import React from 'react';
import { FaEye, FaEyeSlash } from "react-icons/fa";

// ButtonEyeNoRead avec une icône d'oeil barré
const ButtonEyeNoRead = ({ onClick, className = '', hoverLabel = 'Editer'  }) => {
  return (
    <button
      onClick={onClick}
      aria-label={hoverLabel}
      title={hoverLabel} 
      className={`w-8 h-8 bg-blue-500 text-white rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-blue-700 flex items-center justify-center ${className}`}
    >
      <FaEyeSlash className='text-[12px]'/>
    </button>
  );
};


export default ButtonEyeNoRead;
