import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import { getAllParrainages, deleteParrainage } from "../../../../services/parrainageServices";
import { ToastContainer, toast } from "react-toastify";
import ComponentTitle from "./../../../Home/ComponentTitle";

const ParrainagePage = () => {
  const [parrainages, setParrainages] = useState([]);

  // Fonction pour récupérer les données des parrainages
  const fetchParrainages = async () => {
    try {
      const data = await getAllParrainages();
      setParrainages(data);
    } catch (error) {
      console.error("Erreur lors du chargement des parrainages:", error);
      toast.error("Erreur lors du chargement des données.");
    }
  };

  useEffect(() => {
    fetchParrainages();
  }, []);

  // Fonction pour supprimer un parrainage
  const handleDelete = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce parrainage ?")) {
      try {
        await deleteParrainage(id);
        toast.success("Parrainage supprimé avec succès !");
        setParrainages(parrainages.filter((parrainage) => parrainage.id !== id));
      } catch (error) {
        console.error("Erreur lors de la suppression du parrainage:", error);
        toast.error("Erreur lors de la suppression.");
      }
    }
  };

  // Fonction pour éditer un parrainage (à connecter à un modal ou une nouvelle page)
  const handleEdit = (id) => {
    toast.info(`Édition du parrainage ID: ${id} (à implémenter).`);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-8 bg-slate-200 rounded-lg p-2">
        <ComponentTitle title="Les" highlight=" Parrainages" />
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Bienvenue dans l'espace de gestion des parrainages. Vous pouvez consulter, éditer et supprimer les parrainages ici.
        </p>
      </div>

      {/* Table for desktop */}
      <div className="hidden md:block overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
        <table className="w-full text-left text-sm text-gray-400">
          <thead className="border-b border-gray-700">
            <tr>
              <th className="pb-3 px-4 text-gray-400">Nom Parrain</th>
              <th className="pb-3 px-4 text-gray-400">Club Parrain</th>
              <th className="pb-3 px-4 text-gray-400">Email Parrain</th>
              <th className="pb-3 px-4 text-gray-400">Club Parrainé</th>
              <th className="pb-3 px-4 text-gray-400">Actions</th>
            </tr>
          </thead>
          <tbody>
            {parrainages.map((parrainage) => (
              <tr key={parrainage.id} className="border-b border-gray-700">
                <td className="py-2 px-4 text-white">
                  {parrainage.nom_parrain} {parrainage.prenom_parrain}
                </td>
                <td className="py-2 px-4 text-white">{parrainage.club_parrain}</td>
                <td className="py-2 px-4 text-gray-400">{parrainage.email_parrain}</td>
                <td className="py-2 px-4 text-white">{parrainage.club_parraine}</td>
                <td className="py-2 px-4 flex gap-2">
                  <ButtonEdit onClick={() => handleEdit(parrainage.id)} hoverLabel="Éditer" />
                  <ButtonDelete
                    onClick={() => handleDelete(parrainage.id)}
                    hoverLabel="Supprimer"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Cards for mobile */}
      <div className="block md:hidden">
        {parrainages.map((parrainage) => (
          <div
            key={parrainage.id}
            className="bg-gray-800 dark:bg-gray-900 rounded-lg shadow-md p-4 mb-4"
          >
            <div className="text-white text-lg mb-2">
              {parrainage.nom_parrain} {parrainage.prenom_parrain}
            </div>
            <div className="text-gray-400 text-sm">Club Parrain: {parrainage.club_parrain}</div>
            <div className="text-gray-400 text-sm">Email: {parrainage.email_parrain}</div>
            <div className="text-gray-400 text-sm">
              Club Parrainé: {parrainage.club_parraine}
            </div>
            <div className="flex gap-2 mt-4">
              <ButtonEdit onClick={() => handleEdit(parrainage.id)} hoverLabel="Éditer" />
              <ButtonDelete
                onClick={() => handleDelete(parrainage.id)}
                hoverLabel="Supprimer"
              />
            </div>
          </div>
        ))}
      </div>

      <ToastContainer />
    </div>
  );
};

export default ParrainagePage;
