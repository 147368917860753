import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../../../components/Button/Button';
import { getProductById, updateProduct } from '../../../../services/productsServices';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditProductModal = ({ isOpen, onClose, productId, refreshList }) => {
    const [formData, setFormData] = useState({
        nom_product: '',
        contenu_product: '',
        tarif_product: '',
        periodicite_product: '',
        categorie_product: '',
        payment_stripe_link: '',
        id: ''
    });

    useEffect(() => {
        const fetchProduct = async () => {
            if (productId) {
                try {
                    const product = await getProductById(productId);
                    console.log("Produit chargé:", product);  // Debugging: vérifier le produit chargé
                    setFormData({
                        nom_product: product.nom_product || '',
                        contenu_product: product.contenu_product || '',
                        tarif_product: product.tarif_product || '',
                        periodicite_product: product.periodicite_product || '',
                        categorie_product: product.categorie_product || '',
                        payment_stripe_link: product.payment_stripe_link || '',
                        id: product.id || ''
                    });
                } catch (error) {
                    console.error('Erreur lors de la récupération du produit:', error);
                    toast.error('Erreur lors de la récupération du produit');
                }
            }
        };

        if (isOpen) {
            fetchProduct();
        }
    }, [productId, isOpen]);

    const handleChange = (e) => {
        const { name, type, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? e.target.checked : value,
        }));
    };

    const handleContentChange = (value) => {
        setFormData(prev => ({
            ...prev,
            contenu_product: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateProduct(formData.id, formData);
            refreshList();
            onClose();
            toast.success('Produit mis à jour avec succès');
        } catch (error) {
            console.error('Erreur lors de la mise à jour du produit', error);
            toast.error('Erreur lors de la mise à jour du produit');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white p-4 rounded-lg shadow-lg dark:bg-slate-400">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
                    Modifier le Produit
                </h3>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="nom_product"
                        placeholder="Nom du Produit"
                        value={formData.nom_product}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <ReactQuill
                        value={formData.contenu_product}
                        onChange={handleContentChange}
                        className="mb-3"
                    />
                    <input
                        type="number"
                        name="tarif_product"
                        placeholder="Tarif du Produit"
                        value={formData.tarif_product}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <input
                        type="text"
                        name="periodicite_product"
                        placeholder="Périodicité"
                        value={formData.periodicite_product}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <input
                        type="text"
                        name="categorie_product"
                        placeholder="Catégorie"
                        value={formData.categorie_product}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <input
                        type="text"
                        name="payment_stripe_link"
                        placeholder="Lien de paiement Stripe"
                        value={formData.payment_stripe_link}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        />
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Sauvegarder" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditProductModal;
