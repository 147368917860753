function getWeightCategory(gender, ageCategory, weight) {
  const weightCategories = {
    Benjamin: [26, 30, 34, 38, 42, 46, 50, 55, 60, 66],
    Minime: [34, 38, 42, 46, 50, 55, 60, 66, 73],
    Cadet: [46, 50, 55, 60, 66, 73, 81, 90],
    Junior: [55, 60, 66, 73, 81, 90, 100],
    Sénior: [60, 66, 73, 81, 90, 100],
    M1: [60, 66, 73, 81, 90, 100], // Masculin Vétéran Groupe 1
    M2: [60, 66, 73, 81, 90, 100],
    M3: [60, 66, 73, 81, 90, 100],
    M4: [60, 66, 73, 81, 90, 100],
    M5: [60, 66, 73, 81, 90, 100],
    M6: [60, 66, 73, 81, 90, 100],
    M7: [60, 66, 73, 81, 90, 100],
    M8: [60, 66, 73, 81, 90, 100],
    M9: [60, 66, 73, 81, 90, 100],
    M10: [60, 66, 73, 81, 90, 100],
    M11: [60, 66, 73, 81, 90, 100],
    F1: [48, 52, 57, 63, 70, 78], // Féminin Vétéran Groupe 1
    F2: [48, 52, 57, 63, 70, 78],
    F3: [48, 52, 57, 63, 70, 78],
    F4: [48, 52, 57, 63, 70, 78],
    F5: [48, 52, 57, 63, 70, 78],
    F6: [48, 52, 57, 63, 70, 78],
    F7: [48, 52, 57, 63, 70, 78],
    F8: [48, 52, 57, 63, 70, 78],
    F9: [48, 52, 57, 63, 70, 78],
    F10: [48, 52, 57, 63, 70, 78],
    F11: [48, 52, 57, 63, 70, 78],
    BenjaminF: [28, 32, 36, 40, 44, 48, 52, 57, 63],
    MinimeF: [36, 40, 44, 48, 52, 57, 63, 70],
    CadetF: [40, 44, 48, 52, 57, 63, 70],
    JuniorF: [44, 48, 52, 57, 63, 70, 78],
    SéniorF: [48, 52, 57, 63, 70, 78],
  };

  // Assurer que le poids est un nombre
  let numericWeight = parseFloat(weight);

  // Si le poids est null, undefined, vide ou 0, retourne "Non pesé(e)"
  if (
    weight === null ||
    weight === undefined ||
    weight === "" ||
    numericWeight === 0
  ) {
    return "Non pesé(e)";
  }

  // Si le poids est invalide (NaN), retourne une erreur
  if (isNaN(numericWeight)) {
    return "Poids invalide";
  }

  // Modifier la clé en fonction du genre
  let categoryKey = ageCategory;

  // Gérer les catégories pour le genre féminin
  if (gender === "Féminin") {
    if (ageCategory === "Benjamin") categoryKey = "BenjaminF";
    if (ageCategory === "Minime") categoryKey = "MinimeF";
    if (ageCategory === "Cadet") categoryKey = "CadetF";
    if (ageCategory === "Junior") categoryKey = "JuniorF";
    if (ageCategory === "Sénior") categoryKey = "SéniorF";
  }

  // Gérer les catégories d'âge inconnues
  if (ageCategory === "Inconnu") {
    return "Catégorie d'âge inconnue";
  }

  // Gérer les catégories sans poids (Eveil, Mini-Poussin et Poussin)
  if (
    ageCategory === "Eveil" ||
    ageCategory === "Mini-Poussin" ||
    ageCategory === "Poussin"
  ) {
    return "Gr. Morpho."; // GM pour "Groupe Morphologique"
  }

  // Vérifier si la clé existe
  if (!weightCategories.hasOwnProperty(categoryKey)) {
    console.error(`Catégorie inconnue: ${categoryKey}`);
    return "Catégorie inconnue";
  }

  // Récupérer la dernière catégorie (la plus lourde) pour la comparaison
  let lastCategory =
    weightCategories[categoryKey][weightCategories[categoryKey].length - 1];

  // Si le poids est supérieur à la dernière catégorie, retourner cette catégorie
  if (numericWeight >= lastCategory) {
    return `+${lastCategory} kg`;
  }

  // Trouver la catégorie de poids appropriée
  let selectedCategory = weightCategories[categoryKey].find(
    (cat) => numericWeight < cat
  );

  return selectedCategory === null
    ? "Aucune catégorie"
    : `-${selectedCategory} kg`;
}

export default getWeightCategory;
