import React, { createContext, useContext, useState } from "react";

const AsideMenuContext = createContext();

export const useAsideMenu = () => useContext(AsideMenuContext);

export const AsideMenuProvider = ({ children }) => {
  // Initialiser le panneau à l'état réduit par défaut
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prevState) => !prevState);

  const closeMenu = () => setIsOpen(false);

  return (
    <AsideMenuContext.Provider value={{ isOpen, toggleMenu, closeMenu }}>
      {children}
    </AsideMenuContext.Provider>
  );
};
