import React, { useState, useEffect } from "react";
import { FaPlus, FaCalendarAlt, FaTable } from "react-icons/fa";
import Button from "../../../../components/Button/Button";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import ButtonDetails from "../../../../components/Button/ButtonDetails";
import {
  getAllAgendaClients,
  deleteAgendaClient as deleteAgendaClientAPI,
} from "../../../../services/agendaclubServices";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import AddAgendaModal from "./AddAgendaModal";
import EditAgendaModal from "./EditAgendaModal";
import DetailAgendaModal from "./DetailAgendaModal";
import CalendarView from "./CalendarView";

const ListingAgenda = () => {
  useScrollToTop();
  const [agenda, setAgenda] = useState([]);
  const [displayedAgenda, setDisplayedAgenda] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAssociation, setSelectedAssociation] = useState("");
  const [filteredAssociations, setFilteredAssociations] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedAgenda, setSelectedAgenda] = useState(null);
  const [editAgendaId, setEditAgendaId] = useState(null);
  const [view, setView] = useState('table');

  const fetchData = async () => {
    try {
      const agendaData = await getAllAgendaClients();
      setAgenda(agendaData || []);
      setDisplayedAgenda(agendaData || []);
      filterAssociations(agendaData);
    } catch (error) {
      console.error("Erreur lors du chargement des rendez-vous:", error);
      toast.error("Erreur lors du chargement des rendez-vous");
    }
  };

  const updateAgendaInList = (updatedAgenda) => {
    const updatedAgendaList = agenda.map((item) =>
      item.id === updatedAgenda.id ? updatedAgenda : item
    );
    setAgenda(updatedAgendaList);
    setDisplayedAgenda(updatedAgendaList);
  };

  const filterAssociations = (agendaData) => {
    const associationsWithAgenda = agendaData.reduce((acc, item) => {
      if (!acc.some((association) => association.id === item.Associations.id)) {
        acc.push(item.Associations);
      }
      return acc;
    }, []);
    setFilteredAssociations(associationsWithAgenda);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterAgenda(searchTermLocal, selectedAssociation);
  };

  const handleAssociationChange = (e) => {
    const associationId = e.target.value;
    setSelectedAssociation(associationId);
    filterAgenda(searchTerm, associationId);
  };

  const filterAgenda = (term, associationId) => {
    const filteredAgenda = agenda.filter((item) => {
      const matchesSearchTerm =
        item.client_nom.toLowerCase().includes(term.toLowerCase()) ||
        item.Associations.nom_asso.toLowerCase().includes(term.toLowerCase());

      const matchesAssociation = associationId
        ? item.association_id === parseInt(associationId)
        : true;

      return matchesSearchTerm && matchesAssociation;
    });
    setDisplayedAgenda(filteredAgenda);
  };

  const deleteAgenda = async (agendaId) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer ce rendez-vous ? ID: ${agendaId}`
      )
    ) {
      try {
        const message = await deleteAgendaClientAPI(agendaId);
        toast.success(message);
        setAgenda(agenda.filter((item) => item.id !== agendaId));
        setDisplayedAgenda(agenda.filter((item) => item.id !== agendaId));
      } catch (error) {
        console.error("Erreur lors de la suppression du rendez-vous:", error);
        toast.error("Erreur lors de la suppression du rendez-vous");
      }
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openEditModal = (agendaId) => {
    setEditAgendaId(agendaId);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openDetailModal = (agenda) => {
    setSelectedAgenda(agenda);
    setShowDetailModal(true);
  };

  const closeDetailModal = () => {
    setShowDetailModal(false);
    setSelectedAgenda(null);
  };

 const handleSelectEvent = (event) => {
  console.log('Événement sélectionné :', event); // Ajoutez ceci pour vérifier l'événement
  openDetailModal(event.originalEvent);
};

  const toggleView = () => {
    setView(view === 'table' ? 'calendar' : 'table');
  };

  const events = displayedAgenda.map(event => ({
  id: event.id,
  title: `${event.client_nom} (${event.type_rdv})`,
  start: new Date(`${event.date_rdv}T${event.heure_debut}`),
  end: new Date(`${event.date_rdv}T${event.heure_fin}`),
  association: event.Associations.nom_asso,
  type: event.type_rdv,
  originalEvent: event,  // Assurez-vous que cet attribut est présent
}));



  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-light tracking-wide text-gray-800 dark:text-white mb-4">
        Agenda des Rendez-vous
      </h1>

      <div className="w-full mx-auto mb-4 flex justify-between gap-2">
        <Button
          text="Ajouter Rendez-vous"
          onClick={openAddModal}
          icon={FaPlus}
          className="w-1/2 gap-2 mb-4"
        />
        <Button
          text={view === 'table' ? 'Vue Calendrier' : 'Vue Tableau'}
          onClick={toggleView}
          icon={view === 'table' ? FaCalendarAlt : FaTable}
          className="w-1/2 gap-2 mb-4"
        />
      </div>

      {view === 'table' ? (
        <>
          <div className="flex flex-col md:flex-row justify-between mb-2 gap-2">
            <div className="w-full md:w-1/2">
              <select
                className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
                value={selectedAssociation}
                onChange={handleAssociationChange}
              >
                <option value="">Toutes les associations</option>
                {filteredAssociations
                  .sort((a, b) => a.nom_asso.localeCompare(b.nom_asso))
                  .map((association) => (
                    <option key={association.id} value={association.id}>
                      {association.nom_asso}
                    </option>
                  ))}
              </select>
            </div>

            <div className="w-full md:w-1/2">
              <input
                className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
                type="text"
                placeholder="Rechercher..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="text-left font-base mb-2">
            Nombre de rendez-vous affichés : {displayedAgenda.length}
          </div>

          <div className="hidden md:block overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
            <table className="w-full text-left text-sm text-gray-400">
              <thead className="border-b border-gray-700">
                <tr>
                  <th className="pb-3 px-4 text-gray-400">Date</th>
                  <th className="pb-3 px-4 text-gray-400">Heure</th>
                  <th className="pb-3 px-4 text-gray-400">Association</th>
                  <th className="pb-3 px-4 text-gray-400">Client</th>
                  <th className="pb-3 px-4 text-gray-400">Type</th>
                  <th className="pb-3 px-4 text-gray-400">Actions</th>
                </tr>
              </thead>
              <tbody>
                {displayedAgenda.map((item) => (
                  <tr key={item.id} className="border-b border-gray-700">
                    <td className="py-2 px-4 text-white">
                      {new Date(item.date_rdv).toLocaleDateString("fr-FR")}
                    </td>
                    <td className="py-2 px-4 text-white">
                      {item.heure_debut} - {item.heure_fin}
                    </td>
                    <td className="py-2 px-4 text-white">
                      {item.Associations.nom_asso}
                    </td>
                    <td className="py-2 px-4 text-white">{item.client_nom}</td>
                    <td className="py-2 px-4 text-white">{item.type_rdv}</td>
                    <td className="py-2 px-4 flex gap-2">
                      <ButtonDetails
                        onClick={() => openDetailModal(item)}
                        hoverLabel="Voir Détails"
                      />
                      <ButtonEdit
                        onClick={() => openEditModal(item.id)}
                        hoverLabel="Éditer"
                      />
                      <ButtonDelete
                        onClick={() => deleteAgenda(item.id)}
                        hoverLabel="Supprimer"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="block md:hidden">
            {displayedAgenda.map((item) => (
              <div
                key={item.id}
                className={`flex bg-gray-800 dark:bg-gray-900 rounded-lg shadow-md mb-4`}
              >
                <div className={`w-3/10 p-4 flex flex-col items-center justify-center gap-2  bg-gray-500`}>

                  <ButtonDetails
                    onClick={() => openDetailModal(item)}
                    hoverLabel="Voir Détails"
                    className="text-white bg-gray-800"
                  />
                  <ButtonEdit
                    onClick={() => openEditModal(item.id)}
                    hoverLabel="Éditer"
                    className="text-white"
                  />
                  <ButtonDelete
                    onClick={() => deleteAgenda(item.id)}
                    hoverLabel="Supprimer"
                    className="text-white"
                  />
                </div>

                <div className="w-7/10 p-4">
                  <div className="text-white text-lg mb-2">{item.client_nom}</div>
                  <div className="text-gray-400 text-sm">
                    {new Date(item.date_rdv).toLocaleDateString("fr-FR")}
                  </div>
                  <div className="text-gray-400 text-sm">
                    Association: {item.Associations.nom_asso}
                  </div>
                  <div className="text-gray-400 text-sm">
                    Type: {item.type_rdv}
                  </div>
                  <div className="text-gray-400 text-sm mt-2">
                    Heure: {item.heure_debut} - {item.heure_fin}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
         <CalendarView events={events} onSelectEvent={handleSelectEvent} />
      )}

      <AddAgendaModal
        isOpen={showAddModal}
        onClose={closeAddModal}
        refreshList={fetchData}
      />
      {editAgendaId && (
        <EditAgendaModal
          isOpen={showEditModal}
          onClose={closeEditModal}
          agendaId={editAgendaId}
          refreshList={fetchData}
        />
      )}

      {selectedAgenda && (
        <DetailAgendaModal
          isOpen={showDetailModal}
          onClose={closeDetailModal}
          agenda={selectedAgenda}
          onUpdate={updateAgendaInList}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default ListingAgenda;
