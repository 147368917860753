import React, { useState, useEffect } from "react";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import Button from "../../../../components/Button/Button";
import {
  getAllSubscriptions,
  deleteSubscription,
} from "../../../../services/subscriptionServices";
import { useUser } from "../../../../contexts/UserContext";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ComponentTitle from "./../../../Home/ComponentTitle";


const SubscriptionsPage = () => {
  useScrollToTop();
  const [subscriptions, setSubscriptions] = useState([]);
  const [displayedSubscriptions, setDisplayedSubscriptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAssociation, setSelectedAssociation] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [loading, setLoading] = useState(true);

  const { user } = useUser();

  const fetchData = async () => {
    try {
      const data = await getAllSubscriptions();

      const filteredData =
        user.role_id === 1
          ? data
          : data.filter((sub) => sub.user_id === user.userId);

      setSubscriptions(filteredData || []);
      setDisplayedSubscriptions(filteredData || []);
      setLoading(false);
    } catch (error) {
      toast.error("Erreur lors de la récupération des abonnements.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterSubscriptions(searchTermLocal, selectedAssociation, selectedProduct);
  };

  const handleAssociationChange = (e) => {
    const association = e.target.value;
    setSelectedAssociation(association);
    filterSubscriptions(searchTerm, association, selectedProduct);
  };

  const handleProductChange = (e) => {
    const product = e.target.value;
    setSelectedProduct(product);
    filterSubscriptions(searchTerm, selectedAssociation, product);
  };

  const filterSubscriptions = (term, association, product) => {
    const filteredSubscriptions = subscriptions.filter((subscription) => {
      const matchesSearchTerm =
        subscription.User.nom.toLowerCase().includes(term.toLowerCase()) ||
        subscription.User.prenom.toLowerCase().includes(term.toLowerCase()) ||
        subscription.Association.nom_asso
          .toLowerCase()
          .includes(term.toLowerCase()) ||
        subscription.Product.nom_product
          .toLowerCase()
          .includes(term.toLowerCase());

      const matchesAssociation = association
        ? subscription.asso_id === parseInt(association)
        : true;

      const matchesProduct = product
        ? subscription.product_id === parseInt(product)
        : true;

      return matchesSearchTerm && matchesAssociation && matchesProduct;
    });
    setDisplayedSubscriptions(filteredSubscriptions);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet abonnement?")) {
      try {
        await deleteSubscription(id);
        const updatedSubscriptions = subscriptions.filter(
          (sub) => sub.id !== id
        );
        setSubscriptions(updatedSubscriptions);
        setDisplayedSubscriptions(updatedSubscriptions);
        toast.success("Abonnement supprimé avec succès.");
      } catch (error) {
        toast.error("Erreur lors de la suppression de l'abonnement.");
      }
    }
  };

  const handlePay = async (id) => {
  const subscription = subscriptions.find(sub => sub.id === id);

  if (!subscription) {
    toast.error("Abonnement non trouvé.");
    return;
  }

  console.log("Subscription:", subscription);
  console.log("Product:", subscription.Product);
  console.log("Payment Link:", subscription.Product?.payment_stripe_link);

  if (!subscription.Product || !subscription.Product.payment_stripe_link) {
    toast.error("Lien de paiement non disponible pour ce produit.");
    return;
  }

  if (subscription.Product.payment_stripe_link === "") {
    toast.error("Lien de paiement vide pour ce produit.");
    return;
  }

  if (window.confirm("Confirmez-vous le règlement de cet abonnement ?")) {
    try {
      // Redirection vers Stripe pour le paiement
      window.location.href = subscription.Product.payment_stripe_link;
    } catch (error) {
      toast.error("Erreur lors de la redirection vers Stripe.");
    }
  }
};


  if (loading) {
    return <div>Chargement des abonnements...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12 bg-slate-200 rounded-lg p-4">
        <ComponentTitle title="Abonnements" highlight=" & Services" />
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Bienvenue dans votre espace abonnements. Vous pouvez consulter les
          informations de vos Abonnements & Services ici !
        </p>
      </div>

      <div className="w-full mx-auto mb-4">
        <div className="flex flex-col md:flex-row justify-between mb-2 gap-2">
          <div className="w-full md:w-1/3">
            <select
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              value={selectedAssociation}
              onChange={handleAssociationChange}
            >
              <option value="">Toutes les associations</option>
              {Array.from(
                new Set(
                  subscriptions.map((subscription) => ({
                    id: subscription.asso_id,
                    nom: subscription.Association.nom_asso,
                  }))
                )
              ).map((association, index) => (
                <option
                  key={`asso-${association.id}-${index}`}
                  value={association.id}
                >
                  {association.nom}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-1/3">
            <select
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              value={selectedProduct}
              onChange={handleProductChange}
            >
              <option value="">Tous les produits</option>
              {Array.from(
                new Set(
                  subscriptions.map((subscription) => ({
                    id: subscription.product_id,
                    nom: subscription.Product.nom_product,
                  }))
                )
              ).map((product, index) => (
                <option key={`prod-${product.id}-${index}`} value={product.id}>
                  {product.nom}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full md:w-1/3">
            <input
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="text-left font-base mb-2">
          Nombre d'abonnements et services affichés :{" "}
          {displayedSubscriptions.length}
        </div>

        <div className="overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
          <table className="w-full text-left text-sm text-gray-400">
            <thead className="border-b border-gray-700">
              <tr>
                <th className="pb-3 px-4 text-gray-400">Utilisateur</th>
                <th className="pb-3 px-4 text-gray-400">Association</th>
                <th className="pb-3 px-4 text-gray-400">Produit</th>
                <th className="pb-3 px-4 text-gray-400">Tarif</th>
                <th className="pb-3 px-4 text-gray-400">Périodicité</th>
                <th className="pb-3 px-4 text-gray-400">Date de début</th>
                <th className="pb-3 px-4 text-gray-400">Date de fin</th>
                <th className="pb-3 px-4 text-gray-400">Statut</th>
                <th className="pb-3 px-4 text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedSubscriptions.map((sub) => (
                <tr key={sub.id} className="border-b border-gray-700">
                  <td className="py-2 px-4 text-white">
                    {sub.User.nom} {sub.User.prenom}
                  </td>
                  <td className="py-2 px-4 text-white">
                    <div className="flex items-center">
                      <span>{sub.Association.nom_asso}</span>
                    </div>
                  </td>
                  <td className="py-2 px-4 text-white">
                    {sub.Product.nom_product}
                  </td>
                  <td className="py-2 px-4 text-white whitespace-nowrap">
                    {sub.Product.tarif_product} €
                  </td>
                  <td className="py-2 px-4 text-white whitespace-nowrap">
                    {sub.Product.periodicite_product}
                  </td>
                  <td className="py-2 px-4 text-white">
                    {new Date(sub.start_date).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 text-white">
                    {new Date(sub.end_date).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 text-white">
                    <span
                      className={`${
                        sub.status === "En cours"
                          ? "bg-green-500"
                          : "bg-red-500"
                      } text-white py-1 px-3 rounded-full text-xs whitespace-nowrap`}
                    >
                      {sub.status}
                    </span>
                  </td>
                  <td className="py-2 px-4 flex gap-2">
                    {user.role_id === 1 ? (
                      <>
                        <ButtonEdit
                          onClick={() =>
                            alert("Fonction d'édition non implémentée")
                          }
                          hoverLabel="Modifier"
                        />
                        <ButtonDelete
                          onClick={() => handleDelete(sub.id)}
                          hoverLabel="Supprimer"
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          text="Régler"
                          onClick={() => handlePay(sub.id)}
                          className="btn-primary"
                          disabled={sub.status === "En cours"}
                        />
                        <ButtonDelete
                          onClick={() => handleDelete(sub.id)}
                          hoverLabel="Supprimer"
                          disabled={sub.status === "En cours"}
                        />
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SubscriptionsPage;
