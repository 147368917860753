import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import Button from "../../../../components/Button/Button";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import {
  getAllEcheances,
  deleteEcheance as deleteEcheanceAPI,
} from "../../../../services/echeanceServices";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import AddEcheanceModal from "./AddEcheanceModal";
import EditEcheanceModal from "./EditEcheanceModal";

const ListingEcheances = () => {
  useScrollToTop();
  const [echeances, setEcheances] = useState([]);
  const [displayedEcheances, setDisplayedEcheances] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAssociation, setSelectedAssociation] = useState("");
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editEcheanceId, setEditEcheanceId] = useState(null);

  const fetchData = async () => {
    try {
      const echeancesData = await getAllEcheances();
      setEcheances(echeancesData || []);
      setDisplayedEcheances(echeancesData || []);
    } catch (error) {
      console.error("Erreur lors du chargement des échéances:", error);
      toast.error("Erreur lors du chargement des échéances");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterEcheances(searchTermLocal, selectedAssociation, selectedCategorie);
  };

  const handleAssociationChange = (e) => {
    const association = e.target.value;
    setSelectedAssociation(association);
    filterEcheances(searchTerm, association, selectedCategorie);
  };

  const handleCategorieChange = (e) => {
    const categorie = e.target.value;
    setSelectedCategorie(categorie);
    filterEcheances(searchTerm, selectedAssociation, categorie);
  };

  const filterEcheances = (term, association, categorie) => {
    const filteredEcheances = echeances.filter((echeance) => {
      const matchesSearchTerm = echeance.nom_echeance
        .toLowerCase()
        .includes(term.toLowerCase());

      const matchesAssociation = association
        ? echeance.association.id === parseInt(association)
        : true;

      const matchesCategorie = categorie
        ? echeance.categorie_echeance.toLowerCase() === categorie.toLowerCase()
        : true;

      return matchesSearchTerm && matchesAssociation && matchesCategorie;
    });
    setDisplayedEcheances(filteredEcheances);
  };

  const deleteEcheance = async (echeanceId) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer cette échéance ? ID: ${echeanceId}`
      )
    ) {
      try {
        const message = await deleteEcheanceAPI(echeanceId);
        toast.success(message);
        setEcheances(
          echeances.filter((echeance) => echeance.id !== echeanceId)
        );
        setDisplayedEcheances(
          echeances.filter((echeance) => echeance.id !== echeanceId)
        );
      } catch (error) {
        console.error("Erreur lors de la suppression de l'échéance:", error);
        toast.error("Erreur lors de la suppression de l'échéance");
      }
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openEditModal = (echeanceId) => {
    setEditEcheanceId(echeanceId);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setEditEcheanceId(null);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-light tracking-wide text-gray-800 dark:text-white mb-4">
        Liste des Échéances
      </h1>

      <div className="w-full mx-auto mb-4">
        <Button
          text="Ajouter Échéance"
          onClick={openAddModal}
          icon={FaPlus}
          className="w-full gap-2 mb-4"
        />

        <div className="flex flex-col md:flex-row justify-between mb-2 gap-2">
          <div className="w-full md:w-1/3">
            <select
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              value={selectedAssociation}
              onChange={handleAssociationChange}
            >
              <option value="">Toutes les associations</option>
              {Array.from(
                new Set(
                  echeances.map((echeance) => ({
                    id: echeance.association.id,
                    nom: echeance.association.nom_asso,
                  }))
                )
              ).map((association) => (
                <option key={association.id} value={association.id}>
                  {association.nom}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-1/3">
            <select
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              value={selectedCategorie}
              onChange={handleCategorieChange}
            >
              <option value="">Toutes les catégories</option>
              {Array.from(
                new Set(
                  echeances.map((echeance) =>
                    echeance.categorie_echeance.trim()
                  )
                )
              ).map((categorie) => (
                <option key={categorie} value={categorie}>
                  {categorie}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-1/3">
            <input
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="text-left font-base mb-2">
          Nombre d'échéances affichées : {displayedEcheances.length}
        </div>

        <div className="overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
          <table className="w-full text-left text-sm text-gray-400">
            <thead className="border-b border-gray-700">
              <tr>
                <th className="pb-3 px-4 text-gray-400">Nom de l'Échéance</th>
                <th className="pb-3 px-4 text-gray-400">Catégorie</th>
                <th className="pb-3 px-4 text-gray-400">Association</th>
                <th className="pb-3 px-4 text-gray-400">Début</th>
                <th className="pb-3 px-4 text-gray-400">Fin</th>
                <th className="pb-3 px-4 text-gray-400">Auto</th>
                <th className="pb-3 px-4 text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedEcheances.map((echeance) => (
                <tr key={echeance.id} className="border-b border-gray-700">
                  <td className="py-2 px-4 text-white">
                    {echeance.nom_echeance}
                  </td>
                  <td className="py-2 px-4 text-white">
                    {echeance.categorie_echeance}
                  </td>
                  <td className="py-2 px-4 text-white">
                    <div className="flex items-center">
                      <img
                        className="rounded-full w-8 h-8 object-cover mr-2"
                        src={
                          echeance.association.logo_asso ||
                          "/img/default_logo.png"
                        }
                        alt="Logo Association"
                      />
                      <span>{echeance.association.nom_asso}</span>
                    </div>
                  </td>
                  <td className="py-2 px-4 text-white">
                    {echeance.debut_echeance &&
                    echeance.debut_echeance !== "0000-00-00" ? (
                      new Date(echeance.debut_echeance).toLocaleDateString()
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="py-2 px-4 text-white">
                    {echeance.fin_echeance &&
                    echeance.fin_echeance !== "0000-00-00" ? (
                      new Date(echeance.fin_echeance).toLocaleDateString()
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="py-2 px-4 text-white">
                    {echeance.renouvellement_auto ? "✅" : "🔲"}
                  </td>
                  <td className="py-2 px-4 flex gap-2">
                    <ButtonEdit
                      onClick={() => openEditModal(echeance.id)}
                      hoverLabel="Modifier"
                    />
                    <ButtonDelete
                      onClick={() => deleteEcheance(echeance.id)}
                      hoverLabel="Supprimer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
        </div>
        <AddEcheanceModal
          isOpen={showAddModal}
          onClose={closeAddModal}
          refreshList={fetchData}
        />
        {editEcheanceId && (
          <EditEcheanceModal
            isOpen={showEditModal}
            onClose={closeEditModal}
            refreshList={fetchData}
            echeanceId={editEcheanceId}
          />
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default ListingEcheances;
