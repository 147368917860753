import React, { useState, useEffect } from "react";
import Button from "./../../components/Button/Button"; // Assurez-vous que le chemin d'accès est correct
import "react-toastify/dist/ReactToastify.css";
import backgroundImage from "./HeaderSlider_02.jpg";
import { transformImageUrl } from "../../utils/transformImageUrl";
import { ToastContainer, toast } from "react-toastify";
import useScrollToTop from "../../hooks/useScrollToTop";
import {
  getUserById,
  updateUserProfile,
  updateUserProfilePhoto,
} from "./../../services/userServices"; // Assurez-vous que le chemin d'accès est correct
import { FaSave, FaTimes } from "react-icons/fa";

const Profile = () => {
  useScrollToTop();
  const [user, setUser] = useState({
    id: "",
    nom: "",
    prenom: "",
    email: "",
    photoURL: "",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Prend le premier fichier sélectionné
  };

  useEffect(() => {
    // Récupérer les informations de l'utilisateur stockées localement
    const userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const userId = userInfo?.userId;

    if (userId) {
      // Charger les données de l'utilisateur depuis le serveur
      const fetchUserData = async () => {
        try {
          const userData = await getUserById(userId);
          setUser(userData);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données de l'utilisateur:",
            error
          );
          toast.error(
            "Erreur lors de la récupération des données de l'utilisateur."
          );
        }
      };

      fetchUserData();
    }
  }, []);

  const handleEdit = () => {
    setIsEditing(true); // Ouvrir la modale pour modifier les informations personnelles
  };

  const handleCloseModal = () => {
    setIsEditing(false); // Fermer la modale de modification des informations
    setIsUploading(false); // Fermer la modale de modification de la photo
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const updatedUser = await updateUserProfile(user.id, user, token);
      localStorage.setItem("userInfo", JSON.stringify(updatedUser));
      setUser(updatedUser);
      setIsEditing(false);
      toast.success("Profil mis à jour avec succès!");
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des données de l'utilisateur:",
        error
      );
      toast.error(
        "Erreur lors de la mise à jour des données de l'utilisateur."
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value }); // Mettre à jour l'état local avec les nouvelles valeurs des inputs
  };

  const handleUploadModal = () => {
    setIsUploading(true); // Ouvrir la modale pour le changement de photo
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Veuillez sélectionner une image à télécharger.");
      return;
    }

    // Tentative de mise à jour de la photo de profil avec le fichier sélectionné
    setIsUploading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await updateUserProfilePhoto(
        user.id,
        selectedFile,
        token
      );
      if (response && response.data.imageUrl) {
        setUser({ ...user, photoURL: response.data.imageUrl });
        toast.success("Photo de profil mise à jour avec succès!");
      } else {
        throw new Error("Image not updated properly.");
      }
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image :", error);
      toast.error("Erreur lors de l'upload de l'image.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center pt-[150px]"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      <div className="bg-gray-100 dark:bg-slate-400 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-2xl font-semibold mb-4 text-center">
          Profil Utilisateur
        </h1>
        <div className="flex flex-col items-center mb-4">
          <img
            src={user.photoURL || "https://via.placeholder.com/150"}
            alt="Avatar de l'utilisateur"
            className="w-24 h-24 rounded-full object-cover mb-4 border-2 border-slate-400 shadow-lg"
          />
          <div>
            <Button
              text="Modifier Photo"
              onClick={handleUploadModal}
              className="mb-4"
            />
            {isUploading && (
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
                  <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
                    Changer la Photo de Profil
                  </h2>
                  <form
                    onSubmit={handleUploadSubmit}
                    className="flex flex-col items-center space-y-4 w-full"
                  >
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                      className="my-2"
                    />
                    <div className="flex flex-auto gap-4">
                      <Button
                        text="Sauvegarder"
                        type="submit"
                        icon={FaSave}
                        className="gap-2"
                      />
                      <Button
                        text="Fermer"
                        onClick={() => setIsUploading(false)}
                        icon={FaTimes}
                        className="gap-2"
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
          <h2 className="text-xl">
            {user.prenom} {user.nom}
          </h2>
          <p className="text-gray-600">{user.email}</p>
        </div>
        <div className="flex justify-center">
          <Button text="Modifier les informations" onClick={handleEdit} />
        </div>

        {isEditing && (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-slate-200 dark:bg-slate-400 dark:text-black p-6 rounded-lg shadow-xl max-w-lg w-full">
              <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
                Modifier le Profil
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label>Nom</label>
                  <input
                    type="text"
                    name="nom"
                    value={user.nom}
                    onChange={handleChange}
                    className="border rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label>Prénom</label>
                  <input
                    type="text"
                    name="prenom"
                    value={user.prenom}
                    onChange={handleChange}
                    className="border rounded p-2 w-full"
                  />
                </div>

                <div className="flex justify-center space-x-2">
                  <Button
                    text="Sauvegarder"
                    type="submit"
                    icon={FaSave}
                    className="gap-2"
                  />
                  <Button
                    text="Annuler"
                    onClick={handleCloseModal}
                    className="hover:bg-gray-700 gap-2"
                    icon={FaTimes}
                  />
                </div>
                <p>
                  Attention, vous devrez vous déconnecter et reconnecter après
                  les changements.
                </p>
              </form>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Profile;
