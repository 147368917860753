import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../../../components/Button/Button';
import { updateAssociation, getAssociationById } from '../../../../services/associationsServices';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditAssociationModal = ({ isOpen, onClose, associationId, refreshList }) => {
  const [formData, setFormData] = useState({
    nom_asso: '',
    statut_asso: '',
    adresse_asso: '',
    tel_asso: '',
    mail_asso: '',
    siret_asso: '',
    discipline_asso: '',
    siteweb_asso: '',
    detail_asso: '',
    logo_asso: '',
    is_favorite: false,
  });

  useEffect(() => {
    const fetchAssociationData = async () => {
      try {
        const data = await getAssociationById(associationId);
        setFormData({
          ...data,
          is_favorite: !!data.is_favorite,  // Ensure is_favorite is a boolean
        });
      } catch (error) {
        toast.error("Erreur lors du chargement des données de l'association");
      }
    };

    if (isOpen && associationId) {
      fetchAssociationData();
    }
  }, [isOpen, associationId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,  // Handle checkbox differently
    });
  };

  const handleDetailChange = (value) => {
    setFormData({ ...formData, detail_asso: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateAssociation(associationId, formData);
      toast.success('Association mise à jour avec succès');
      refreshList();
      onClose();
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de l'association");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full max-h-[90vh] overflow-y-auto">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-gray-200 bg-primary rounded-md shadow">
          Modifier l'Association
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Nom de l'Association:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="nom_asso"
              value={formData.nom_asso}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Statut:</label>
            <select
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              name="statut_asso"
              value={formData.statut_asso}
              onChange={handleChange}
              required
            >
              <option value="Client">Client</option>
              <option value="Prospect">Prospect</option>
              <option value="Ancien Client">Ancien Client</option>
              <option value="Prof.">Prof.</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Adresse:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="adresse_asso"
              value={formData.adresse_asso}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Téléphone:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="tel_asso"
              value={formData.tel_asso}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Email:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="email"
              name="mail_asso"
              value={formData.mail_asso}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">SIRET:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="siret_asso"
              value={formData.siret_asso}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Discipline:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="discipline_asso"
              value={formData.discipline_asso}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Site Web:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="siteweb_asso"
              value={formData.siteweb_asso}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Détail:</label>
            <ReactQuill
              value={formData.detail_asso}
              onChange={handleDetailChange}
              className="bg-gray-400 text-gray-800 border border-gray-600 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Logo:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="logo_asso"
              value={formData.logo_asso}
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center">
            <label className="block text-sm font-bold text-gray-300 mb-2">Favori:</label>
            <input
              className="ml-2 checkbox"
              type="checkbox"
              name="is_favorite"
              checked={formData.is_favorite}
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Mettre à jour" icon={FaSave} type="submit" className='gap-2' />
            <Button text="Fermer" icon={FaTimes} onClick={onClose} className='gap-2' />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAssociationModal;
