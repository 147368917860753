import React from "react";
import { FaSignInAlt, FaUserPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AuthIcons = () => {
  return (
    <div className="fixed top-0 right-0 z-40" style={{ top: "180px" }}>
      <div className="group cursor-pointer mb-5">
        <Link to="/login">
          <div className="flex items-center bg-blue-600 p-2 rounded-l-full text-white absolute right-8 translate-x-full group-hover:translate-x-8 transition-transform duration-300">
            <FaSignInAlt />
            <span className="whitespace-nowrap ml-2 mr-2">Se connecter</span>
          </div>
        </Link>
      </div>
      <div className="group cursor-pointer">
        <Link to="/register">
          <div className="flex items-center my-5 mt-8 bg-primary p-2 rounded-l-full text-white absolute right-8 translate-x-full group-hover:translate-x-8 transition-transform duration-300">
            <FaUserPlus />
            <span className="whitespace-nowrap ml-2 mr-2">S'inscrire</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AuthIcons;
