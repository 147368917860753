import React, { useState, useEffect } from "react";
import { sendMessage } from "./../../../../services/messageServices";
import { getUserById } from "./../../../../services/userServices";
import { toast } from "react-toastify";
import { IoIosSend } from "react-icons/io";
import Button from "../../../../components/Button/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateResponseMessageForm = ({
  onSuccess,
  show,
  onHide,
  userId,
  initialRecipientId,
  initialSubject,
}) => {
  const [messageData, setMessageData] = useState({
    recipient_id: initialRecipientId || "",
    subject: initialSubject || "",
    content: "",
  });
  const [recipientName, setRecipientName] = useState("");

  useEffect(() => {
    if (initialRecipientId && !recipientName) {
      fetchRecipientName(initialRecipientId);
    }
  }, [initialRecipientId]);

  const fetchRecipientName = async (recipientId) => {
    try {
      const userDetails = await getUserById(recipientId);
      if (userDetails) {
        setRecipientName(`${userDetails.nom} ${userDetails.prenom}`);
      } else {
        console.error("User not found.");
      }
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  };

  const handleChange = (e) => {
    setMessageData({ ...messageData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !messageData.recipient_id ||
      !messageData.subject ||
      !messageData.content
    ) {
      toast.error("Merci de remplir tous les champs.");
      return;
    }

    try {
      await sendMessage({
        sender_id: userId,
        recipient_id: messageData.recipient_id,
        subject: messageData.subject,
        content: messageData.content,
      });
      toast.success("Message envoyé avec succès.");
      if (onSuccess) onSuccess();
      onHide();
    } catch (error) {
      console.error("Failed to send message:", error);
      toast.error("Erreur lors de l'envoi du message.");
    }
  };

  if (!show) return null;

  return (
    <div className="mt-5 container mx-auto p-4 bg-gray-600 text-gray-200 ">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Destinataire:</label>
          <input
            type="text"
            value={recipientName || `Loading... (ID: ${initialRecipientId})`}
            className="bg-gray-700 text-gray-200 border border-gray-600 rounded-lg py-2 px-3"
            readOnly
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Sujet:</label>
          <input
            type="text"
            name="subject"
            value={messageData.subject}
            onChange={handleChange}
            className="bg-gray-700 text-gray-200 border border-gray-600 rounded-lg py-2 px-3"
            required
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Contenu:</label>
          <ReactQuill
            value={messageData.content}
            onChange={(content) => setMessageData({ ...messageData, content })}
            className="bg-gray-400 text-gray-800 border border-gray-600 rounded-lg"
            required
          />
        </div>
        <div className="flex justify-center gap-4 items-center w-full px-4 mt-4">
          <Button
            text="Envoyer"
            icon={IoIosSend}
            type="submit"
            className="w-xl gap-2 mb-4 bg-gray-700 text-gray-200"
          />
          <Button
            text="Annuler"
            onClick={onHide}
            className="w-xl gap-2 mb-4 bg-gray-700 text-gray-200"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateResponseMessageForm;
