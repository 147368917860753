import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/echeances`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les échéances
export const getAllEcheances = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allEcheances`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des échéances:", error);
    return [];
  }
};

// Fonction pour récupérer une échéance par son id
export const getEcheanceById = async (echeanceId) => {
  try {
    const response = await axios.get(`${apiUrl}/echeanceById/${echeanceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de l'échéance:", error);
    return null;
  }
};

/// POST
// Fonction pour ajouter une échéance
export const addEcheance = async (echeance) => {
  try {
    const response = await axios.post(`${apiUrl}/addEcheance`, echeance, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'échéance:", error);
    return null;
  }
};

/// PATCH
// Fonction pour modifier une échéance
export const updateEcheance = async (echeanceId, echeance) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updateEcheance/${echeanceId}`,
      echeance,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de l'échéance:", error);
    return null;
  }
};

/// DELETE
// Fonction pour supprimer une échéance
export const deleteEcheance = async (echeanceId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteEcheance/${echeanceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'échéance:", error);
    return null;
  }
};
