import React, { createContext, useState, useEffect, useContext } from "react";
import { countTasksNotDone } from "./../services/tasksServices";
import { useUser } from "./../contexts/UserContext";

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const { user } = useUser();
  const [tasksNotDoneCount, setTasksNotDoneCount] = useState(0);

  const loadTasksNotDoneCount = async () => {
    if (user && user.userId) {
      const response = await countTasksNotDone();
      setTasksNotDoneCount(response.count);
    }
  };

  useEffect(() => {
    loadTasksNotDoneCount();
  }, [user]);

  return (
    <TaskContext.Provider value={{ tasksNotDoneCount, loadTasksNotDoneCount }}>
      {children}
    </TaskContext.Provider>
  );
};

export const useTask = () => useContext(TaskContext);
