import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/taches`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les tâches
export const getAllTasks = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allTasks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      console.log("Réponse API :", response.data);
      return response.data; // ou response.data.data si votre API encapsule les données
    } else {
      console.error("Les données de l'API sont vides ou non définies.");
      return [];
    }
  } catch (error) {
    console.error("Erreur lors du chargement des tâches:", error);
    return []; // Renvoie un tableau vide en cas d'erreur
  }
};

// Fonction pour compter les taches non terminées
export const countTasksNotDone = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countTasksNotDone`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des tâches non terminées:", error);
  }
};

// Fonction pour récupérer une tâche par son ID
export const getTaskById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/taskById/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement de la tâche:", error);
  }
};

/// POST
// Fonction pour sauvegarder une tâche
export const addTask = async (formData) => {
  try {
    const response = await axios.post(`${apiUrl}/addTask`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la sauvegarde de la tâche:", error);
  }
};

/// PUT
// Fonction pour mettre à jour une tâche
export const updateTask = async (id, formData) => {
  try {
    const response = await axios.put(`${apiUrl}/updateTask/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la tâche:", error);
  }
};

/// DELETE
// Fonction pour supprimer une tâche
export const deleteTask = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteTask/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de la tâche:", error);
  }
};
