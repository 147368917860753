import React, { useState, useEffect } from "react";
import Footer from "./../Home/Footer";
import useScrollToTop from "../../hooks/useScrollToTop";
import AuthIcons from "../../hooks/AuthIcons";
import { useUser } from "../../contexts/UserContext";
import AboutMonAppliClubLight from "./AboutMonAppliClubLight";
import FormContact from "./../Home/FormContact";
import { FaPhoneAlt } from "react-icons/fa";
import DiaporamaLight from "./DiaporamaLight";

const MonAppliClubLight = () => {
  useScrollToTop();

  const { user } = useUser();
  const [currentImage, setCurrentImage] = useState("/images/fond07.jpg");

  useEffect(() => {
    const images = ["/images/fond07.jpg", "/images/fond08.webp"]; // Remplace ces images avec les tiennes
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length;
      setCurrentImage(images[currentIndex]);
    }, 7000); // Change l'image toutes les 7 secondes

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {!user && <AuthIcons />}
      <div className="flex-grow">
        <div className="relative h-screen">
          <div
            className="absolute inset-0 transition-opacity duration-1000 ease-in-out bg-cover bg-center"
            style={{
              backgroundImage: `url('${currentImage}')`,
            }}
          ></div>
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
            <div className=" p-8 rounded-lg">
            <span className="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Club "Light"
              </span>
            <p className="text-lg mb-4">
              Une solution pour une meilleure visibilité de votre club sur internet.
            </p>
            </div>
          </div>
        </div>

        {/* Section About */}
        <div id="about">
          <AboutMonAppliClubLight />
        </div>
                {/* image sur toute la largeur sur 500px max, l'image doit s'élargir sur toute la largeur */}
        <div id="diaporama">
          <img
              src="https://res.cloudinary.com/dy5kblr32/image/upload/v1728204732/fake/illustration_MACL_rihirg.webp"
              alt="inscription en ligne"
              className="w-full max-h-[600px] object-cover"
            />
        </div>

        {/* DiaporamaLight */}
        <DiaporamaLight />
        

        {/* Section de contact avec téléphone et formulaire */}
        <div className="bg-gradient-to-r from-yellow-400 to-orange-600 text-white text-center py-8">
          <h2 className="text-2xl font-bold">Je souhaite être recontacté</h2>
          <p className="mt-4">
            Vous souhaitez en savoir plus sur notre solution MonAppliClub "Light" ?
            <br />
            Remplissez le formulaire ci-dessous ou appelez-nous directement.
          </p>

          {/* Bouton avec icône de téléphone et numéro à droite */}
          <div className="mt-6 flex justify-center items-center">
            <a
              href="tel:0680727707"
              className="flex items-center bg-white text-black px-4 py-2 rounded-full transition hover:bg-gray-300"
            >
              <FaPhoneAlt className="mr-2" /> {/* Icône de téléphone */}
              Appeler maintenant
            </a>
            <span className="ml-4 text-3xl font-bold">06.80.72.77.07</span>{" "}
            {/* Numéro à droite */}
          </div>
        </div>

        {/* Composant FormContact */}
        <FormContact />


      </div>

      <Footer />
    </div>
  );
};

export default MonAppliClubLight;
