import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/subscriptions`;
const token = localStorage.getItem("token");

/// GET
export const getAllSubscriptions = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allSubscriptions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      console.log("Réponse API :", response.data);
      return response.data;
    }
    console.error("Les données de l'API sont vides ou non définies.");
    return [];
  } catch (error) {
    console.error("Erreur lors du chargement des abonnements:", error);
    return [];
  }
};

export const getSubscriptionById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/subscriptionById/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement de l'abonnement:", error);
  }
};

/// POST
export const saveSubscription = async (subscriptionData, token) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addSubscription`,
      subscriptionData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'enregistrement de l'abonnement:", error);
    throw error;
  }
};

/// PATCH
export const updateSubscription = async (id, subscriptionData) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updateSubscription/${id}`,
      subscriptionData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'abonnement:", error);
    throw error;
  }
};

/// DELETE
export const deleteSubscription = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteSubscription/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'abonnement:", error);
    throw error;
  }
};

