// CarteFrance.jsx
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const CarteFrance = ({ partenaires }) => {
  const [departements, setDepartements] = useState([]);

  useEffect(() => {
    const loadGeoJSON = async () => {
      const response = await fetch("/departements.geojson");
      const data = await response.json();
      if (data && data.features) {
        // Récupère les départements ayant une annonce
        const annoncesDepartements = partenaires.map(
          (partenaire) => partenaire.departement
        );
        const departementsAvecAnnonce = data.features.map((feature) => {
          const hasAnnonce = annoncesDepartements.includes(
            parseInt(feature.properties.code)
          );
          return {
            ...feature,
            properties: { ...feature.properties, hasAnnonce },
          };
        });
        setDepartements(departementsAvecAnnonce);
      }
    };
    loadGeoJSON();
  }, [partenaires]); // Dépendance sur partenaires

  const styleDepartement = (feature) => ({
    fillColor: feature.properties.hasAnnonce ? "#0000FF" : "#CCCCCC",
    weight: 1,
    color: "black",
    fillOpacity: 0.5,
    zindex: 99,
  });

  if (!departements.length) {
    return <div>Chargement de la carte...</div>;
  }

  return (
    <MapContainer
      center={[46.603354, 1.888334]}
      zoom={6}
      style={{ height: "500px", width: "100%" }}
      className="carte-france"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <GeoJSON
        key={JSON.stringify(departements)} // Force le recalcul quand departements change
        data={departements}
        style={styleDepartement}
      />
    </MapContainer>
  );
};

export default CarteFrance;
