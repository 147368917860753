import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../../../components/Button/Button';
import { addTask } from '../../../../services/tasksServices';
import { getAllAssociations } from '../../../../services/associationsServices';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTask } from '../../../../contexts/TaskContext';

const AddTaskModal = ({ isOpen, onClose, refreshList }) => {
  const initialFormData = {
    categorie: '',
    titre: '',
    contenu: '',
    asso_id: '',
    statut: 'A faire',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [associations, setAssociations] = useState([]);
  const { loadTasksNotDoneCount } = useTask();

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData); // Réinitialise les champs du formulaire à l'ouverture de la modale
      fetchAssociations(); // Récupère les associations
    }
  }, [isOpen]); // Réinitialise les données à chaque ouverture

  const fetchAssociations = async () => {
    try {
      const associationsData = await getAllAssociations();
      setAssociations(associationsData || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des associations:", error);
      toast.error("Erreur lors de la récupération des associations");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContenuChange = (value) => {
    setFormData({ ...formData, contenu: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addTask(formData);
      toast.success('Tâche ajoutée avec succès');
      refreshList();
      loadTasksNotDoneCount();
      onClose();
    } catch (error) {
      toast.error("Erreur lors de l'ajout de la tâche");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full max-h-full overflow-y-auto">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-gray-200 bg-primary rounded-md shadow">
          Ajouter une Tâche
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Catégorie:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="categorie"
              value={formData.categorie}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Titre:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="titre"
              value={formData.titre}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Contenu:</label>
            <ReactQuill
              value={formData.contenu}
              onChange={handleContenuChange}
              className="bg-gray-400 text-gray-800 border border-gray-600 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Association:</label>
            <select
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              name="asso_id"
              value={formData.asso_id}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionnez une association</option>
              {associations.map((association) => (
                <option key={association.id} value={association.id}>
                  {association.nom_asso}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Statut:</label>
            <select
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              name="statut"
              value={formData.statut}
              onChange={handleChange}
              required
            >
              <option value="A faire">À Faire</option>
              <option value="En cours">En Cours</option>
              <option value="Terminee">Terminée</option>
              <option value="Urgent">Urgent</option>
            </select>
          </div>
          <div className="flex justify-center space-x-4 mt-4">
            <Button text="Ajouter" icon={FaSave} type="submit" className='gap-2' />
            <Button text="Fermer" icon={FaTimes} onClick={onClose} className='gap-2' />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTaskModal;
