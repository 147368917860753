import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/desinscriptions`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les désinscriptions
export const getAllDesinscriptions = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allDesinscriptions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des désinscriptions:", error);
    return [];
  }
};

/// POST
// Fonction pour ajouter une désinscription
export const addDesinscription = async (desinscription) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addDesinscription`,
      desinscription,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de la désinscription:", error);
    return null;
  }
};

/// DELETE
// Fonction pour supprimer une désinscription par ID
export const deleteDesinscriptionById = async (desinscriptionId) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteDesinscription/${desinscriptionId}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Si authentification nécessaire
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de la désinscription:", error);
    throw error;
  }
};