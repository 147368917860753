import React from 'react';
import { FaEdit  } from 'react-icons/fa'; // Importer les icônes nécessaires

// ButtonEdit avec une icône d'édition
const ButtonVerif = ({ onClick, className = '', hoverLabel = 'Se connecter pour modifier'  }) => {
  return (
    <button
      onClick={onClick}
      aria-label={hoverLabel}
      title={hoverLabel} 
      className={`w-8 h-8 bg-blue-500 text-white rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-blue-700 flex items-center justify-center ${className}`}
    >
      <FaEdit  className='text-[12px]'/>
    </button>
  );
};


export default ButtonVerif;
