import React, { createContext, useState, useEffect, useContext } from "react";
import { getUnreadMessagesCountContactForm } from "../services/contactformServices";

// Création du contexte
const ContactFormContext = createContext();

// Fournisseur du contexte
export const ContactFormProvider = ({ userId, children }) => {
  const [unreadCountContactForm, setUnreadCountContactForm] = useState(0);

  useEffect(() => {
    const loadUnreadCountContactForm = async () => {
      const countContactForm = await getUnreadMessagesCountContactForm(userId);
      setUnreadCountContactForm(countContactForm);
    };

    loadUnreadCountContactForm();
  }, [userId]);

  return (
    <ContactFormContext.Provider
      value={{ unreadCountContactForm, setUnreadCountContactForm }}
    >
      {children}
    </ContactFormContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useContactForm = () => useContext(ContactFormContext);
