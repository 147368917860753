import React, { useState, useEffect } from "react";
import Footer from "./../Home/Footer";
import useScrollToTop from "../../hooks/useScrollToTop";
import getCategory from "./../../utils/getCategory";
import getWeightCategory from "./../../utils/getWeightCategory";
import { useUser } from "../../contexts/UserContext";
import AuthIcons from "../../hooks/AuthIcons"; // Correct import of AuthIcons
import { toast, ToastContainer } from "react-toastify";
import Button from "../../components/Button/Button";
import { FaWeight } from "react-icons/fa";
import TableauCategories from "./TableauCategories";

const MonAppliCatePage = () => {
  useScrollToTop();
  const { user } = useUser();

  const [currentImage, setCurrentImage] = useState("/images/fond14.webp");
  const [saison, setSaison] = useState("2024-2025");
  const [genre, setGenre] = useState("Masculin");
  const [dateNaissance, setDateNaissance] = useState("");
  const [poids, setPoids] = useState("");
  const [categorieAge, setCategorieAge] = useState("Inconnu");
  const [categoriePoids, setCategoriePoids] = useState("Non pesé(e)");

  // Change background images every 7 seconds
  useEffect(() => {
    const images = ["/images/fond14.webp", "/images/fond15.webp"];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length;
      setCurrentImage(images[currentIndex]);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  // Exemple de calcul des catégories
const calculerCategories = () => {
  // Reformatez la date de naissance en YYYY-MM-DD
  const formattedDate = new Date(dateNaissance).toISOString().slice(0, 10);

  // Calculez la catégorie d'âge en passant la saison active et le genre
  const ageCategory = getCategory(formattedDate, saison, genre);

  // Créez une variable pour l'affichage de la catégorie d'âge (pour le féminin)
  let displayAgeCategory = ageCategory;
  if (genre === "Féminin") {
    if (ageCategory === "Mini-Poussin") displayAgeCategory = "Mini-Poussine";
    if (ageCategory === "Poussin") displayAgeCategory = "Poussine";
    if (ageCategory === "Benjamin") displayAgeCategory = "Benjamine";
    if (ageCategory === "Cadet") displayAgeCategory = "Cadette";
  }

  // Utilisez toujours ageCategory (version masculine) pour getWeightCategory
  const weightCategory = getWeightCategory(genre, ageCategory, poids);

  setCategorieAge(displayAgeCategory);
  setCategoriePoids(weightCategory);

  toast.success("Catégories calculées avec succès !");
};

  return (
    <div className="flex flex-col min-h-screen">
      {!user && <AuthIcons />} {/* Include AuthIcons if user is not authenticated */}
      <div className="flex-grow">
        <div className="relative">
          <div
            className="absolute inset-0 transition-opacity duration-1000 ease-in-out bg-cover bg-center"
            style={{ backgroundImage: `url('${currentImage}')` }}
          ></div>
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 flex flex-col items-center justify-center h-screen text-center text-white">
            <div className="p-8 rounded-lg">
              <span className="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl font-semibold font-passionOne">
                Mon
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  Appli
                </span>
                Caté
              </span>
              <p className="text-lg mb-4">
                Application pour trouver votre catégorie d'âge et de poids.
              </p>
            </div>
          </div>
        </div>

        {/* Contenu principal */}
        <div className="container mx-auto py-8 px-4 flex flex-col lg:flex-row gap-8">
          {/* Formulaire de saisie */}
          <div className="flex-1 bg-gray-200 border border-gray-400 dark:bg-gray-800 shadow-md rounded-lg p-6">
            <h3 className="text-2xl font-bold mb-4 text-center text-gray-700 dark:text-gray-300">Formulaire</h3>
            {/* Ligne pour saison et genre */}
            <div className="flex gap-4 mb-4">
              <div className="flex-1">
                <label className="block mb-2 text-gray-700 dark:text-gray-300">Saison :</label>
                <select
                  value={saison}
                  onChange={(e) => setSaison(e.target.value)}
                  className="w-full p-2 border rounded-lg dark-bg-gray-200 dark:text-gray-800"
                >
                  <option value="2024-2025">2024-2025</option>
                  <option value="2025-2026">2025-2026</option>
                </select>
              </div>
              <div className="flex-1">
                <label className="block mb-2 text-gray-700 dark:text-gray-300">Genre :</label>
                <select
                  value={genre}
                  onChange={(e) => setGenre(e.target.value)}
                  className="w-full p-2 border rounded-lg dark-bg-gray-200 dark:text-gray-800"
                >
                  <option value="Masculin">Masculin</option>
                  <option value="Féminin">Féminin</option>
                </select>
              </div>
            </div>

            {/* Ligne pour date de naissance et poids */}
            <div className="flex gap-4 mb-8">
              <div className="flex-1">
                <label className="block mb-2 text-gray-700 dark:text-gray-300">Date de naissance :</label>
                <input
                  type="date"
                  value={dateNaissance}
                  onChange={(e) => setDateNaissance(e.target.value)}
                  className="w-full p-2 border rounded-lg dark-bg-gray-200 dark:text-gray-800"
                />
              </div>
              <div className="flex-1">
                <label className="block mb-2 text-gray-700 dark:text-gray-300">Poids (kg) :</label>
                <input
                  type="number"
                  value={poids}
                  onChange={(e) => setPoids(e.target.value)}
                  className="w-full p-2 border rounded-lg dark-bg-gray-200 dark:text-gray-800"
                />
              </div>
            </div>

            {/* Bouton pour calculer les catégories */}
            <Button
              text="Calculer"
              icon={FaWeight}
              onClick={calculerCategories}
              className="w-full mb-4 gap-2 text-xl"
            />
          </div>

          {/* Carte des réponses */}
          <div className="flex-1 bg-gray-200 border border-gray-400 dark:bg-gray-800 shadow-md rounded-lg p-6 flex flex-col justify-evenly">
            <h3 className="text-2xl font-bold mb-4 text-center text-gray-700 dark:text-gray-300">Résultats</h3>
            <div className="border border-gray-400 text-center text-xl font-semibold text-gray-800 dark:text-white mb-4 bg-blue-100 dark:bg-blue-800 py-2 rounded-lg">
              Genre : <span className="font-extrabold text-3xl">{genre}</span>
            </div>
            <div className="border border-gray-400 text-center text-xl font-semibold text-gray-800 dark:text-white mb-4 bg-yellow-100 dark:bg-yellow-800 py-2 rounded-lg">
              Catégorie d'âge : <span className="font-extrabold text-3xl">{categorieAge}</span>
            </div>
            <div className="border border-gray-400 text-center text-xl font-semibold text-gray-800 dark:text-white bg-green-100 dark:bg-green-800 py-2 rounded-lg">
              Catégorie de poids : <span className="font-extrabold text-3xl">{categoriePoids}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Tableau des catégories d'âge et de poids */}
      <TableauCategories />

      <ToastContainer />
      <Footer />
    </div>
  );
};

export default MonAppliCatePage;
