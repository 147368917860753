import React from "react";
import StatisticsCards from "./StatisticsCards";
import LatestUsersAndAssociations from "./LatestUsersAndAssociations";
import DepensesPieChart from "./DepensesPieChart";
import DepensesProByMonthChart from "./DepensesProByMonthChart";
import VisitesLineChart from "./VisitesLineChart";
import ComponentTitle from "./../../../Home/ComponentTitle";
import AssociationsShortcuts from "./AssociationsShortcuts";
import StatisticClubsCards from "./StatisticClubsCards";
import UsersCountTable from "./UsersCountTable";
import VisitesDuJourBarChart from "./VisitesDuJourBarChart";
import PagesVisitesBarChart from "./PagesVisitesBarChart";


const DashboardAdmin = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-8 bg-slate-200 rounded-lg p-2">
        <ComponentTitle title="Tableau" highlight=" de Bord" />
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Bienvenue dans votre espace de tableau de bord. Vous pouvez voir les statistiques de vos projets ici !
        </p>
      </div>
      <StatisticsCards />
      <div className="mt-4">
      <StatisticClubsCards />
      </div>

      <div className="mt-4">
      <VisitesDuJourBarChart />
      </div>

      <div className="mt-4">
      <PagesVisitesBarChart />
      </div>

      <div className="mt-4">
        <UsersCountTable />
      </div>
      <div className="mt-4">
        <LatestUsersAndAssociations />
      </div>

      <div className="mt-4">
        <DepensesPieChart />
      </div>
      <div className="mt-4">
        <DepensesProByMonthChart />
      </div>

      {/* Ajout du nouveau composant */}
      <div className="mt-4">
        <AssociationsShortcuts />
      </div>
    </div>
  );
};

export default DashboardAdmin;
