import React, { useState, useEffect } from "react";
import { getLatestUsers } from "../../../../services/userServices";
import { getLatestAssociations } from "../../../../services/associationsServices";

const LatestUsersAndAssociations = () => {
  const [latestUsers, setLatestUsers] = useState([]);
  const [latestAssociations, setLatestAssociations] = useState([]);

  useEffect(() => {
    const fetchLatestData = async () => {
      try {
        const usersResponse = await getLatestUsers();
        setLatestUsers(usersResponse.slice(0, 5)); // Récupérer les 5 derniers utilisateurs

        const associationsResponse = await getLatestAssociations();
        setLatestAssociations(associationsResponse.slice(0, 5)); // Récupérer les 5 dernières associations
      } catch (error) {
        console.error("Erreur lors de la récupération des dernières données", error);
      }
    };

    fetchLatestData();
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
      {/* Tableau des derniers utilisateurs */}
      <div className="bg-gray-800 rounded-lg shadow-lg p-4 dark:bg-gray-900 dark:text-gray-200">
        <h3 className="text-lg font-bold text-white mb-4">Derniers Utilisateurs</h3>
        <table className="w-full text-left text-sm">
          <thead>
            <tr>
              <th className="pb-2 text-gray-400">Photo</th>
              <th className="pb-2 text-gray-400">Nom</th>
              <th className="pb-2 text-gray-400">Email</th>
            </tr>
          </thead>
          <tbody>
            {latestUsers.map((user) => (
              <tr key={user.id} className="border-b border-gray-700">
                <td className="py-2">
                  <img
                    src={user.photoURL}
                    alt={`${user.prenom} ${user.nom}`}
                    className="w-8 h-8 rounded-full"
                  />
                </td>
                <td className="py-2 text-white">
                  {user.prenom} {user.nom}
                </td>
                <td className="py-2 text-gray-400">{user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Tableau des dernières associations */}
      <div className="bg-gray-800 rounded-lg shadow-lg p-4 dark:bg-gray-900 dark:text-gray-200">
        <h3 className="text-lg font-bold text-white mb-4">Dernières Associations</h3>
        <table className="w-full text-left text-sm">
          <thead>
            <tr>
              <th className="pb-2 text-gray-400">Nom</th>
              <th className="pb-2 text-gray-400">Discipline</th>
            </tr>
          </thead>
          <tbody>
            {latestAssociations.map((association) => (
              <tr key={association.id} className="border-b border-gray-700">
                <td className="py-2 text-white">{association.nom_asso}</td>
                <td className="py-2 text-gray-400">{association.discipline_asso}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LatestUsersAndAssociations;
