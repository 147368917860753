import { useEffect } from "react";

const useScrollToTop = () => {
  useEffect(() => {
    const attemptScrollToTop = () => {
      try {
        // Using `behavior: 'smooth'` to smoothly scroll to the top
        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch (error) {
        // Fallback for browsers that do not support `behavior: 'smooth'`
        window.scrollTo(0, 0);
      }
    };

    attemptScrollToTop();
  }, []);
};

export default useScrollToTop;
