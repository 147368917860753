import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../../../components/Button/Button';
import { getEcheanceById, updateEcheance } from '../../../../services/echeanceServices';
import { getAllAssociations } from '../../../../services/associationsServices';
import { toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css';

const EditEcheanceModal = ({ isOpen, onClose, refreshList, echeanceId }) => {
  const [formData, setFormData] = useState({
    nom_echeance: '',
    categorie_echeance: '',
    debut_echeance: '',
    fin_echeance: '',
    asso_id: '',
    renouvellement_auto: false,
  });
  const [associations, setAssociations] = useState([]);

  useEffect(() => {
    const fetchEcheance = async () => {
      if (isOpen && echeanceId) {
        try {
          const echeance = await getEcheanceById(echeanceId);
          setFormData({
            nom_echeance: echeance.nom_echeance || '',
            categorie_echeance: echeance.categorie_echeance || '',
            debut_echeance: echeance.debut_echeance || '',
            fin_echeance: echeance.fin_echeance || '',
            asso_id: echeance.asso_id || '',
            renouvellement_auto: echeance.renouvellment_auto,
          });
        } catch (error) {
          toast.error("Erreur lors du chargement de l'échéance");
        }
      }
    };

    const fetchAssociations = async () => {
      try {
        const associationsData = await getAllAssociations();
        setAssociations(associationsData || []);
      } catch (error) {
        toast.error("Erreur lors de la récupération des associations");
      }
    };
    if (isOpen) {
      fetchAssociations();
      fetchEcheance();
    }
  }, [isOpen, echeanceId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateEcheance(echeanceId, formData);
      toast.success("Échéance modifiée avec succès");
      refreshList();
      onClose();
    } catch (error) {
      toast.error("Erreur lors de la modification de l'échéance");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full max-h-[90vh] overflow-y-auto">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-gray-200 bg-gray-700 rounded-md shadow">
          Modifier l'Échéance
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Nom de l'Échéance:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="nom_echeance"
              value={formData.nom_echeance}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Catégorie:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="categorie_echeance"
              value={formData.categorie_echeance}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Date de Début:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="date"
              name="debut_echeance"
              value={formData.debut_echeance}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Date de Fin:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="date"
              name="fin_echeance"
              value={formData.fin_echeance}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Association:</label>
            <select
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              name="asso_id"
              value={formData.asso_id}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionnez une association</option>
              {associations.map((association) => (
                <option key={association.id} value={association.id}>
                  {association.nom_asso}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Renouvellement Automatique:</label>
            <input
              type="checkbox"
              name="renouvellement_auto"
              checked={formData.renouvellement_auto}
              onChange={(e) => setFormData({ ...formData, renouvellement_auto: e.target.checked })}
            />
          </div>
          <div className="flex justify-center space-x-4 mt-4">
            <Button text="Modifier" icon={FaSave} type="submit" className="bg-gray-600 text-gray-200 hover:bg-gray-500 hover:text-white px-4 py-2 rounded-md shadow-sm transition duration-300 ease-in-out gap-2" />
            <Button text="Fermer" icon={FaTimes} onClick={onClose} className="bg-gray-600 text-gray-200 hover:bg-gray-500 hover:text-white px-4 py-2 rounded-md shadow-sm transition duration-300 ease-in-out gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEcheanceModal;
