import React, { useEffect } from "react";
import { getSettings } from "../services/settingsServices";

const PrimaryColorProvider = ({ children }) => {
  useEffect(() => {
    const fetchPrimaryColor = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        const primaryColor = data[0].primary_color;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
      }
    };

    fetchPrimaryColor();
  }, []);

  return <>{children}</>;
};

export default PrimaryColorProvider;
