import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { sendMessage } from "./../../../../services/messageServices";
import { getAllUsers, getAdminsIds } from "./../../../../services/userServices";
import { IoIosSend } from "react-icons/io";
import Button from "./../../../../components/Button/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateMessageForm = ({
  onSuccess,
  show,
  onHide,
  userId,
  userRole,
  initialRecipientId = "",
  initialSubject = "",
}) => {
  const [messageData, setMessageData] = useState({
    recipient_id: initialRecipientId,
    subject: initialSubject,
    content: "",
  });
  const [recipients, setRecipients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRecipients = async () => {
      setIsLoading(true);
      try {
        let response = [];

        if (userRole === "administrateur") {
          // Si l'utilisateur est administrateur, récupérer tous les utilisateurs
          response = await getAllUsers();
        } else if (userRole === "utilisateur" || userRole === "client") {
          // Si l'utilisateur est un utilisateur ou un client, récupérer uniquement les administrateurs
          response = await getAdminsIds();
        }

        setRecipients(response);
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors du chargement des destinataires:", error);
        toast.error("Erreur lors du chargement des destinataires.");
        setIsLoading(false);
      }
    };

    fetchRecipients();
  }, [userRole]);

  useEffect(() => {
    setMessageData({
      recipient_id: initialRecipientId,
      subject: initialSubject,
      content: "",
    });
  }, [initialRecipientId, initialSubject, show]);

  const handleChange = (e) => {
    setMessageData({ ...messageData, [e.target.name]: e.target.value });
  };

  const handleContentChange = (content) => {
    setMessageData({ ...messageData, content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { recipient_id, subject, content } = messageData;

    if (!recipient_id || !subject || !content) {
      toast.error("Veuillez remplir tous les champs.");
      return;
    }
    try {
      await sendMessage({
        ...messageData,
        sender_id: userId,
      });
      toast.success("Message envoyé avec succès.");
      if (onSuccess) {
        onSuccess();
      }
      onHide();
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
      toast.error("Erreur lors de l'envoi du message.");
    }
  };

  if (isLoading) {
    return <p>Chargement des destinataires...</p>;
  }

  if (!show) {
    return null;
  }

  return (
    <div className="mt-5 container mx-auto p-4 bg-gray-600 text-gray-200">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Destinataire:</label>
          <select
            name="recipient_id"
            value={messageData.recipient_id}
            onChange={handleChange}
            className="bg-gray-700 text-gray-200 border border-gray-600 rounded-lg py-2 px-3"
            required
          >
            <option value="">Sélectionnez un destinataire</option>
            {recipients.map((recipient) => (
              <option key={recipient.id} value={recipient.id}>
                {recipient.nom} {recipient.prenom}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Objet:</label>
          <select
            name="subject"
            value={messageData.subject}
            onChange={handleChange}
            className="bg-gray-700 text-gray-200 border border-gray-600 rounded-lg py-2 px-3"
            required
          >
            <option value="">Sélectionnez une option</option>
            <option value="Bug technique">Bug technique</option>
            <option value="Problème de connexion">Problème de connexion</option>
            <option value="Erreur 404">Erreur 404</option>
            <option value="Fonctionnalité manquante">
              Fonctionnalité manquante
            </option>
            <option value="Suggestion d'amélioration">
              Suggestion d'amélioration
            </option>
            <option value="Problème de paiements avec Stripe">
              Problème de paiements avec Stripe
            </option>
            <option value="Problèmes liées à Cloudinary">
              Problèmes liées à Cloudinary
            </option>
            <option value="Intervention sur la base de données">
              Intervention sur la base de données
            </option>
            <option value="Autre">Autre</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Contenu:</label>
          <ReactQuill
            value={messageData.content}
            onChange={handleContentChange}
            className="bg-gray-400 text-gray-800 border border-gray-600 rounded-lg"
            required
          />
        </div>

        <div className="flex justify-center gap-4 items-center w-full px-4 mt-4 font-montserrat">
          <Button
            text="Envoyer"
            icon={IoIosSend}
            type="submit"
            className="w-xl gap-2 mb-4 bg-gray-700 text-gray-200"
          />
          <Button
            text="Annuler"
            onClick={onHide}
            className="w-xl gap-2 mb-4 bg-gray-700 text-gray-200"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateMessageForm;
