import React, { useEffect, useState } from "react";
import { useUser } from "../../../../contexts/UserContext";
import { getAssociationsByUserId } from "../../../../services/associationsServices";
import { toast, ToastContainer } from "react-toastify";
import ComponentTitle from "./../../../Home/ComponentTitle";
import RegisterAssociationModal from "./RegisterAssociationModal"; // Importer la modale

const MonAssociationPage = () => {
  const { user } = useUser();
  const [associations, setAssociations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour gérer la modale

  useEffect(() => {
    const fetchAssociations = async () => {
      try {
        const data = await getAssociationsByUserId(user.userId);
        setAssociations(data.Associations);
        setLoading(false);
      } catch (error) {
        toast.error("Erreur lors du chargement des associations.");
        setLoading(false);
      }
    };

    fetchAssociations();
  }, [user.userId]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (loading) {
    return <div>Chargement des associations...</div>;
  }

  if (associations.length === 0) {
    return <div>Aucune association trouvée.</div>;
  }

  const pageTitle =
    associations.length > 1
      ? "Mes Associations / Clubs"
      : "Mon Association / Club";

  return (
    <div className="container mx-auto p-4">
      <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-8 bg-slate-200 rounded-lg p-2">
        <ComponentTitle title="Mon" highlight=" Association" />
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Bienvenue dans votre espace association. Vous pouvez consulter les
          informations de votre association ici !
        </p>
        <button
          onClick={openModal}
          className="mt-4 bg-gray-800 text-white p-2 rounded hover:bg-gray-700"
        >
          Ajouter une Association
        </button>
      </div>

      <div className="mx-auto max-w-screen-md mb-8 lg:mb-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {associations.map((asso) => (
            <div
              key={asso.id}
              className="bg-gray-800 p-6 hover:shadow-xl transition-shadow duration-300 dark:bg-gray-900 dark:text-gray-200"
            >
              <div className="flex items-center mb-4">
                <img
                  src={asso.logo_asso}
                  alt={`${asso.nom_asso} logo`}
                  className="w-16 h-16 rounded-full mr-4 border-2 border-gray-700"
                />
                <div>
                  <h2 className="text-xl font-semibold text-white">
                    {asso.nom_asso}
                  </h2>
                  <p className="text-sm text-gray-400">{asso.statut_asso}</p>
                </div>
              </div>
              <p className="mb-2 text-gray-300">
                <strong>Discipline: </strong>
                {asso.discipline_asso}
              </p>
              <p className="mb-2 text-gray-300">
                <strong>Adresse: </strong>
                {asso.adresse_asso}
              </p>
              <p className="mb-2 text-gray-300">
                <strong>Email: </strong>
                {asso.mail_asso}
              </p>
              <p className="mb-2 text-gray-300">
                <strong>Téléphone: </strong>
                {asso.tel_asso}
              </p>
              {asso.siteweb_asso && (
                <p className="mb-2 text-gray-300">
                  <strong>Site Web: </strong>
                  <a
                    href={asso.siteweb_asso}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:text-blue-500"
                  >
                    {asso.siteweb_asso}
                  </a>
                </p>
              )}
              <p className="mb-2 text-gray-300">
                <strong>SIRET: </strong>
                {asso.siret_asso}
              </p>
              <p className="mb-4 text-gray-300">
                <strong>Détails: </strong>
                {asso.detail_asso}
              </p>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer position="bottom-right" />

      {/* Inclusion de la modale */}
      {isModalOpen && (
        <RegisterAssociationModal onClose={closeModal} />
      )}
    </div>
  );
};

export default MonAssociationPage;
