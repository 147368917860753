import React, { useState } from "react";
import { departementsEtRegions } from "./donnees";
import { createPartenaire } from "../../services/partenaireskataServices";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import { FaPlus, FaTimes } from "react-icons/fa";

const CreatePartenaireModal = ({ isOpen, onClose, refreshList }) => {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    region: "",
    departement: "",
    club: "",
    passage_grade: "",
    content: "",
    tel: "",
    mail: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createPartenaire(formData);
      toast.success("Partenaire créé avec succès !");
      refreshList();
      onClose();
    } catch (error) {
      toast.error("Erreur lors de la création du partenaire.");
      console.error(error);
    }
  };

  const regions = [
    ...new Set(departementsEtRegions.map((item) => item.region)),
  ];
  const filteredDepartements = departementsEtRegions.filter(
    (dep) => dep.region === formData.region
  );

  const grades = [
    "1er Dan",
    "2ème Dan",
    "3ème Dan",
    "4ème Dan",
    "5ème Dan",
    "6ème Dan",
    "7ème Dan",
    "8ème Dan",
    "9ème Dan",
    "10ème Dan",
  ];

  if (!isOpen) return null;

  return (
    <div style={{ zIndex: 100 }}  className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-100">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full max-h-[90vh] overflow-y-auto">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-gray-200 bg-primary rounded-md shadow">
          Créer votre annonce 🥋
        </h3>
        <p className="text-sm italic text-gray-100 mb-4">RGPD : attention, vos coordonnées seront en accès libre pour pouvoir être contacté.</p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-xs text-gray-300 mb-2">Nom :</label>
            <input
              type="text"
              name="nom"
              placeholder="Nom"
              value={formData.nom}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
          </div>
          <div>
            <label className="block text-xs text-gray-300 mb-2">Prénom :</label>
            <input
              type="text"
              name="prenom"
              placeholder="Prénom"
              value={formData.prenom}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
          </div>

          <div>
            <label className="block text-xs text-gray-300 mb-2">Région :</label>
            <select
              name="region"
              value={formData.region}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            >
              <option value="">Sélectionnez une région</option>
              {regions.map((region, index) => (
                <option key={index} value={region}>
                  {region}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-xs text-gray-300 mb-2">
              Département :
            </label>
            <select
              name="departement"
              value={formData.departement}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            >
              <option value="">Sélectionnez un département</option>
              {filteredDepartements.map((dep) => (
                <option key={dep.code} value={dep.code}>
                  {dep.departement}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-xs text-gray-300 mb-2">Club :</label>
            <input
              type="text"
              name="club"
              placeholder="Club"
              value={formData.club}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
          </div>

          <div>
            <label className="block text-xs text-gray-300 mb-2">
              Pour le passage du :
            </label>
            <select
              name="passage_grade"
              value={formData.passage_grade}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            >
              <option value="">Sélectionnez un grade</option>
              {grades.map((grade, index) => (
                <option key={index} value={grade}>
                  {grade}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-xs text-gray-300 mb-2">
              Description : kata travaillé, objectifs, disponibilités, etc.
            </label>
            <textarea
              name="content"
              placeholder="Description"
              value={formData.content}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
          </div>

          <div>
            <label className="block text-xs text-gray-300 mb-2">
              Téléphone :
            </label>
            <input
              type="text"
              name="tel"
              placeholder="Téléphone"
              value={formData.tel}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
          </div>

          <div>
            <label className="block text-xs text-gray-300 mb-2">Email :</label>
            <input
              type="email"
              name="mail"
              placeholder="Email"
              value={formData.mail}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
          </div>

          <div>
            <label className="block text-xs text-gray-300 mb-2">
              Mot de passe :
            </label>
            <input
              type="password"
              name="password"
              placeholder="Mot de passe"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              required
            />
          </div>

          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Créer"
              icon={FaPlus}
              type="submit"
              className="gap-2"
            />
            <Button
              text="Fermer"
              icon={FaTimes}
              onClick={onClose}
              className="gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePartenaireModal;
