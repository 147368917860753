import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../../../components/Button/Button';
import { addAgendaClient } from '../../../../services/agendaclubServices'; // Changez cela pour votre service de rendez-vous
import { getAllAssociations } from '../../../../services/associationsServices';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddAgendaModal = ({ isOpen, onClose, refreshList }) => {
  const initialFormData = {
    type_rdv: 'sur place',
    date_rdv: '',
    heure_debut: '',
    heure_fin: '',
    client_nom: '',
    client_tel: '',
    client_mail: '',
    notes: '',
    association_id: '',  // Changez 'asso_id' en 'association_id'
  };

  const [formData, setFormData] = useState(initialFormData);
  const [associations, setAssociations] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData); // Réinitialise les champs du formulaire
      fetchAssociations();
    }
  }, [isOpen]);

  const fetchAssociations = async () => {
    try {
      const associationsData = await getAllAssociations();
      setAssociations(associationsData || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des associations:", error);
      toast.error("Erreur lors de la récupération des associations");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNotesChange = (value) => {
    setFormData({ ...formData, notes: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addAgendaClient(formData); // Changez cela pour votre service de rendez-vous
      toast.success('Rendez-vous ajouté avec succès');
      refreshList();
      onClose();
    } catch (error) {
      toast.error("Erreur lors de l'ajout du rendez-vous");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full max-h-full overflow-y-auto">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-gray-200 bg-primary rounded-md shadow">
          Ajouter un Rendez-vous
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Type de rendez-vous:</label>
            <select
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              name="type_rdv"
              value={formData.type_rdv}
              onChange={handleChange}
              required
            >
              <option value="sur place">Sur place</option>
              <option value="téléphonique">Téléphonique</option>
              <option value="visio">Visio</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Date du rendez-vous:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="date"
              name="date_rdv"
              value={formData.date_rdv}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-bold text-gray-300 mb-2">Heure de début:</label>
              <input
                className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                type="time"
                name="heure_debut"
                value={formData.heure_debut}
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-bold text-gray-300 mb-2">Heure de fin:</label>
              <input
                className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                type="time"
                name="heure_fin"
                value={formData.heure_fin}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Nom du client:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="text"
              name="client_nom"
              value={formData.client_nom}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Téléphone du client:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="tel"
              name="client_tel"
              value={formData.client_tel}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Email du client:</label>
            <input
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              type="email"
              name="client_mail"
              value={formData.client_mail}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Notes:</label>
            <ReactQuill
              value={formData.notes}
              onChange={handleNotesChange}
              className="bg-gray-400 text-gray-800 border border-gray-600 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-300 mb-2">Association:</label>
            <select
              className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
              name="association_id"
              value={formData.association_id}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionnez une association</option>
              {associations.map((association) => (
                <option key={association.id} value={association.id}>
                  {association.nom_asso}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-center space-x-4 mt-4">
            <Button text="Ajouter" icon={FaSave} type="submit" className='gap-2' />
            <Button text="Fermer" icon={FaTimes} onClick={onClose} className='gap-2' />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAgendaModal;
