import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSettings } from "../../services/settingsServices";

const Footer = () => {
  const navigate = useNavigate();
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
      }
    };

    fetchSettings();
  }, []);

  // Fonction pour gérer le défilement vers les ancres
  const handleNavigate = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/", { state: { scrollTo: id } });
    }
  };

  return (
    <div className="footer-wrapper">
      <footer className="bg-gray-800 p-6 text-white rounded-t border-t-2 flex flex-col lg:flex-row justify-between items-center">
        {/* Logo et Nom de l'application */}
        <div className="flex-1 flex flex-col items-center lg:mb-0 mb-6">
          <span className="text-5xl sm:text-5xl font-semibold font-passionOne">
            Mon
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
              Appli
            </span>
            Club
          </span>
          <p className="text-lg mb-8 italic font-light">
            La solution idéale pour la gestion de votre club sportif
          </p>
        </div>

        {/* Séparateur vertical en blanc sur mobile */}
        <div className="hidden lg:block h-full w-1 bg-white mx-6"></div>

        {/* Navigation vers les sections du site */}
        <div className="flex flex-row lg:flex-row justify-between w-full lg:w-auto">
          <div className="flex-1 flex flex-col items-center">
            <nav className="flex flex-col gap-1 text-center text-sm lg:text-base">
              <a
                href="/"
                className="link link-hover text-white hover:text-primary"
              >
                Accueil
              </a>
              <a
                href="/product-monappliclub"
                className="link link-hover text-white hover:text-primary"
              >
                MonAppliClub
              </a>
              <a
                href="/product-monappliclub-light"
                className="link link-hover text-white hover:text-primary"
              >
                MonAppliClub "Light"
              </a>
              <a
                href="/product-monappliclub-omnisports"
                className="link link-hover text-white hover:text-primary"
              >
                MonAppliClub "Omnisports"
              </a>
              <a
                href="/partenaires-kata"
                className="link link-hover text-white hover:text-primary"
              >
                MonAppliKata
              </a>
              <a
                href="/tarifs"
                className="link link-hover text-white hover:text-primary"
              >
                Tarifs
              </a>
              <a
                href="/login"
                className="link link-hover text-white hover:text-primary"
              >
                Se connecter
              </a>
              <a
                href="/register"
                className="link link-hover text-white hover:text-primary"
              >
                S'inscrire
              </a>
            </nav>
          </div>

          {/* Séparateur fin vertical entre les deux blocs de liens */}
          <div className="h-auto w-[1px] bg-white mx-3 lg:hidden"></div>

          <div className="flex-1 flex flex-col items-center">
            <nav className="flex flex-col gap-1 text-center text-sm lg:text-base">
              <a
                href="/mentions-legales"
                className="link link-hover text-white hover:text-primary"
              >
                Mentions légales
              </a>
              <a
                href="/politique-confidentialite"
                className="link link-hover text-white hover:text-primary"
              >
                Politique de confidentialité
              </a>
              <a
                href="/conditions-utilisation"
                className="link link-hover text-white hover:text-primary"
              >
                Conditions d’utilisation
              </a>
              <a
                href="/conditions-generales-de-vente"
                className="link link-hover text-white hover:text-primary"
              >
                Conditions générales de vente
              </a>
            </nav>
          </div>
        </div>
      </footer>

      <aside className="w-full h-15 bg-black text-white p-2 text-center text-sm">
        <p>
          Copyright © {new Date().getFullYear()} - Tous droits réservés -{" "}
          <a
            href="https://www.monappliclub.com"
            target="_blank"
            rel="noopener noreferrer"
            className="link link-hover text-white hover:text-primary"
          >
            MonAppliClub
          </a>{" "}
          -{" "}
          <a
            href="https://www.laurent-victorin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="link link-hover text-white hover:text-primary"
          >
            Laurent VICTORIN
          </a>
        </p>
      </aside>
    </div>
  );
};

export default Footer;
