import React, { useState } from "react";
import { addDesinscription } from "./../../services/desinscriptionServices"; // Assure-toi que le chemin est correct
import useScrollToTop from "../../hooks/useScrollToTop";

const Desinscription = () => {
  // Scroll to top screen
  useScrollToTop();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const desinscription =  await addDesinscription({ email });
    if (desinscription) {
      setMessage("Vous avez été désinscrit avec succès.");
    } else {
      setMessage("Erreur lors de la désinscription.");
    }
  };

  return (
    <div>
      <div>
        <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[80px] w-full"></div>
        <div className="flex flex-col w-full max-w-[1280px] mx-auto p-6 px-10 bg-gray-200 dark:bg-backgroundMainDark dark:text-white ">
          <h2 className="text-4xl font-bold uppercase text-center my-6 mb-10">
            Désinscription
          </h2>
      <p className="text-gray-600 mb-4">
        Entrez votre adresse email pour vous désinscrire de notre liste de diffusion.
      </p>
      <form onSubmit={handleSubmit} className="flex flex-col space-y-4 w-full">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Votre adresse email"
          className="p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
        <button
          type="submit"
          className="bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition"
        >
          Envoyer
        </button>
      </form>
      {message && <p className="mt-4 text-center text-green-600 font-bold">{message}</p>}
    </div>
  </div>
</div>
  );
};

export default Desinscription;
