import React, { useState } from "react";
import ComponentTitle from "../Home/ComponentTitle";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const images = [
  { src: "/images/diapo3/omnisports_001.webp", alt: "Accueil", caption: "Accueil" },
  { src: "/images/diapo3/omnisports_002.webp", alt: "Sections sportives", caption: "Sections sportives" },
  { src: "/images/diapo3/omnisports_003.webp", alt: "Sections sportives", caption: "Sections sportives" },
  { src: "/images/diapo3/omnisports_004.webp", alt: "ActualitésEvénements", caption: "Actualités-Evénements" },
  { src: "/images/diapo3/omnisports_005.webp", alt: "Formulaire de contact", caption: "Formulaire de contact" },
  { src: "/images/diapo3/omnisports_006.webp", alt: "Page Section Coordonnées", caption: "Page Section Coordonnées" },
  { src: "/images/diapo3/omnisports_007.webp", alt: "Page Section Palmarès, Album, Contact", caption: "Page Section Palmarès, Album, Contact" },
  { src: "/images/diapo3/omnisports_008.webp", alt: "Page Infrastructures", caption: "Page Infrastructures" },
  { src: "/images/diapo3/omnisports_009.webp", alt: "Page Contact et Responsables Sports", caption: "Page Contact et Responsables Sports" },
  { src: "/images/diapo3/omnisports_010.webp", alt: "Espace Admin", caption: "Espace Admin" },
  { src: "/images/diapo3/omnisports_011.webp", alt: "Espace Admin", caption: "Espace Admin" },
  
];

const DiaporamaOmnisports = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const { src, alt, caption } = images[currentImageIndex];

  return (
    <div>
      <div className="py-16 bg-base-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Quelques" highlight=" Pages" />
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Voici une sélection de quelques pages de l'application.
          </p>
        </div>

        {/* Carrousel */}
        <div className="relative flex flex-col items-center justify-center">
          {/* Bouton pour image précédente */}
          <button
            className="absolute left-4 bg-orange-400 dark:bg-orange-600 text-white p-2 rounded-full focus:outline-none"
            onClick={handlePrev}
          >
            <FaArrowLeft className="text-xl" />
          </button>

          {/* Conteneur d'image centré */}
          <div className="w-full max-w-4xl mx-auto flex justify-center">
            <img
              src={src}
              alt={alt}
              className="max-w-[500px] h-auto rounded-lg object-cover"
            />
          </div>

          {/* Légende sous l'image */}
          <p className="text-center text-gray-600 dark:text-gray-400 mt-4">
            {caption}
          </p>

          {/* Bouton pour image suivante */}
          <button
            className="absolute right-4 bg-orange-400 dark:bg-orange-600 text-white p-2 rounded-full focus:outline-none"
            onClick={handleNext}
          >
            <FaArrowRight className="text-xl" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DiaporamaOmnisports;
