import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text } from "recharts";
import axios from "axios";

// Liste des clubs avec leurs API
const clubs = [
  { name: 'MonAppliClub Démo', apiUrl: 'https://api.monappliclub-demo.com' },
  { name: 'MonAppliClub Light', apiUrl: 'https://api.monappliclub-demo-light.com/' },
  { name: 'USB Judo', apiUrl: 'https://api.usbouscat-judo.com' },
  { name: 'ASSM Judo', apiUrl: 'https://api.assm-judo.com/' },
  { name: 'USB Karaté', apiUrl: 'https://api.usbouscat-karate.com/' },
  { name: 'US Bouscat Omnisport', apiUrl: 'https://api.union-sportive-bouscataise.com/' },
  { name: 'Judo Club Salon-de-Provence', apiUrl: 'https://api.judoclubsalon.fr' },
  { name: 'CAL Judo', apiUrl: 'https://api.caljudo.com/' },
  { name: 'ESSHA Judo Segré', apiUrl: 'https://api.essha-judo-segre.com/' },
  { name: 'Dojo Gujanais', apiUrl: 'https://api.dojogujanais.fr/' },
  // Ajoutez les autres clubs ici
];

const VisitesDuJourBarChart = () => {
  const [data, setData] = useState([]);

  // Date d'aujourd'hui au format YYYY-MM-DD
  const today = new Date().toISOString().split('T')[0];

  // Fonction pour récupérer et filtrer les visites du jour pour chaque club
  const fetchTodayVisits = async () => {
    const visitesData = [];

    for (const club of clubs) {
      try {
        const response = await axios.get(`${club.apiUrl}/api/visites/countVisitesByDate`);
        // Filtrer les visites pour obtenir uniquement celles du jour
        const todayVisit = response.data.find((visit) => visit.date_visite === today);

        // Ajouter au tableau les visites pour chaque club
        visitesData.push({
          name: club.name,
          compteur: todayVisit ? todayVisit.compteur : 0, // Utilisation du champ compteur
        });
      } catch (error) {
        console.error(`Erreur lors de la récupération des données pour ${club.name}`, error);
        visitesData.push({ name: club.name, compteur: 0 });
      }
    }

    setData(visitesData);
  };

  // Appeler la fonction fetchTodayVisits une seule fois au chargement du composant
  useEffect(() => {
    fetchTodayVisits();
  }, []);

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-4 dark:bg-gray-900 dark:text-gray-200">
      <h3 className="text-lg font-bold text-white mb-4">Visites du jour par site</h3>

      <div style={{ position: "relative", zIndex: 0 }}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis
              type="category"
              dataKey="name"
              tick={{ fill: 'white', fontSize: 14 }} // Couleur des noms de sites en blanc
              width={150} // Ajuste la largeur pour laisser de l'espace aux noms de sites longs
            />
            <Tooltip />
            <Bar dataKey="compteur" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default VisitesDuJourBarChart;
