import React from "react";
import ComponentTitle from "./../Home/ComponentTitle";
import useScrollToTop from "../../hooks/useScrollToTop";

const AboutMonAppliClub = () => {
  useScrollToTop();

  const handleDemoClick = () => {
    window.open("https://www.monappliclub-demo.com", "_blank");
  };

  return (
    <section id="about" className="py-16 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        {/* Titre principal */}
        <div className="mx-auto max-w-screen-md text-center mb-12">
          <ComponentTitle title="Quelques" highlight=" Fonctionnalités" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Bloc Photo */}
          <div className="bg-slate-100 dark:bg-neutral-800 p-6 rounded-lg">
            <img
              src="/images/mobile_appli.webp"
              alt="illustration de l'application MonAppliClub"
              className="rounded-lg mx-auto w-full object-cover"
            />
          </div>

          {/* Bloc Adhérents */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-blue-200 dark:bg-blue-500 p-2 rounded-md shadow-md">🥋 Adhérents</h3>
            <ul className="list-none">
              <li className="mb-4">✔️ Inscription en ligne avec dépôt des documents</li>
              <li className="mb-4">✔️ Règlement en ligne des cotisations</li>
              <li className="mb-4">✔️ Accès à la boutique en ligne</li>
              <li className="mb-4">✔️ Calendrier des compétitions avec inscription</li>
              <li className="mb-4">✔️ Lexique de judo, techniques et arbitrage</li>
              <li className="mb-4">✔️ Actualités et galerie photo</li>
              <li className="mb-4">✔️ Messagerie et chat pour échanger avec le club</li>
            </ul>
          </div>

          {/* Bloc Enseignants */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-red-200 dark:bg-red-500 p-2 rounded-md shadow-md">🥋 Enseignants</h3>
            <ul className="list-none">
              <li className="mb-4">✔️ Accès au listing des adhérents</li>
              <li className="mb-4">✔️ Suivi de la présence des adhérents</li>
              <li className="mb-4">✔️ Saisie des passages de grades</li>
              <li className="mb-4">✔️ Gestion du calendrier des cours et événements</li>
              <li className="mb-4">✔️ Suivi des compétiteurs et palmarès</li>
            </ul>
          </div>

          {/* Bloc Bureau */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-green-200 dark:bg-green-500 p-2 rounded-md shadow-md">🥋 Le Bureau</h3>
            <ul className="list-none">
              <li className="mb-4">✔️ Statistiques sur les adhérents</li>
              <li className="mb-4">✔️ Gestion de la comptabilité du club</li>
              <li className="mb-4">✔️ Suivi des paiements et des dossiers d'inscription</li>
              <li className="mb-4">✔️ Gestion de la boutique en ligne et des stocks</li>
              <li className="mb-4">✔️ Suivi des heures des enseignants pour les cours et coaching</li>
              <li className="mb-4">✔️ Personnalisation de la page d'accueil du club</li>
            </ul>
          </div>
        </div>

        {/* Bouton de démo */}
        <div className="mt-8 text-center mx-auto">
          <button
            onClick={handleDemoClick}
            className="px-8 py-3 bg-gradient-to-r from-yellow-400 to-orange-600 text-white rounded-full transition relative group w-96"
          >
            <span className="group-hover:opacity-0 transition-opacity duration-300">
              Tester l'Application
            </span>
            <span className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 font-passionOne text-3xl font-light">
              MonAppliClub Démo
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutMonAppliClub;
