import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const token = localStorage.getItem("token");

/// POST
// Fonction pour le mot de passe oublié
export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${apiUrl}/password/forgot-password`, {
      email,
    });
    if (response.status === 200) {
      return { success: true, message: response.data.message };
    } else {
      return {
        success: false,
        message: "Erreur inattendue lors de la demande.",
      };
    }
  } catch (error) {
    return {
      success: false,
      message: error.response
        ? error.response.data.message
        : "Erreur lors de la connexion au serveur.",
    };
  }
};

// Vérifie la validité du token avant de réinitialiser le mot de passe
export const checkResetPasswordToken = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/password/check-token/${token}`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la vérification du token de réinitialisation:",
      error
    );
    return false;
  }
};

// Réinitialise le mot de passe
export const resetPassword = async (token, password) => {
  try {
    console.log(
      "Sending reset request with token:",
      token,
      "and password:",
      password
    ); // Ajoutez ce log pour le débogage
    const response = await axios.post(
      `${apiUrl}/password/reset-password/${token}`,
      {
        password,
      }
    );
    console.log("Response:", response); // Log de la réponse
    return {
      success: true,
      message: "Votre mot de passe a été réinitialisé avec succès.",
    };
  } catch (error) {
    console.error("Erreur lors de la réinitialisation du mot de passe:", error);
    return {
      success: false,
      message: error.response
        ? error.response.data
        : "Erreur lors de la connexion au serveur.",
    };
  }
};
