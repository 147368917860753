import React, { useState } from "react";
import { saveMessageContactForm } from "./../../services/contactformServices"; // Importation de la fonction de sauvegarde
import Button from "./../../components/Button/Button";
import { FaMailBulk } from "react-icons/fa";
import ComponentTitle from "./ComponentTitle";

const FormContact = () => {
  const [notificationSent, setNotificationSent] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, email, message } = formData;
    const notificationMessage = `Formulaire de Contact : ${name}, ${email}, message => ${message}`;

    try {
      // Sauvegarder le message dans la base de données
      await saveMessageContactForm({ name, email, message });

      console.log(notificationMessage);
      setNotificationSent(true);
      setFormData({ name: "", email: "", message: "" }); // Réinitialiser le formulaire après l'envoi
    } catch (error) {
      console.error(
        "Erreur lors de l'envoi de la notification ou de la sauvegarde du message",
        error
      );
    }
  };

  return (
    <section className="py-8 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Contactez" highlight=" Moi" />
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Vous souhaitez plus d'informations ? N'hésitez pas à me contacter en remplissant le formulaire ci-dessous.
          </p>
        </div>

        <form className="space-y-8" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Votre email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              placeholder="mail@gmail.com"
              required
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Nom Prénom
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              placeholder="Votre Nom Prénom"
              required
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Votre Message (Merci de préciser votre demande, votre numéro de téléphone si vous souhaitez être rappelé et votre club)
            </label>
            <textarea
              id="message"
              name="message"
              rows="6"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Laissez votre message ici..."
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="flex items-center justify-center gap-2">
            <Button
              text="Envoyer"
              icon={FaMailBulk}
              hoverLabel="Envoyer le message"
              type="submit"
              className="gap-2"
            />
          </div>
          {notificationSent && (
            <p className="text-blue-500 text-center">
              Votre message a bien été envoyé!
            </p>
          )}
        </form>
      </div>
    </section>
  );
};

export default FormContact;
