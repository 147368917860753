import React, { useState, useEffect } from "react";
import {
  deleteMessageContactForm,
  markMessageContactForm,
  getAllMessagesContactForm,
} from "../../../../services/contactformServices";
import { useContactForm } from "../../../../contexts/ContactFormContext";
import { MdMessage } from "react-icons/md";
import ButtonEyeNoRead from "../../../../components/Button/ButtonEyeNoRead";
import ButtonEyeRead from "../../../../components/Button/ButtonEyeRead";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import "./styles.css";

const ContactFormDisplay = ({ userId }) => {
  const [messages, setMessages] = useState([]);
  const { unreadCountContactForm, setUnreadCountContactForm } =
    useContactForm();

  const loadMessages = async () => {
    if (!userId) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      return;
    }

    try {
      const data = await getAllMessagesContactForm(userId);
      setMessages(data);
    } catch (error) {
      console.error("Erreur lors du chargement des messages:", error);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessageContactForm(messageId);
      loadMessages(); // Recharger les messages après la suppression
    } catch (error) {
      console.error("Erreur lors de la suppression du message:", error);
    }
  };

  const toggleReadStatus = async (messageId) => {
    try {
      const message = messages.find((m) => m.id === messageId);
      if (!message) {
        console.error("Message non trouvé");
        return;
      }

      // Inverser l'état de lecture dans la base de données
      await markMessageContactForm(messageId);

      // Mettre à jour l'état local des messages
      setMessages((prevMessages) =>
        prevMessages.map((m) =>
          m.id === messageId ? { ...m, read_message: !m.read_message } : m
        )
      );

      // Mettre à jour le compteur de messages non lus
      setUnreadCountContactForm((prevCount) =>
        message.read_message ? prevCount + 1 : prevCount - 1
      );
    } catch (error) {
      console.error(
        "Erreur lors du basculement de l'état de lecture du message:",
        error
      );
    }
  };

  useEffect(() => {
    loadMessages();
  }, [userId]);

  return (
    <div className="mt-5 container mx-auto p-4 bg-gray-600 text-gray-200 border border-black">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <MdMessage className="mr-2" />
          Messages du Formulaire
          {unreadCountContactForm > 0 && (
            <span className="ml-2 bg-primary text-white text-xs rounded-full px-2 py-0.5">
              {unreadCountContactForm}
            </span>
          )}
        </h3>
      </div>
      {messages.length > 0 ? (
        <>
          {/* Tableau pour mode desktop */}
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-gray-700 divide-y divide-gray-600">
              <thead>
                <tr className="bg-gray-800">
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Détails
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-right text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-600">
                {messages.map((message) => (
                  <tr
                    key={message.id}
                    className={`${
                      message.read_message
                        ? "bg-gray-600"
                        : "bg-gray-500 text-gray-800"
                    } hover:bg-gray-500`}
                  >
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-200">
                      {new Date(message.created_at).toLocaleDateString(
                        "fr-FR",
                        {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                        }
                      )}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-200 max-w-xs overflow-hidden text-ellipsis">
                      <div className="font-semibold">{message.name}</div>
                      <div className="text-blue-400 font-bold italic">
                        {message.email}
                      </div>
                      <div className="text-gray-300">{message.message}</div>
                    </td>
                    <td className="px-4 py-2 text-right text-sm font-medium gap-2 flex">
                      {message.read_message ? (
                        <ButtonEyeRead
                          onClick={() => toggleReadStatus(message.id)}
                          hoverLabel="Marquer comme non lu"
                          className="bg-gray-700 text-gray-200"
                        />
                      ) : (
                        <ButtonEyeNoRead
                          onClick={() => toggleReadStatus(message.id)}
                          hoverLabel="Marquer comme lu"
                          className="bg-gray-700 text-gray-200"
                        />
                      )}
                      <ButtonDelete
                        onClick={() => handleDeleteMessage(message.id)}
                        hoverLabel="Supprimer"
                        className="bg-gray-700 text-gray-200"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cartes pour mode mobile */}
          <div className="block sm:hidden">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`shadow-md rounded-lg p-4 mb-4 hover:bg-gray-500 ${
                  message.read_message ? "bg-gray-600" : "bg-gray-500"
                }`}
              >
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-semibold text-gray-200">
                    {new Date(message.created_at).toLocaleDateString("fr-FR", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </span>
                  <div className="flex space-x-2">
                    {message.read_message ? (
                      <ButtonEyeRead
                        onClick={() => toggleReadStatus(message.id)}
                        hoverLabel="Marquer comme non lu"
                        className="bg-gray-700 text-gray-200"
                      />
                    ) : (
                      <ButtonEyeNoRead
                        onClick={() => toggleReadStatus(message.id)}
                        hoverLabel="Marquer comme lu"
                        className="bg-gray-700 text-gray-200"
                      />
                    )}
                    <ButtonDelete
                      onClick={() => handleDeleteMessage(message.id)}
                      hoverLabel="Supprimer"
                      className="bg-gray-700 text-gray-200"
                    />
                  </div>
                </div>
                <div className="text-sm text-gray-300">
                  <div className="font-semibold">{message.name}</div>
                  <div className="text-blue-400 font-bold italic">
                    {message.email}
                  </div>
                  <div className="text-gray-300">{message.message}</div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center py-4 bg-gray-600 text-gray-200">
          Aucuns messages.
        </div>
      )}
    </div>
  );
};

export default ContactFormDisplay;
