import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/partenaireskata`;
const token = localStorage.getItem("token");

/// GET
// Route pour récupérer tous les partenaires
export const getAllPartenaires = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allpartenaires`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des partenaires:", error);
    return [];
  }
};

// Route pour récupérer un partenaire par son id
export const getPartenaireById = async (partenaireId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/partenaireById/${partenaireId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du partenaire:", error);
    return null;
  }
};

// Route pour récupérer les partenaires par département
export const getPartenaireByDepartement = async (departement) => {
  try {
    const response = await axios.get(
      `${apiUrl}/partenaireByDepartement/${departement}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du partenaire:", error);
    return null;
  }
};

// Route pour récupérer les partenaires par Région
export const getPartenaireByRegion = async (region) => {
  try {
    const response = await axios.get(`${apiUrl}/partenaireByRegion/${region}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du partenaire:", error);
    return null;
  }
};


/// POST
// Route pour ajouter un partenaire
export const createPartenaire = async (partenaire) => {
  try {
    const response = await axios.post(`${apiUrl}/addPartenaire`, partenaire, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout du partenaire:", error);
    return null;
  }
};

// PATCH
// Route pour modifier un partenaire
export const updatePartenaire = async (id, partenaireData) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updatePartenaire/${id}`, // L'ID est bien utilisé ici
      partenaireData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du partenaire:", error);
    return null;
  }
};

// DELETE
// Route pour supprimer un partenaire par son id
export const deletePartenaire = async (partenaireId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deletePartenaire/${partenaireId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du partenaire:", error);
    return null;
  }
};
