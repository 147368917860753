import React, { useState, useEffect } from "react";
import { getAllDesinscriptions, deleteDesinscriptionById } from "./../../../../services/desinscriptionServices";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import { ToastContainer, toast } from "react-toastify";
import useScrollToTop from "./../../../../hooks/useScrollToTop";

const DesinscriptionsPage = () => {
  useScrollToTop();
  const [desinscriptions, setDesinscriptions] = useState([]);

  // Fonction pour récupérer les désinscriptions
  const fetchDesinscriptions = async () => {
    try {
      const data = await getAllDesinscriptions();
      setDesinscriptions(data.sort((a, b) => new Date(b.date_desinscription) - new Date(a.date_desinscription)));
    } catch (error) {
      console.error("Erreur lors du chargement des désinscriptions:", error);
      toast.error("Erreur lors du chargement des désinscriptions");
    }
  };

  // Récupération des désinscriptions lors du chargement de la page
  useEffect(() => {
    fetchDesinscriptions();
  }, []);

  // Fonction pour supprimer une désinscription
  const deleteDesinscription = async (desinscriptionId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette désinscription ?")) {
      try {
        await deleteDesinscriptionById(desinscriptionId);
        toast.success("Désinscription supprimée avec succès");
        setDesinscriptions(desinscriptions.filter(d => d.id !== desinscriptionId));
      } catch (error) {
        console.error("Erreur lors de la suppression de la désinscription:", error);
        toast.error("Erreur lors de la suppression de la désinscription");
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-light tracking-wide text-gray-800 dark:text-white mb-4">
        Liste des Désinscriptions
      </h1>

      <div className="overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
        <table className="w-full text-left text-sm text-gray-400">
          <thead className="border-b border-gray-700">
            <tr>
              <th className="pb-3 px-4 text-gray-400">Email</th>
              <th className="pb-3 px-4 text-gray-400">Date de Désinscription</th>
              <th className="pb-3 px-4 text-gray-400">Actions</th>
            </tr>
          </thead>
          <tbody>
            {desinscriptions.map((desinscription) => (
              <tr key={desinscription.id} className="border-b border-gray-700 text-white">
                <td className="py-2 px-4 text-white">{desinscription.email}</td>
                <td className="py-2 px-4 text-white">
                  {new Date(desinscription.date_desinscription).toLocaleString()}
                </td>
                <td className="py-2 px-4">
                  <ButtonDelete
                    onClick={() => deleteDesinscription(desinscription.id)}
                    hoverLabel="Supprimer"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DesinscriptionsPage;
