import React, { useState } from 'react';
import {
  FaUsers, FaCalendarAlt, FaEnvelope, FaMoneyBillWave, FaUserCheck,
  FaChartBar, FaAddressCard, FaCartPlus, FaRegCreditCard, FaUserCircle,
  FaFileSignature, FaRegNewspaper, FaImage, FaComment, FaHourglassHalf, FaCalculator, FaShoppingBag    
} from "react-icons/fa";
import { IoMdContacts, IoMdSettings } from "react-icons/io";
import { GiBlackBelt, GiKimono, GiPodiumWinner, GiPodium, GiGong } from "react-icons/gi";
import { MdDashboard, MdTimer, MdOutlineInventory   } from "react-icons/md";
import { PiTranslateFill } from "react-icons/pi";
import { LuListTodo } from "react-icons/lu";
import ComponentTitle from "./../Home/ComponentTitle";

const linkIcon = {
  "Tableau de Bord": <MdDashboard />,
  "Dossier d'Inscription": <FaAddressCard />,
  "Fiche Adhérent": <FaUserCircle />,
  "Calendrier": <FaCalendarAlt />,
  "Messages": <FaEnvelope />,
  "Chat": <FaComment />,
  "Boutique": <FaCartPlus />,
  "Commandes & Règlements": <FaRegCreditCard />,
  "Techniques Judo": <GiKimono />,
  "Actualités": <FaRegNewspaper  />,
  "Lexique": <PiTranslateFill  />,
  "Arbitrage Judo": <GiGong  />,
  "Galerie Photos": <FaImage  />,
  "Listing Adhérents": <IoMdContacts />,
  "Passage Ceinture": <GiBlackBelt />,
  "Grade CN": <GiBlackBelt />,
  "Suivi Compétitions": <GiPodiumWinner />,
  "Palmarès": <GiPodium />,
  "ToDoList": <LuListTodo  />,
  "Événements": <FaCalendarAlt />,
  "Appel Présence": <FaUserCheck />,
  "Dashboard Adhérents": <FaChartBar />,
  "Dashboard Comptabilité": <FaChartBar />,
  "Détails Adhérent": <FaUserCircle />,
  "Listing Utilisateurs": <FaUsers />,
  "Listing Adhérents Admin": <FaUsers />,
  "Règlements Inscriptions": <FaMoneyBillWave />,
  "Dossiers d'Inscription": <FaFileSignature />,
  "Achats Boutique": <FaCartPlus />,
  "Articles Admin": <FaShoppingBag  />,
  "Dépenses Recettes": <FaCalculator />,
  "Coachings": <MdTimer />,
  "Heures Enseignement": <FaHourglassHalf  />,
  "Inventaire": <MdOutlineInventory  />,
  "Chat Page Admin": <FaComment />,
  "Message Admin": <FaEnvelope />,
  "Paramètres": <IoMdSettings />,
};

const linkDescriptions = {
  "Tableau de Bord": "Vue d'ensemble de vos activités et informations clés.",
  "Dossier d'Inscription": "Gérez vos informations d'inscription et documents.",
  "Fiche Adhérent": "Accédez à votre profil personnel et informations.",
  "Calendrier": "Consultez les événements et possibilité de s'y inscrire.",
  "Messages": "Envoyez et recevez des messages.",
  "Chat": "Communiquez en direct avec votre groupe.",
  "Boutique": "Accédez à la boutique en ligne du club.",
  "Commandes & Règlements": "Suivez vos achats et effectuez des paiements.",
  "Techniques Judo": "Explorez les techniques de judo.",
  "Actualités": "Restez informé des dernières nouvelles.",
  "Lexique": "Découvrez les termes du judo en français et japonais.",
  "Arbitrage Judo": "Apprenez les règles d'arbitrage.",
  "Galerie Photos": "Consultez les photos des événements passés.",
  "Listing Adhérents": "Accédez à la liste des adhérents du club.",
  "Passage Ceinture": "Suivez les progressions de ceinture.",
  "Grade CN": "Gérez les grades CN des adhérents.",
  "Suivi Compétitions": "Suivez la performance des adhérents en compétition.",
  "Palmarès": "Affichez les palmarès des compétitions.",
  "ToDoList": "Gérez votre liste de tâches.",
  "Événements": "Planifiez et suivez les événements du club.",
  "Appel Présence": "Faites l'appel des adhérents présents.",
  "Dashboard Adhérents": "Vue d'ensemble des adhérents.",
  "Dashboard Comptabilité": "Vue d'ensemble des règlements.",
  "Détails Adhérent": "Accédez aux informations détaillées d'un adhérent.",
  "Listing Utilisateurs": "Gérez tous les utilisateurs de l'application.",
  "Listing Adhérents Admin": "Accédez à la liste complète des adhérents.",
  "Règlements Inscriptions": "Gérez les paiements des inscriptions.",
  "Dossiers d'Inscription": "Suivez les dossiers d'inscription.",
  "Achats Boutique": "Gérez les achats effectués via la boutique.",
  "Articles Admin": "Gérez les articles de la boutique en ligne.",
  "Dépenses Recettes": "Suivez les dépenses et recettes du club.",
  "Coachings": "Gérez les sessions de coaching.",
  "Heures Enseignement": "Enregistrez les heures de présence des enseignants.",
  "Inventaire": "Gérez l'inventaire des équipements.",
  "Chat Page Admin": "Suivez les discussions en tant qu'administrateur.",
  "Message Admin": "Gérez les messages.",
  "Paramètres": "Personnalisez les paramètres du club et du site.",
};

const Features = () => {
  const [activeRole, setActiveRole] = useState("Adhérent");

  const sections = {
    Adhérent: [
      "Tableau de Bord", "Dossier d'Inscription", "Fiche Adhérent", "Calendrier", "Messages", "Chat",
      "Boutique", "Commandes & Règlements", "Actualités", "Galerie Photos", "Lexique", "Arbitrage Judo", "Techniques Judo"
    ],
    Enseignant: [
      "Listing Adhérents", "Appel Présence", "Passage Ceinture", "Grade CN", "Événements", "Suivi Compétitions",
      "Palmarès", "ToDoList"
    ],
    Dirigeant: [
      "Dashboard Adhérents", "Dashboard Comptabilité", "Détails Adhérent", "Listing Utilisateurs",
      "Listing Adhérents Admin", "Règlements Inscriptions", "Dossiers d'Inscription", "Achats Boutique",
      "Articles Admin", "Dépenses Recettes", "Coachings", "Heures Enseignement", "Inventaire",
      "Chat Page Admin", "Message Admin", "Paramètres"
    ],
  };

  const getCardBackgroundColor = (sectionLabel) => {
    if (sectionLabel === "Enseignant") return "bg-white dark:bg-neutral-800 hover:bg-blue-100 dark:hover:bg-blue-500";
    if (sectionLabel === "Dirigeant") return "bg-white dark:bg-neutral-800 hover:bg-green-100 dark:hover:bg-green-500";
    return "bg-white dark:bg-neutral-800 hover:bg-gray-100 dark:hover:bg-gray-500";
  };

  const getBubbleStyle = (role) => {
    const activeStyle = "bg-gradient-to-br from-blue-600 to-blue-400 text-white";
    const inactiveStyle = "bg-gradient-to-br from-gray-400 to-gray-200 text-gray-700 dark:from-gray-600 dark:to-gray-400 dark:text-gray-200";
    return activeRole === role ? activeStyle : inactiveStyle;
  };

  return (
    <section id="features" className="py-8 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <ComponentTitle title="Les" highlight=" Sections" />
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Découvrez les fonctionnalités de MonAppliClub selon votre rôle.</p>
        </div>
        <div className="flex justify-center space-x-4 mb-12">
          {Object.keys(sections).map((role, idx) => (
            <button
              key={idx}
              onClick={() => setActiveRole(role)}
              className={`px-6 py-2 rounded-full shadow-lg text-lg font-semibold transition-all duration-300 ${getBubbleStyle(role)}`}
            >
              Pour les {role}s
            </button>
          ))}
        </div>
        <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 cursor-pointer">
          {sections[activeRole].map((link, linkIdx) => (
            <li key={linkIdx} className="text-center relative group">
              <div
                className={`block relative overflow-hidden rounded-lg p-4 shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 ${getCardBackgroundColor(activeRole)}`}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="text-3xl text-primary my-3">{linkIcon[link]}</div>
                  <span className="block text-sm font-semibold text-gray-800 dark:text-white">
                    {link}
                  </span>
                </div>
                {/* Texte explicatif au survol */}
                <div className="absolute inset-0 bg-[#F27131] bg-opacity-100 text-white flex items-center justify-center text-center p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <p className="text-xs sm:text-sm">{linkDescriptions[link]}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Features;
