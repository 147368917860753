import React, { useEffect, useState } from "react";
import NotificationsDisplay from "./NotificationsDisplay";
import { useUser } from "./../../../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../../../utils/roleHelper";
import useScrollToTop from "./../../../../hooks/useScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import ComponentTitle from "./../../../Home/ComponentTitle";

const Notifications = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [userId, setUserId] = useState(null);

  const getUserIdFromLocalStorage = () => {
    try {
      const userData = localStorage.getItem("userInfo");
      if (userData) {
        const userObj = JSON.parse(userData);
        return userObj.userId;
      }
    } catch (error) {
      console.error(
        "Erreur lors de la lecture des données de l'utilisateur du localStorage:",
        error
      );
      toast.error("Erreur lors de la lecture des données de l'utilisateur.");
      return null;
    }
  };

  useEffect(() => {
    const id = getUserIdFromLocalStorage();
    if (!id) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      toast.error("L'ID de l'utilisateur n'est pas trouvé.");
    } else {
      setUserId(id);
    }
  }, []);

  if (!userId) {
    return (
      <div className="text-center">
        <h2>Vous devez être connecté pour accéder à cette page.</h2>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-8 bg-slate-200 rounded-lg p-2">
        <ComponentTitle title="Mes" highlight=" Notifications" />
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Bienvenue dans votre espace de notifications.
        </p>
      </div>

      <div className="mx-auto max-w-screen-md mb-8 lg:mb-12">
        <div className="w-full max-w-4xl px-2 mb-4">
          <NotificationsDisplay userId={userId} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Notifications;
