import React, { useState, useEffect } from "react";
import Footer from "./../Home/Footer";
import useScrollToTop from "../../hooks/useScrollToTop";
import AuthIcons from "../../hooks/AuthIcons";
import { useUser } from "../../contexts/UserContext";
import Pricing from "./Pricing";
import Pricing2 from "./Pricing2";
import FormContact from "./../Home/FormContact";
import { FaPhoneAlt } from "react-icons/fa";
import Parrainage from "./Parrainage";

const TarifsPage = () => {
  useScrollToTop();

  const { user } = useUser();

  return (
    <div className="flex flex-col min-h-screen">
      {!user && <AuthIcons />}
      <div className="flex-grow">
        {/* Bloc supérieur de 70px avec dégradé */}
        <div className="w-full h-[70px] bg-gradient-to-b from-neutral-800 to-neutral-500"></div>

        {/* Section Tarifs */}
        <div
          id="tarifs"
          className="py-4 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200"
        >
          <div className="container mx-auto px-4">
            <Pricing />
            <Pricing2 />
            <Parrainage />
          </div>
        </div>

        {/* Section de contact avec téléphone et formulaire */}
      
        <div id="contact" className="bg-gradient-to-r from-yellow-400 to-orange-600 text-white text-center py-8">
          <h2 className="text-2xl font-bold">Je souhaite être recontacté</h2>
          <p className="mt-4">
            Vous souhaitez en savoir plus sur nos tarifs et nos solutions ?
            <br />
            Remplissez le formulaire ci-dessous ou appelez-nous directement.
          </p>

          <div className="mt-6 flex justify-center items-center">
            <a
              href="tel:0680727707"
              className="flex items-center bg-white text-black px-4 py-2 rounded-full transition hover:bg-gray-300"
            >
              <FaPhoneAlt className="mr-2" /> Appeler maintenant
            </a>
            <span className="ml-4 text-3xl font-bold">06.80.72.77.07</span>
          </div>
        </div>

        {/* Formulaire de contact */}
        <FormContact />
      </div>

      <Footer />
    </div>
  );
};

export default TarifsPage;
