import React, { useState, useEffect } from "react";
import { FaPlus, FaUndo } from "react-icons/fa";
import Button from "../../../../components/Button/Button";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import { getAllDepensesPro, deleteDepensePro } from "../../../../services/depensesproServices";
import AddDepenseProModal from "./AddDepenseProModal";
import EditDepenseProModal from "./EditDepenseProModal";
import { ToastContainer, toast } from "react-toastify";

const ListingDepensesPro = () => {
  const [depenses, setDepenses] = useState([]);
  const [displayedDepenses, setDisplayedDepenses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterRecipient, setFilterRecipient] = useState("");
  const [filterAssociation, setFilterAssociation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editDepenseId, setEditDepenseId] = useState(null);

  const fetchData = async () => {
    try {
      const depensesData = await getAllDepensesPro();
      setDepenses(depensesData || []);
      setDisplayedDepenses(depensesData || []);
    } catch (error) {
      console.error("Erreur lors du chargement des dépenses:", error);
      toast.error("Erreur lors du chargement des dépenses");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterDepenses(searchTermLocal, filterCategory, filterRecipient, filterAssociation, startDate, endDate);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setFilterCategory(category);
    filterDepenses(searchTerm, category, filterRecipient, filterAssociation, startDate, endDate);
  };

  const handleRecipientChange = (e) => {
    const recipient = e.target.value;
    setFilterRecipient(recipient);
    filterDepenses(searchTerm, filterCategory, recipient, filterAssociation, startDate, endDate);
  };

  const handleAssociationChange = (e) => {
    const association = e.target.value;
    setFilterAssociation(association);
    filterDepenses(searchTerm, filterCategory, filterRecipient, association, startDate, endDate);
  };

  const handleStartDateChange = (e) => {
    const start = e.target.value;
    setStartDate(start);
    filterDepenses(searchTerm, filterCategory, filterRecipient, filterAssociation, start, endDate);
  };

  const handleEndDateChange = (e) => {
    const end = e.target.value;
    setEndDate(end);
    filterDepenses(searchTerm, filterCategory, filterRecipient, filterAssociation, startDate, end);
  };

  const filterDepenses = (term, category, recipient, association, start, end) => {
    const filteredDepenses = depenses.filter((depense) => {
      const isMatchTerm = depense.nom_depense.toLowerCase().includes(term.toLowerCase()) ||
                          depense.destinataire_depense.toLowerCase().includes(term.toLowerCase()) ||
                          depense.categorie_depense.toLowerCase().includes(term.toLowerCase());
      const isMatchCategory = category ? depense.categorie_depense === category : true;
      const isMatchRecipient = recipient ? depense.destinataire_depense === recipient : true;
      const isMatchAssociation = association ? depense.asso_id === parseInt(association) : true;
      const isMatchDateRange = start && end ? new Date(depense.date_depense) >= new Date(start) && new Date(depense.date_depense) <= new Date(end) : true;

      return isMatchTerm && isMatchCategory && isMatchRecipient && isMatchAssociation && isMatchDateRange;
    });
    setDisplayedDepenses(filteredDepenses);
  };

  const resetFilters = () => {
    setSearchTerm("");
    setFilterCategory("");
    setFilterRecipient("");
    setFilterAssociation("");
    setStartDate("");
    setEndDate("");
    setDisplayedDepenses(depenses);
  };

  const deleteDepense = async (depenseId) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer cette dépense ? ID: ${depenseId}`)) {
      try {
        const message = await deleteDepensePro(depenseId);
        toast.success(message);
        fetchData(); // Rafraîchir la liste après suppression
      } catch (error) {
        console.error("Erreur lors de la suppression de la dépense:", error);
        toast.error("Erreur lors de la suppression de la dépense");
      }
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openEditModal = (depenseId) => {
    setEditDepenseId(depenseId);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  // Calcul du montant total des dépenses affichées
  const totalAmount = displayedDepenses.reduce((sum, depense) => sum + depense.montant_depense, 0).toFixed(2);

  // Extraction des associations uniques, triées par ordre alphabétique
  const uniqueAssociations = depenses
  .map(dep => ({ id: dep.asso_id, name: dep.association.nom_asso }))
  .filter((value, index, self) => self.findIndex(a => a.id === value.id) === index)
  .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-2 dark:text-white">
      <h2 className="text-2xl font-bold tracking-widest text-gray-200 text-center mb-6 uppercase">
        Liste des Dépenses
      </h2>

      <div className="w-full mx-auto mb-6 p-6 rounded-lg">
        <Button
          text="Ajouter Dépense"
          onClick={openAddModal}
          icon={FaPlus}
          className="w-full gap-2 mb-4 bg-gray-700 text-white hover:bg-gray-600"
        />

        <input
          className="input input-bordered mb-4 w-full bg-gray-700 text-white placeholder-gray-400 border-none focus:ring-2 focus:ring-gray-600 text-sm"
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <select
            className="input input-bordered bg-gray-700 text-white placeholder-gray-400 border-none focus:ring-2 focus:ring-gray-600 text-sm"
            value={filterCategory}
            onChange={handleCategoryChange}
          >
            <option value="">Filtrer par catégorie</option>
            {[...new Set(depenses.map(dep => dep.categorie_depense))].map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>

          <select
            className="input input-bordered bg-gray-700 text-white placeholder-gray-400 border-none focus:ring-2 focus:ring-gray-600 text-sm"
            value={filterRecipient}
            onChange={handleRecipientChange}
          >
            <option value="">Filtrer par destinataire</option>
            {[...new Set(depenses.map(dep => dep.destinataire_depense))].map((recipient) => (
              <option key={recipient} value={recipient}>{recipient}</option>
            ))}
          </select>

          <select
              className="input input-bordered bg-gray-700 text-white placeholder-gray-400 border-none focus:ring-2 focus:ring-gray-600 text-sm"
              value={filterAssociation}
              onChange={handleAssociationChange}
            >
              <option value="">Filtrer par association</option>
              {uniqueAssociations.map((asso) => (
                <option key={asso.id} value={asso.id}>{asso.name}</option>
              ))}
            </select>

          <input
            type="date"
            className="input input-bordered bg-gray-700 text-white placeholder-gray-400 border-none focus:ring-2 focus:ring-gray-600 text-sm"
            value={startDate}
            onChange={handleStartDateChange}
          />

          <input
            type="date"
            className="input input-bordered bg-gray-700 text-white placeholder-gray-400 border-none focus:ring-2 focus:ring-gray-600 text-sm"
            value={endDate}
            onChange={handleEndDateChange}
          />

        </div>

        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md text-center w-full md:w-auto mb-4 md:mb-0">
            <h3 className="text-lg font-semibold">Nombre de dépenses affichées</h3>
            <p className="text-3xl font-bold">{displayedDepenses.length}</p>
          </div>

          <button
            onClick={resetFilters}
            className="flex items-center justify-center bg-red-600 text-white font-bold px-4 py-2 rounded-md hover:bg-red-700 transition-colors duration-200"
          >
            <FaUndo className="mr-2" /> Reset
          </button>

          <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md text-center w-full md:w-auto">
            <h3 className="text-lg font-semibold">Montant total des dépenses affichées</h3>
            <p className="text-3xl font-bold">{totalAmount} €</p>
          </div>
        </div>

        <div className="overflow-x-auto shadow-xl">
          <table className="table-auto w-full bg-gray-900 text-white text-sm font-thin rounded-lg">
            <thead className="bg-gray-700 text-gray-300">
              <tr>
                <th className="px-5 py-3 text-left uppercase tracking-wider border-b border-white">
                  Nom
                </th>
                <th className="px-5 py-3 text-left uppercase tracking-wider border-b border-white">
                  Destinataire
                </th>
                <th className="px-5 py-3 text-left uppercase tracking-wider border-b border-white">
                  Date
                </th>
                <th className="px-5 py-3 text-left uppercase tracking-wider border-b border-white">
                  Montant
                </th>
                <th className="px-5 py-3 text-left uppercase tracking-wider border-b border-white">
                  Catégorie
                </th>
                <th className="px-5 py-3 text-left uppercase tracking-wider border-b border-white">
                  Association
                </th>
                <th className="px-5 py-3 text-left uppercase tracking-wider border-b border-white">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {displayedDepenses.map((depense) => (
                <tr key={depense.id} className="bg-gray-900 text-gray-300 hover:bg-gray-700 border-b border-white">
                  <td className="px-4 py-2">
                    {depense.nom_depense}
                  </td>
                  <td className="px-4 py-2">
                    {depense.destinataire_depense}
                  </td>
                  <td className="px-4 py-2">
                    {new Date(depense.date_depense).toLocaleDateString("fr-FR")}
                  </td>
                  <td className="px-4 py-2">
                    {depense.montant_depense.toFixed(2)} €
                  </td>
                  <td className="px-4 py-2">
                    {depense.categorie_depense}
                  </td>
                  <td className="px-4 py-2">
                    {depense.association.nom_asso}
                  </td>
                  <td className="flex items-center gap-2 px-4 py-2">
                    <ButtonEdit
                      onClick={() => openEditModal(depense.id)}
                      hoverLabel="Éditer"
                      className="text-white hover:text-gray-300"
                    />
                    <ButtonDelete
                      onClick={() => deleteDepense(depense.id)}
                      hoverLabel="Supprimer"
                      className="text-white hover:text-gray-300"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modales d'ajout et d'édition de dépense */}
      <AddDepenseProModal
        isOpen={showAddModal}
        onClose={closeAddModal}
        refreshList={fetchData}
      />
      {editDepenseId && (
        <EditDepenseProModal
          isOpen={showEditModal}
          onClose={closeEditModal}
          depenseId={editDepenseId}
          refreshList={fetchData}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default ListingDepensesPro;
