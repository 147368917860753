// données.js
export const departementsEtRegions = [
  { code: "01", departement: "Ain", region: "Auvergne-Rhône-Alpes" },
  { code: "02", departement: "Aisne", region: "Hauts-de-France" },
  { code: "03", departement: "Allier", region: "Auvergne-Rhône-Alpes" },
  { code: "04", departement: "Alpes-de-Haute-Provence", region: "Provence-Alpes-Côte d'Azur" },
  { code: "05", departement: "Hautes-Alpes", region: "Provence-Alpes-Côte d'Azur" },
  { code: "06", departement: "Alpes-Maritimes", region: "Provence-Alpes-Côte d'Azur" },
  { code: "07", departement: "Ardèche", region: "Auvergne-Rhône-Alpes" },
  { code: "08", departement: "Ardennes", region: "Grand Est" },
  { code: "09", departement: "Ariège", region: "Occitanie" },
  { code: "10", departement: "Aube", region: "Grand Est" },
  { code: "11", departement: "Aude", region: "Occitanie" },
  { code: "12", departement: "Aveyron", region: "Occitanie" },
  { code: "13", departement: "Bouches-du-Rhône", region: "Provence-Alpes-Côte d'Azur" },
  { code: "14", departement: "Calvados", region: "Normandie" },
  { code: "15", departement: "Cantal", region: "Auvergne-Rhône-Alpes" },
  { code: "16", departement: "Charente", region: "Nouvelle-Aquitaine" },
  { code: "17", departement: "Charente-Maritime", region: "Nouvelle-Aquitaine" },
  { code: "18", departement: "Cher", region: "Centre-Val de Loire" },
  { code: "19", departement: "Corrèze", region: "Nouvelle-Aquitaine" },
  { code: "20", departement: "Corse", region: "Corse" },
  { code: "21", departement: "Côte-d'Or", region: "Bourgogne-Franche-Comté" },
  { code: "22", departement: "Côtes-d'Armor", region: "Bretagne" },
  { code: "23", departement: "Creuse", region: "Nouvelle-Aquitaine" },
  { code: "24", departement: "Dordogne", region: "Nouvelle-Aquitaine" },
  { code: "25", departement: "Doubs", region: "Bourgogne-Franche-Comté" },
  { code: "26", departement: "Drôme", region: "Auvergne-Rhône-Alpes" },
  { code: "27", departement: "Eure", region: "Normandie" },
  { code: "28", departement: "Eure-et-Loir", region: "Centre-Val de Loire" },
  { code: "29", departement: "Finistère", region: "Bretagne" },
  { code: "2A", departement: "Corse-du-Sud", region: "Corse" },
  { code: "2B", departement: "Haute-Corse", region: "Corse" },
  { code: "30", departement: "Gard", region: "Occitanie" },
  { code: "31", departement: "Haute-Garonne", region: "Occitanie" },
  { code: "32", departement: "Gers", region: "Occitanie" },
  { code: "33", departement: "Gironde", region: "Nouvelle-Aquitaine" },
  { code: "34", departement: "Hérault", region: "Occitanie" },
  { code: "35", departement: "Ille-et-Vilaine", region: "Bretagne" },
  { code: "36", departement: "Indre", region: "Centre-Val de Loire" },
  { code: "37", departement: "Indre-et-Loire", region: "Centre-Val de Loire" },
  { code: "38", departement: "Isère", region: "Auvergne-Rhône-Alpes" },
  { code: "39", departement: "Jura", region: "Bourgogne-Franche-Comté" },
  { code: "40", departement: "Landes", region: "Nouvelle-Aquitaine" },
  { code: "41", departement: "Loir-et-Cher", region: "Centre-Val de Loire" },
  { code: "42", departement: "Loire", region: "Auvergne-Rhône-Alpes" },
  { code: "43", departement: "Haute-Loire", region: "Auvergne-Rhône-Alpes" },
  { code: "44", departement: "Loire-Atlantique", region: "Pays de la Loire" },
  { code: "45", departement: "Loiret", region: "Centre-Val de Loire" },
  { code: "46", departement: "Lot", region: "Occitanie" },
  { code: "47", departement: "Lot-et-Garonne", region: "Nouvelle-Aquitaine" },
  { code: "48", departement: "Lozère", region: "Occitanie" },
  { code: "49", departement: "Maine-et-Loire", region: "Pays de la Loire" },
  { code: "50", departement: "Manche", region: "Normandie" },
  { code: "51", departement: "Marne", region: "Grand Est" },
  { code: "52", departement: "Haute-Marne", region: "Grand Est" },
  { code: "53", departement: "Mayenne", region: "Pays de la Loire" },
  { code: "54", departement: "Meurthe-et-Moselle", region: "Grand Est" },
  { code: "55", departement: "Meuse", region: "Grand Est" },
  { code: "56", departement: "Morbihan", region: "Bretagne" },
  { code: "57", departement: "Moselle", region: "Grand Est" },
  { code: "58", departement: "Nièvre", region: "Bourgogne-Franche-Comté" },
  { code: "59", departement: "Nord", region: "Hauts-de-France" },
  { code: "60", departement: "Oise", region: "Hauts-de-France" },
  { code: "61", departement: "Orne", region: "Normandie" },
  { code: "62", departement: "Pas-de-Calais", region: "Hauts-de-France" },
  { code: "63", departement: "Puy-de-Dôme", region: "Auvergne-Rhône-Alpes" },
  { code: "64", departement: "Pyrénées-Atlantiques", region: "Nouvelle-Aquitaine" },
  { code: "65", departement: "Hautes-Pyrénées", region: "Occitanie" },
  { code: "66", departement: "Pyrénées-Orientales", region: "Occitanie" },
  { code: "67", departement: "Bas-Rhin", region: "Grand Est" },
  { code: "68", departement: "Haut-Rhin", region: "Grand Est" },
  { code: "69", departement: "Rhône", region: "Auvergne-Rhône-Alpes" },
  { code: "70", departement: "Haute-Saône", region: "Bourgogne-Franche-Comté" },
  { code: "71", departement: "Saône-et-Loire", region: "Bourgogne-Franche-Comté" },
  { code: "72", departement: "Sarthe", region: "Pays de la Loire" },
  { code: "73", departement: "Savoie", region: "Auvergne-Rhône-Alpes" },
  { code: "74", departement: "Haute-Savoie", region: "Auvergne-Rhône-Alpes" },
  { code: "75", departement: "Paris", region: "Île-de-France" },
  { code: "76", departement: "Seine-Maritime", region: "Normandie" },
  { code: "77", departement: "Seine-et-Marne", region: "Île-de-France" },
  { code: "78", departement: "Yvelines", region: "Île-de-France" },
  { code: "79", departement: "Deux-Sèvres", region: "Nouvelle-Aquitaine" },
  { code: "80", departement: "Somme", region: "Hauts-de-France" },
  { code: "81", departement: "Tarn", region: "Occitanie" },
  { code: "82", departement: "Tarn-et-Garonne", region: "Occitanie" },
  { code: "83", departement: "Var", region: "Provence-Alpes-Côte d'Azur" },
  { code: "84", departement: "Vaucluse", region: "Provence-Alpes-Côte d'Azur" },
  { code: "85", departement: "Vendée", region: "Pays de la Loire" },
  { code: "86", departement: "Vienne", region: "Nouvelle-Aquitaine" },
  { code: "87", departement: "Haute-Vienne", region: "Nouvelle-Aquitaine" },
  { code: "88", departement: "Vosges", region: "Grand Est" },
  { code: "89", departement: "Yonne", region: "Bourgogne-Franche-Comté" },
  { code: "90", departement: "Territoire de Belfort", region: "Bourgogne-Franche-Comté" },
  { code: "91", departement: "Essonne", region: "Île-de-France" },
  { code: "92", departement: "Hauts-de-Seine", region: "Île-de-France" },
  { code: "93", departement: "Seine-Saint-Denis", region: "Île-de-France" },
  { code: "94", departement: "Val-de-Marne", region: "Île-de-France" },
  { code: "95", departement: "Val-d'Oise", region: "Île-de-France" },
  { code: "971", departement: "Guadeloupe", region: "Guadeloupe" },
  { code: "972", departement: "Martinique", region: "Martinique" },
  { code: "973", departement: "Guyane", region: "Guyane" },
  { code: "974", departement: "La Réunion", region: "La Réunion" },
  { code: "976", departement: "Mayotte", region: "Mayotte" },

  // Ajoutez tous les autres départements ici...
];
