import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/products`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les produits
export const getAllProducts = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allproducts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des produits:", error);
    return [];
  }
};

// Fonction pour récupérer un produit par son id
export const getProductById = async (productId) => {
  try {
    const response = await axios.get(`${apiUrl}/productById/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du produit:", error);
    return null;
  }
};

/// POST
// Fonction pour ajouter un produit
export const addProduct = async (product) => {
  try {
    const response = await axios.post(`${apiUrl}/addProduct`, product, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout du produit:", error);
    return null;
  }
};

/// PATCH
// Fonction pour mettre à jour un produit
export const updateProduct = async (id, productData) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updateProduct/${id}`, // L'ID est bien utilisé ici
      productData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du produit:", error);
    throw error; // Laissez l'erreur remonter pour être gérée dans le composant
  }
};

/// DELETE
// Fonction pour supprimer un produit par son id
export const deleteProductById = async (productId) => {
  try {
    await axios.delete(`${apiUrl}/deleteProduct/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la suppression du produit:", error);
  }
};
