import React, { useEffect, useState } from "react";
import { getAllProducts } from "../../../../services/productsServices";
import { saveSubscription } from "../../../../services/subscriptionServices";
import { sendNotificationToAdmin, sendNotification } from "../../../../services/notificationServices";
import { useUser } from "../../../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { getAssociationsByUserId } from "../../../../services/associationsServices";
import { toast, ToastContainer } from "react-toastify";
import ComponentTitle from "./../../../Home/ComponentTitle";


const PricingPageForProspects = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [associations, setAssociations] = useState([]);
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const { user } = useUser();
  const userId = user?.userId;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductsAndAssociations = async () => {
      try {
        const productsData = await getAllProducts();
        setProducts(productsData);

        const associationsData = await getAssociationsByUserId(user.userId);
        setAssociations(associationsData.Associations);

        if (associationsData.Associations.length === 1) {
          setSelectedAssociation(associationsData.Associations[0].id);
        }

        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
        setLoading(false);
      }
    };

    fetchProductsAndAssociations();
  }, [user.userId]);

  if (loading) {
    return <div>Chargement des tarifs...</div>;
  }

  const handleSubscriptionSelect = async (productId, productName, category) => {
    if (!selectedAssociation) {
      alert("Veuillez sélectionner une association avant de confirmer votre abonnement.");
      return;
    }

    if (selectedSubscription === productId) {
      try {
        const subscriptionData = {
          user_id: user.userId,
          asso_id: selectedAssociation,
          product_id: productId,
          stripe_subscription_id: "manual",
          status: "non-actif",
        };

        const token = localStorage.getItem("token");
        await saveSubscription(subscriptionData, token);

        const selectedAssociationName = associations.find(
          (asso) => asso.id === parseInt(selectedAssociation)
        )?.nom_asso;

        const adminMessage = `Un nouveau service a été souscrit pour l'association ${selectedAssociationName} par ${user.nom} ${user.prenom}.`;
        await sendNotificationToAdmin(adminMessage, token);

        const userMessage = `Votre ${productName} a été activé pour l'association : "${selectedAssociationName}".`;
        await sendNotification(userId, userMessage, token);

        toast.success("Service souscrit avec succès!");

        navigate("/appli/subscriptions");
      } catch (error) {
        console.error("Erreur lors de l'enregistrement du service:", error);
        toast.error("Erreur lors de la souscription du service.");
      }
    } else {
      setSelectedSubscription(productId);
    }
  };

  const handleAssociationChange = (e) => {
    setSelectedAssociation(e.target.value);
  };

  const abonnements = products.filter(product => product.categorie_product === "Abonnement");
  const installations = products.filter(product => product.categorie_product === "Installation");
  const assistances = products.filter(product => product.categorie_product === "Assistance");

  return (
    <section className="py-4 text-gray-800">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6">
        
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12 bg-slate-200 rounded-lg p-4">
        <ComponentTitle title="Nos" highlight=" Tarifs" />
        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Choisissez le plan qui convient le mieux à votre club sportif.
        </p>
      </div>

        {associations.length > 1 && (
          <div className="mb-6 text-center">
            <label htmlFor="association-select" className="block mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200">
              Sélectionnez votre association
            </label>
            <select
              id="association-select"
              value={selectedAssociation}
              onChange={handleAssociationChange}
              className="block w-full max-w-xs mx-auto p-2 border border-gray-300 rounded dark:border-gray-600 dark:bg-gray-700 dark:text-white"
            >
              <option value="">Sélectionner une association</option>
              {associations.map((asso) => (
                <option key={asso.id} value={asso.id}>
                  {asso.nom_asso}
                </option>
              ))}
            </select>
          </div>
        )}

        <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200 text-center border-b-2 border-gray-300 dark:border-gray-700 inline-block pb-2">
          Nos formules d'abonnement
        </h2>
        <div className="lg:grid lg:grid-cols-3 sm:gap-4 xl:gap-6 space-y-6 lg:space-y-0 mb-12">
          {abonnements.map((product) => (
            <div
              key={product.id}
              className={`flex flex-col p-4 max-w-xs mx-auto text-center text-gray-900 rounded-lg border bg-gray-200 border-gray-200 shadow dark:border-gray-700 dark:bg-gray-800 dark:text-white`}
            >
              <h3 className="mb-2 text-lg font-medium">{product.nom_product}</h3>
              <div className="flex justify-center items-baseline my-4">
                <span className="mr-2 text-3xl font-bold">{product.tarif_product}€</span>
                <span className="text-gray-500 dark:text-gray-400 text-sm">/{product.periodicite_product}</span>
              </div>
              <div className="mb-4 space-y-2 text-left text-sm">
                <div dangerouslySetInnerHTML={{ __html: product.contenu_product }} />
              </div>
              <button
                onClick={() => handleSubscriptionSelect(product.id, product.nom_product, "Abonnement")}
                className={`px-4 py-2 rounded-full shadow text-sm font-semibold transition-all duration-300 ${
                  selectedSubscription === product.id
                    ? "bg-primary text-white"
                    : selectedSubscription
                    ? "bg-gray-400 text-gray-300"
                    : "bg-blue-600 text-white hover:bg-blue-500"
                }`}
              >
                {selectedSubscription === product.id ? "Confirmation" : "S'abonner"}
              </button>
            </div>
          ))}
        </div>

        <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200 text-center border-b-2 border-gray-300 dark:border-gray-700 inline-block pb-2">
          Nos packs d'installation
        </h2>
        <div className="lg:grid lg:grid-cols-3 sm:gap-4 xl:gap-6 space-y-6 lg:space-y-0 mb-12">
          {installations.map((product) => (
            <div
              key={product.id}
              className="flex flex-col p-4 max-w-xs mx-auto text-center text-gray-900 bg-gray-200 rounded-lg border border-gray-200 shadow dark:border-gray-700 dark:bg-gray-800 dark:text-white"
            >
              <h3 className="mb-2 text-lg font-medium">{product.nom_product}</h3>
              <div className="flex justify-center items-baseline my-4">
                <span className="mr-2 text-3xl font-bold">{product.tarif_product}€</span>
                <span className="text-gray-500 dark:text-gray-400 text-sm">/{product.periodicite_product}</span>
              </div>
              <div className="mb-4 space-y-2 text-left text-sm">
                <div dangerouslySetInnerHTML={{ __html: product.contenu_product }} />
              </div>
              <button
                onClick={() => handleSubscriptionSelect(product.id, product.nom_product, "Installation")}
                className={`px-4 py-2 rounded-full shadow text-sm font-semibold transition-all duration-300 ${
                  selectedSubscription === product.id
                    ? "bg-primary text-white"
                    : selectedSubscription
                    ? "bg-gray-400 text-gray-300"
                    : "bg-blue-600 text-white hover:bg-blue-500"
                }`}
              >
                {selectedSubscription === product.id ? "Confirmation" : "Choisir"}
              </button>
            </div>
          ))}
        </div>

        <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200 text-center border-b-2 border-gray-300 dark:border-gray-700 inline-block pb-2">
          Nos assistances
        </h2>
        <div className="lg:grid lg:grid-cols-3 sm:gap-4 xl:gap-6 space-y-6 lg:space-y-0">
          {assistances.map((product) => (
            <div
              key={product.id}
              className="flex flex-col p-4 max-w-xs mx-auto text-center text-gray-900 bg-gray-200 rounded-lg border border-gray-200 shadow dark:border-gray-700 dark:bg-gray-800 dark:text-white"
            >
              <h3 className="mb-2 text-lg font-medium">{product.nom_product}</h3>
              <div className="flex justify-center items-baseline my-4">
                <span className="mr-2 text-3xl font-bold">{product.tarif_product}€</span>
                <span className="text-gray-500 dark:text-gray-400 text-sm">/{product.periodicite_product}</span>
              </div>
              <div className="mb-4 space-y-2 text-left text-sm">
                <div dangerouslySetInnerHTML={{ __html: product.contenu_product }} />
              </div>
              <button
                onClick={() => handleSubscriptionSelect(product.id, product.nom_product, "Assistance")}
                className={`px-4 py-2 rounded-full shadow text-sm font-semibold transition-all duration-300 ${
                  selectedSubscription === product.id
                    ? "bg-primary text-white"
                    : selectedSubscription
                    ? "bg-gray-400 text-gray-300"
                    : "bg-blue-600 text-white hover:bg-blue-500"
                }`}
              >
                {selectedSubscription === product.id ? "Confirmation" : "Choisir"}
              </button>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </section>
  );
};

export default PricingPageForProspects;
