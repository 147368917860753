import React, { useState, useEffect } from "react";
import useScrollToTop from "../../hooks/useScrollToTop";
import { getSettings } from "../../services/settingsServices";

const ConditionsUtilisationPage = () => {
  // Scroll to top screen
  useScrollToTop();

  const [settings, setSettings] = useState({
    siteweb_club: "",
    nom_club: "",
    adresse_club: "",
    responsable_club: "",
    mail_club: "",
    siret_club: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
      }
    };

    fetchSettings();
  }, []);

  return (
    <div>
      <div>
        <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[80px] w-full"></div>
        <div className="flex flex-col w-full max-w-[1280px] mx-auto p-6 px-10 bg-gray-200 dark:bg-backgroundMainDark dark:text-white ">
          <h1 className="text-xl tracking-widest text-gray-800 text-center dark:text-white">
            Textes Réglementaires
          </h1>
          <h2 className="text-4xl font-bold uppercase text-center my-6 mb-10">
            Conditions d'Utilisation
          </h2>

          {/* Content Section */}
          <div className="flex flex-col space-y-4">
            <h3 className="text-2xl font-bold">1. Introduction</h3>
            <p>
              Les présentes conditions d'utilisation régissent l'accès et
              l'utilisation du site <b>{settings.siteweb_club}</b>, et
              s'appliquent à tous les visiteurs, utilisateurs et autres
              personnes qui accèdent ou utilisent le service.
            </p>

            <h3 className="text-2xl font-bold">
              2. Acceptation des conditions
            </h3>
            <p>
              En accédant ou en utilisant le site <b>{settings.siteweb_club}</b>
              , vous acceptez d'être lié par ces conditions. Si vous n'êtes pas
              d'accord avec une partie quelconque des conditions, vous n'êtes
              pas autorisé à accéder au service.
            </p>

            <h3 className="text-2xl font-bold">
              3. Modifications des conditions
            </h3>
            <p>
              <b>{settings.nom_club}</b> se réserve le droit, à sa seule
              discrétion, de modifier ou de remplacer ces conditions à tout
              moment. Si une révision est importante, nous essaierons de fournir
              un préavis d'au moins 30 jours avant l'entrée en vigueur des
              nouvelles conditions. Ce qui constitue une modification mineure
              sera déterminé à notre seule discrétion.
            </p>

            <h3 className="text-2xl font-bold">4. Contenu</h3>
            <p>
              Les droits sur tout le contenu, les images, les matériaux, le
              design et les services fournis ou accessibles via{" "}
              <b>{settings.siteweb_club}</b> appartiennent à{" "}
              <b>{settings.nom_club}</b> ou à ses concédants et sont protégés
              par les lois sur la propriété intellectuelle. Vous acceptez de ne
              pas utiliser le contenu de manière à violer ces lois ou ces
              conditions.
            </p>

            <h3 className="text-2xl font-bold">5. Comportements interdits</h3>
            <p>
              Vous acceptez de ne pas utiliser le site{" "}
              <b>{settings.siteweb_club}</b> pour :
              <ul className="list-disc pl-10">
                <li>
                  Publier du contenu illégal, nuisible, menaçant, abusif,
                  harcelant, diffamatoire, vulgaire, obscène, pornographique, ou
                  répréhensible.
                </li>
                <li>
                  Tenter de sonder, scanner ou tester la vulnérabilité d'un
                  système ou réseau ou de violer des mesures de sécurité ou
                  d'authentification.
                </li>
                <li>
                  Interférer avec le service à tout utilisateur, hôte ou réseau,
                  y compris, sans limitation, par le biais de l'envoi d'un
                  virus, surcharge, inondation, spam, ou par mail-bombing du
                  site.
                </li>
              </ul>
            </p>

            <h3 className="text-2xl font-bold">
              6. Limitation de responsabilité
            </h3>
            <p>
              <b>{settings.nom_club}</b> ne sera pas responsable des dommages de
              toute nature découlant de votre utilisation ou de votre incapacité
              à utiliser le site <b>{settings.siteweb_club}</b>, bien que{" "}
              <b>{settings.nom_club}</b> ait été informé de la possibilité de
              tels dommages.
            </p>

            <h3 className="text-2xl font-bold">
              7. Droit applicable et juridiction
            </h3>
            <p>
              Ces conditions seront régies et interprétées conformément aux lois
              de la France, sans tenir compte de ses conflits de dispositions
              légales. Notre incapacité à appliquer un droit ou une disposition
              des présentes conditions ne sera pas considérée comme une
              renonciation à ces droits.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionsUtilisationPage;
