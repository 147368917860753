import React from 'react';
import { FaTrash } from 'react-icons/fa'; // Importer les icônes nécessaires

// ButtonDelete avec une icône de poubelle
const ButtonDelete = ({ onClick, className = '', hoverLabel = 'Supprimer', disabled = false }) => {
  return (
    <button
      onClick={disabled ? null : onClick} // Désactiver onClick si disabled est true
      aria-label={hoverLabel}
      title={hoverLabel}
      className={`w-8 h-8 rounded-md shadow-sm transition duration-300 ease-in-out flex items-center justify-center 
        ${disabled ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-red-500 text-white hover:bg-red-700'} 
        ${className}`}
      disabled={disabled} // Ajout de l'attribut disabled
    >
      <FaTrash className='text-[12px]'/>
    </button>
  );
};

export default ButtonDelete;
